import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { GetTopSearchKeys } from "../../business/Public/HeaderBusiness";
import { sysIsDarkMode, sysLanguageKey } from "../../helpers/storagekeys";
import { DefaultPortalLanguage } from "../../helpers/constantData";
import { GetMyTasks } from "../../business/Public/HeaderBusiness";
import { ValidLanguages } from "../../helpers/resources";

export type CommonReducerType = {
  loading: boolean;
  selectedMenu: string | null;
  token: string | null;
  showTMNewDemandModal: false;
  showPublicSearch: boolean;
  popularSearch: any | null;
  sysLang: any | null;
  isDarkMode: any | null;
  myTasks: any | null;
  loadedContentObject: any | null;
  corporateTabChoice: any | null;
  myTasksCalling: any |null;
  popularSearchCalling: any |null;
  officeListCalling: any |null;
  officeList : any | null;
  isSurveyAnswered : any | null;
}

const initialState: CommonReducerType = {
  loading: false,
  token: null,
  selectedMenu: "1",
  showTMNewDemandModal: false,
  showPublicSearch: true,
  popularSearch: null,
  sysLang: null,
  isDarkMode: null,
  myTasks: null,
  loadedContentObject: null,
  corporateTabChoice: "1",
  myTasksCalling: false,
  popularSearchCalling : false,
  officeListCalling : false,
  officeList : null,
  isSurveyAnswered : false

}

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoading: (state, action) => void (state.loading = action.payload),
    setToken: (state, action) => void (state.token = action.payload), //void: just update state do not return action.payload
    setSelectedMenu: (state, action) => void (state.selectedMenu = action.payload), //void: just update state do not return action.payload
    setShowTMNewDemandModal: (state, action) => void (state.showTMNewDemandModal = action.payload), //void: just update state do not return action.payload
    setShowPublicSearch: (state, action) => void (state.showPublicSearch = action.payload), //void: just update state do not return action.payload
    setPopularSearch: (state, action) => void (state.popularSearch = action.payload),
    setSysLang: (state, action) => void (state.sysLang = action.payload),
    setDarkMode: (state, action) => void (state.isDarkMode = action.payload),
    setMyTasks: (state, action) => void (state.myTasks = action.payload),
    setLoadedContentObject: (state, action) => void (state.loadedContentObject = action.payload),
    setCorporateTabChoice: (state, action) => void (state.corporateTabChoice = action.payload),
    setMyTasksCalling: (state, action) => void (state.myTasksCalling = action.payload),
    setPopularSearchCalling: (state, action) => void (state.popularSearchCalling = action.payload),
    setOfficeListCalling: (state, action) => void (state.officeListCalling = action.payload),
    setOfficeList: (state, action) => void (state.officeList = action.payload),
    setIsSurveyAnswered: (state, action) => void (state.isSurveyAnswered = action.payload)

  }
})

export const {
  setToken,
  setSelectedMenu,
  setLoading,
  setShowTMNewDemandModal,
  setShowPublicSearch,
  setPopularSearch,
  setSysLang,
  setDarkMode,
  setMyTasks,
  setLoadedContentObject,
  setCorporateTabChoice,
  setMyTasksCalling,
  setPopularSearchCalling,
  setOfficeListCalling,
  setOfficeList,
  setIsSurveyAnswered
} = commonSlice.actions;

export const getPopularSearch = () => async (dispatch: AppDispatch) => {
  let apiCall = GetTopSearchKeys();
  if (apiCall.isSuccessful) {      
      dispatch(setPopularSearch(apiCall.result));
  }
};

export const getIsDarkMode = () => async (dispatch: AppDispatch) => {
  let _isDarkMode = localStorage.getItem(sysIsDarkMode)=="1";
  dispatch(setDarkMode(_isDarkMode));
};

export const getSysLang = () => async (dispatch: AppDispatch) => {
  let _sysLang :any = localStorage.getItem(sysLanguageKey);
  if (!ValidLanguages.includes(_sysLang)) {
    _sysLang = DefaultPortalLanguage;
  }
  dispatch(setSysLang(_sysLang));
};


export default commonSlice.reducer;


