import axios, { AxiosResponse } from "axios";
import { getBrowserInfo } from "../../helpers/root";
import { LogApiRequestType } from "../../type/Common/LogApiRequestType";
import { callApi } from "../../helpers/service";
import { methods } from "../../helpers/enums";
import { getRandomDate, getRandomDepartment, getRandomEmail, getRandomFirstName, getRandomImage, getRandomName, getRandomPhoneNumber, getRandomShortCode, getRandomSurName, getRandomTitle, getRandomUsername } from "../../helpers/samples";
import { urlGetAllContentCategoriesForUI, urlGetContentByIdForUI } from "../../helpers/apiUrls";
import { ApiTestResults } from "../../helpers/testApiResponses";

//Merkezi loglama operasyonu
export const LogApi = async ({
    logType,
    action,
    actionName,
    itemId,
    logTimeOut
  }: LogApiRequestType) => {
  
    axios.defaults.headers.common["browserInfo"] = getBrowserInfo();
  
    callApi({
      url: `/Log/Post`, useToken: true, data: {
        logType,
        action,
        actionName,
        itemId,
        logTimeOut
      }, method: methods.POST,
      successCallback: (response: any) => {      
        
    }})
  
  };

  export const GetSearchResults = async (key) =>{
    let resultData:any = null;
    await callApi({
        url: "/api/Test/GetSearchResults?key="+key,
        useToken: true,
        successCallback:(response: AxiosResponse<any>) => {
            console.log(response);
            resultData = response.data.data;
        },
        errorCallback: (e) => {
            resultData = [];
        },
        finish: () =>{
            
        }
        
    })
    return resultData;
  }

  export const GetUserDetail = async (userName)  => {
    let result = {};
    await callApi({
      url: urlGetAllContentCategoriesForUI,
      useToken: true,
      successCallback: (res: AxiosResponse<any>) => {
          if (res.status === 200) { 
            
          }
      },
      errorCallback: (e) => {  
      },
      finish: () =>{           
        result = {
              "isActive": true,
              "userName": getRandomUsername(),
              "email": getRandomEmail(),
              "name": getRandomFirstName(),
              "surname": getRandomSurName(),
              "fullName": getRandomName(),
              "phoneNumber": "55555555555",
              "telephoneNumber": "55555555555",
              "mobileShortCode": "2726",
              "profilePhoto": "photoUrl",
              "managerUserName": "volkan.secginli",
              "managerFullName": "Volkan Seçginli",
              "department": "IT",
              "title": "Senior Developer",
              "company": 1,
              "birthDay": "04.02.1994",
              "hireDate": "02.02.2024",
              "unvan": "Senior Developer",
              "currentLocation" : "Merkez Ofis",
              "currentLocationId" : 1
        }
      }});
  
    return result;

  };

  export const GetSearchDelegatedResult = async (searchKey)  => {
    let result = [];
    await callApi({
      url: urlGetAllContentCategoriesForUI,
      useToken: true,
      successCallback: (res: AxiosResponse<any>) => {
          if (res.status === 200) { 
            
          }
      },
      errorCallback: (e) => {  
      },
      finish: () =>{           
        result = [
          
            { value: getRandomName() },
            { value: 'Volkan Seçginli' },
            { value: getRandomName() }
          
      ];
      }});
  
    return result;

  };

  export const testApiCall = async (resultType, params? ) =>{
    let resultData:any = null;
    // await callApi({
    //     url: urlGetContentByIdForUI + "id=047a0994-c8b6-41bb-b1a8-4894232d1feb",
    //     useToken: true,
    //     successCallback:(response: AxiosResponse<any>) => {
            
    //     },
    //     errorCallback: (e) => {

    //     },
    //     finish: () =>{
    //       console.log("test call : " , resultType);
    //       resultData = ApiTestResults(resultType);
    //     }
        
    // })
    console.log("test call : " , resultType);
    resultData = ApiTestResults(resultType);
    return resultData;
  }

