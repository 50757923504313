import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { emptyGuid } from "../../helpers/constantData";
import { getRandomFirstName, getRandomName } from "../../helpers/samples";

export type DemandReducerType = {
    myDemandContent: any | null;
    myTasksContent: any | null;
    taskTrackingMyTasks: any | null;
    taskTrackingMyTeamTasks: any | null;
}

const initialState: DemandReducerType = {
    myDemandContent: null,
    myTasksContent: null,
    taskTrackingMyTasks : null,
    taskTrackingMyTeamTasks : null
}

const commonSlice = createSlice({
  name: "demand",
  initialState,
  reducers: {
    setMyDemandContent: (state, action) => void (state.myDemandContent = action.payload),
    setMyTasksContent: (state, action) => void (state.myTasksContent = action.payload),
    setTaskTrackingMyTasks: (state, action) => void (state.taskTrackingMyTasks = action.payload),
    setTaskTrackingMyTeamTasks: (state, action) => void (state.taskTrackingMyTeamTasks = action.payload)
  }
})

export const {
    setMyDemandContent,
    setMyTasksContent,
    setTaskTrackingMyTasks,
    setTaskTrackingMyTeamTasks
} = commonSlice.actions;

export const getMyDemandContent = () => async (dispatch: AppDispatch) => {
    dispatch(setMyDemandContent(
        {
            "lang" : "Tr",  
            "data" : [
        {
            "type" : "1",
            "typeDesc" : "İzin Talebi",
            "date" : "28.02.2024",
            "state" : "Onay Bekliyor",
            "activeApproveManager" : "Volkan Seçginli",
            "isActive" : true,
            "id" : 1,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "1 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "2",
            "typeDesc" : "Masraf Talebi",
            "date" : "29.02.2024",
            "state" : "İnceleniyor",
            "activeApproveManager" : null,
            "isActive" : true,
            "id" : 2,
            "desc": "Müşteri toplantısı masraf talebi",
            "history" : [
                {
                    "process" : "2 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "3",
            "typeDesc" : "Seyahat Talebi",
            "date" : "29.02.2024",
            "state" : "İnceleniyor",
            "activeApproveManager" : null,
            "isActive" : false,
            "id" : 3,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "3 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "3",
            "typeDesc" : "Seyahat Talebi",
            "date" : "11.02.2024",
            "state" : "Onay Bekliyor",
            "activeApproveManager" : "Volkan Seçginli",
            "isActive" : true,
            "id" : 4,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "4 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "2",
            "typeDesc" : "Masraf Talebi",
            "date" : "29.02.2024",
            "state" : "İnceleniyor",
            "activeApproveManager" : null,
            "isActive" : false,
            "id" : 5,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "5 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "1",
            "typeDesc" : "İzin Talebi",
            "date" : "29.02.2024",
            "state" : "Onay Bekliyor",
            "activeApproveManager" : null,
            "isActive" : true,
            "id" : 6,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "6 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        }
    ]}));
}

export const getMyTasksContent = () => async (dispatch: AppDispatch) => {
    dispatch(setMyTasksContent(
        {
            "lang" : "Tr",  
            "data" : [
        {
            "type" : "1",
            "typeDesc" : "İzin Talebi",
            "date" : "28.02.2024",
            "state" : "Onay Bekliyor",
            "activeApproveManager" : "Volkan Seçginli",
            "isActive" : true,
            "id" : 1,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "1 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "2",
            "typeDesc" : "Masraf Talebi",
            "date" : "29.02.2024",
            "state" : "İnceleniyor",
            "activeApproveManager" : null,
            "isActive" : true,
            "id" : 2,
            "desc": "Müşteri toplantısı masraf talebi",
            "history" : [
                {
                    "process" : "2 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "3",
            "typeDesc" : "Seyahat Talebi",
            "date" : "29.02.2024",
            "state" : "İnceleniyor",
            "activeApproveManager" : null,
            "isActive" : false,
            "id" : 3,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "3 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "3",
            "typeDesc" : "Seyahat Talebi",
            "date" : "11.02.2024",
            "state" : "Onay Bekliyor",
            "activeApproveManager" : "Volkan Seçginli",
            "isActive" : true,
            "id" : 4,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "4 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "2",
            "typeDesc" : "Masraf Talebi",
            "date" : "29.02.2024",
            "state" : "İnceleniyor",
            "activeApproveManager" : null,
            "isActive" : false,
            "id" : 5,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "5 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        },
        {
            "type" : "1",
            "typeDesc" : "İzin Talebi",
            "date" : "29.02.2024",
            "state" : "Onay Bekliyor",
            "activeApproveManager" : null,
            "isActive" : true,
            "id" : 6,
            "desc": "Bayram tatile ile birleştirilen izin talebi",
            "history" : [
                {
                    "process" : "6 No'lu Talep oluşturuldu.",
                    "user" : "Volkan Seçginli",
                    "date" : "22.02.2024 12:57"
                },
                {
                    "process" : "Onaylandı",
                    "user" : "Volkan Seçginli",
                    "date" : "25.02.2024 10:15"
                },
                {
                    "process" : "Tamamlandı",
                    "user" : "-",
                    "date" : "25.02.2024 10:15"
                }
            ]
        }
    ]}));
}

export const getTaskTrackingMyTasks = () => async (dispatch: AppDispatch) => {
    dispatch(setTaskTrackingMyTasks(
        {
            "lang" : "Tr",  
            "data" : [
        {
            "taskOwner" : "Volkan Seçginli",
            "taskId" : "1",
            "taskName" : "Ürün v1.2 analizin tamamlanması",
            "taskDesc" : "Ürün v1.2 analizin tamamlanması",
            "taskTargetDate" : "08.10.2024",
            "isActive" : true,
            "state" : "Devam Ediyor",
            "subTaskList" : [
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 1",
                    "subTaskState" : true
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                   
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                 
                }
            ]
        },
        {
            "taskOwner" : "Volkan Seçginli",
            "taskId" : "2",
            "taskName" : "Eylül24 canlı yayın sunumunun iletilmesi",
            "taskDesc" : "Eylül24 canlı yayın sunumunun iletilmesi",
            "taskTargetDate" : "10.11.2024",
            "isActive" : true,
            "state" : "Devam Ediyor",
            "subTaskList" : [
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 1",
                    "subTaskState" : true
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : true
                   
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                 
                }
            ]
        },
        {
            "taskOwner" : "Volkan Seçginli",
            "taskId" : "3",
            "taskName" : "ABC müşterisine teklif hazırlanıp iletilmesi",
            "taskDesc" : "ABC müşterisine teklif hazırlanıp iletilmesi",
            "taskTargetDate" : "11.07.2024",
            "isActive" : false,
            "state" : "Tamamlandı",
            "subTaskList" : [
                {
                    "subTaskGuid" : "1234",
                    "subTaskDesc" : "Test alt görev 1",
                    "subTaskState" : true
                },
                {
                    "subTaskGuid" : "123456",
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : true                   
                }
            ]
        },
        
    ]}));
}


export const getTaskTrackingMyTeamTasks = () => async (dispatch: AppDispatch) => {
    dispatch(setTaskTrackingMyTeamTasks(
        {
            "lang" : "Tr",  
            "data" : [
        {
            "taskOwner" : "Volkan Seçginli",
            "taskId" : "1",
            "taskName" : "Ürün v1.2 analizin tamamlanması",
            "taskDesc" : "Ürün v1.2 analizin tamamlanması",
            "taskTargetDate" : "08.10.2024",
            "isActive" : true,
            "state" : "Devam Ediyor",
            "subTaskList" : [
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 1",
                    "subTaskState" : true
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                   
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                 
                }
            ]
        },
        {
            "taskOwner" : "Volkan Seçginli",
            "taskId" : "2",
            "taskName" : "Eylül24 canlı yayın sunumunun iletilmesi",
            "taskDesc" : "Eylül24 canlı yayın sunumunun iletilmesi",
            "taskTargetDate" : "10.11.2024",
            "isActive" : true,
            "state" : "Devam Ediyor",
            "subTaskList" : [
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 1",
                    "subTaskState" : true
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                   
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                 
                }
            ]
        },
        {
            "taskOwner" : "Volkan Seçginli",
            "taskId" : "3",
            "taskName" : "ABC müşterisine teklif hazırlanıp iletilmesi",
            "taskDesc" : "ABC müşterisine teklif hazırlanıp iletilmesi",
            "taskTargetDate" : "11.07.2024",
            "isActive" : false,
            "state" : "Tamamlandı",
            "subTaskList" : [
                {
                    "subTaskGuid" : "1234",
                    "subTaskDesc" : "Test alt görev 1",
                    "subTaskState" : true
                },
                {
                    "subTaskGuid" : "123456",
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : true                   
                }
            ]
        },
        {
            "taskOwner" :  getRandomName(),
            "taskId" : "4",
            "taskName" : "CRM ekibine VNS sunumunun planlaması",
            "taskDesc" : "CRM ekibine VNS sunumunun planlaması",
            "taskTargetDate" : "17.07.2024",
            "isActive" : true,
            "state" : "Devam Ediyor",
            "subTaskList" : [
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 1",
                    "subTaskState" : true
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                   
                },
                {
                    "subTaskGuid" : emptyGuid,
                    "subTaskDesc" : "Test alt görev 2",
                    "subTaskState" : false
                 
                }
            ]
        }
        
    ]}));
}

export default commonSlice.reducer;


