// history.js
import { createBrowserHistory } from 'history'

const history = createBrowserHistory({
  /* pass a configuration object here if needed */
});

export default history;

history.listen((location) => {
  insertPath(location.pathname, location.search, location.hash);
})


export const HISTORY_KEYS = {
  HISTORY_LIST: 'history_list'
}

export const insertPath = (path: string, search: string, hash: string ) => {
  let list = getHistoryList();
  list = [...list, {path, search, hash}];
  sessionStorage.setItem(HISTORY_KEYS.HISTORY_LIST, JSON.stringify(list));
}

export const getHistoryList = () => {
  const listStr = sessionStorage.getItem(HISTORY_KEYS.HISTORY_LIST);
  return listStr ? JSON.parse(listStr): [];
}