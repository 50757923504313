import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Tooltip } from 'antd';
import { PageBase } from '../../../../components';
import { getMenuItem } from '../../../../helpers/menu';
import history from '../../../../history';
import { callApi } from '../../../../helpers/service';
import { AxiosResponse } from 'axios';
import { ServiceResult } from '../../../../type/ServiceResult';
import { methods } from '../../../../helpers/enums';
import { trueFalseRender } from '../../../../helpers/columns';
import { EditOutlined, EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Content, ContentWithHeader } from '../../../../type/ContentManagement/Content';
import { getUrlParamValue } from '../../../../helpers/root';

export interface FAQListProps { }



const FAQList: React.FC<FAQListProps> = () => {
    const contentId = getUrlParamValue("id");

    const [loading, setLoading] = useState<boolean>(false);
    const [Data, setData] = useState<any[]>([]);
    const [PageTitle, setPageTitle] = useState<string>(getMenuItem("FAQList").text);

    const cols: any = [
        { title: "Sıra", key: "sequence", width: 30, render: (item: any) => { return item.sequence; } },
        { title: "Soru", key: "name", render: (item: any) => { return item.name; } },        
        {
            title: '', key: 'operation', fixed: 'right', width: 100,
            render: (item: any) => {
                return (
                    <div className='d-flex gap-5'>
                        <Tooltip title="Düzenle">
                            <Button onClick={() => history.push(`${getMenuItem("FAQEdit").path}?id=${item.id}&contentId=${contentId}`)} type="primary" shape="circle" icon={<EditOutlined />} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (contentId) {
            getData();
        }
    }, []);

    const getData = () => {
        setLoading(true)
        callApi({
            url: `/admin/Content/GetContentById/${contentId}`, useToken: true, method: methods.GET,
            successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
                const result = res.data.result;
                setPageTitle(`${getMenuItem("FAQList").text} (${result.content.title})`)
            },
        });      
        callApi({
            url: `/admin/Content/GetContentFaqByContentId/${contentId}`, useToken: true,
            method: methods.GET,
            successCallback: (res: AxiosResponse<ServiceResult<any[]>>) => {
                setLoading(false)
                if (res.data.isSuccessful) {
                    const result = res.data.result;
                    setData(result)
                } else {
                    setData([])
                }
            },
        });
    }
   
    return (
        <PageBase showBreadcrump showPageHeader title={PageTitle} menuName={PageTitle}  >
            <Form layout="inline" className="components-table-demo-control-bar mb-15 flex-end" >
                <Form.Item>

                    <Button type="dashed" onClick={() => history.push(`${getMenuItem("FAQNew").path}?contentId=${contentId}`)}>Yeni S.S.S Ekle </Button>
                </Form.Item>
            </Form>
            <Table dataSource={Data} columns={cols} loading={false} />
        </PageBase>
    );
}

export default FAQList;