import { GetSystemLanguage, GetTodayWithShortFormat } from "./basemethods";
import { getRandomDate, getRandomDepartment, getRandomEmail, getRandomFirstName, getRandomName, getRandomSurName, getRandomTitle, getRandomUsername } from "./samples";

export const ApiTestResults = (resultType) :any => {
    switch (resultType) {
        case "quickSearch":
            return [                
                {
                    user : {
                        "isActive": true,
                        "userName": getRandomUsername(),
                        "email": getRandomEmail(),
                        "name": getRandomFirstName(),
                        "surname": getRandomSurName(),
                        "fullName": getRandomName(),
                        "phoneNumber": "55555555555",
                        "telephoneNumber": "55555555555",
                        "mobileShortCode": "2726",
                        "profilePhoto": "photoUrl",
                        "managerUserName": "volkan.secginli",
                        "managerFullName": "Volkan Seçginli",
                        "department": getRandomDepartment(),
                        "title": getRandomTitle(),
                        "company": 1,
                        "birthDay": getRandomDate().toLocaleDateString("tr-TR"),
                        "hireDate": getRandomDate().toLocaleDateString("tr-TR"),
                        "unvan": "Senior Developer",
                        "currentLocation" : "Merkez Ofis",
                        "currentLocationId" : 1
                    }
                },
                {
                    user : {
                        "isActive": true,
                        "userName": getRandomUsername(),
                        "email": getRandomEmail(),
                        "name": getRandomFirstName(),
                        "surname": getRandomSurName(),
                        "fullName": getRandomName(),
                        "phoneNumber": "55555555555",
                        "telephoneNumber": "55555555555",
                        "mobileShortCode": "2726",
                        "profilePhoto": "photoUrl",
                        "managerUserName": "volkan.secginli",
                        "managerFullName": "Volkan Seçginli",
                        "department": getRandomDepartment(),
                        "title": getRandomTitle(),
                        "company": 1,
                        "birthDay": getRandomDate().toLocaleDateString("tr-TR"),
                        "hireDate": getRandomDate().toLocaleDateString("tr-TR"),
                        "unvan": "Senior Developer",
                        "currentLocation" : "Merkez Ofis",
                        "currentLocationId" : 1
                    }
                },              
                {
                    document : {
                        fileName : "2024 Q1 Organizasyon Duyurusu.pdf",
                        fileTitle : "2024 Q1 Organizasyon Duyurusu",
                        id : "1"
                    }
                },              
                {
                    document : {
                        fileName : "Farkındalık Seansı Tüm Yöneticiler.xlsx",
                        fileTitle : "Farkındalık Seansı Tüm Yöneticiler",
                        id : "1"
                    }
                },              
                {
                    document : {
                        fileName : "2024 Hedef_Kataloğu.doc",
                        fileTitle : "2024 Hedef_Kataloğu",
                        id : "1"
                    }
                },
                {
                    link : {
                        id : "1",
                        name : "İzin Talep",
                        url : "/corporate-demands?tab=1"
                    }
                }
            ];   
        case "notifications" : 
            return {
                "totalCount" : 10,
                    "jobs" :[
                        {
                            "title": GetSystemLanguage() == "Tr" ? "İzin Talebi" : "DayOff Request",
                            "count": 3,
                            "description" : GetSystemLanguage() == "Tr" ? "Bekleyen izin talebiniz bulunmaktadır!" : "You have a pending dayoff request",
                            "url" : "/corporate-demands?tab=7"
                        },                    
                        {
                            "title": GetSystemLanguage() == "Tr" ? "Seyahat Talebi" : "Travel Request",
                            "count": 5,
                            "description" : GetSystemLanguage() == "Tr" ? "Bekleyen seyahat talebiniz bulunmaktadır!" : "You have a pending travel request",
                            "url" : "/corporate-demands?tab=7"
                        },
                        {
                            "title": GetSystemLanguage() == "Tr" ? "Masraf Talebi" : "Cost Request",
                            "count": 2,
                            "description" : GetSystemLanguage() == "Tr" ? "Bekleyen masraf talebiniz bulunmaktadır!" : "You have a pending cost request",
                            "url" : "/corporate-demands?tab=7"
                        }
                    ]
            };
        case "popularSearch" :
            return ["izin Politikası","Kurumsal Haberler","Yan Haklar","Sosyal Aktiviteler","Ulaşım Hizmetleri"];
        case "officeList" : 
            return [
                    { label: GetSystemLanguage() == "Tr" ? "Merkez" : "Center", icon:"fa fa-building centralOffice", key:1 , src: require("../assets/images/offices/ofis2.png") },
                    { label: "Kadıköy", icon:"fa fa-building normalOffice", key:2 , src: require("../assets/images/offices/ofis5.png") },
                    { label: "Üsküdar", icon:"fa fa-building normalOffice", key:3 , src: require("../assets/images/offices/ofis3.png")},
                    { label: "Mecidiyeköy", icon:"fa fa-building normalOffice" , key:4, src: require("../assets/images/offices/ofis4.png") },
                    { label: GetSystemLanguage() == "Tr" ? "Uzaktan Çalışma" : "Remote Working", icon:"fa fa-home homeOffice" , key:5, src: require("../assets/images/offices/homeoffice.png") }
              ];
        case "banners" : 
        return [
                    {
                        header : {
                            redirectUrl:"/test",
                        },
                        title : GetSystemLanguage() == "Tr" ? "İzmir Teknoloji Serbest Bölge ofisinde yakında çalışmaya başlıyoruz!" : "We will start working in Izmir Technology Free Zone office soon!",
                        bannerImageId: 1
                    },
                    {
                        header : {
                            redirectUrl:"/test",
                        },
                        redirectUrl:"/test",
                        title : GetSystemLanguage() == "Tr" ? "Yeni calisma modelimizi yayınladık :)" : "We published our new working model :)",
                        bannerImageId: 2
                    },                    
                    {
                        header : {
                            redirectUrl:"/test",
                        },
                        redirectUrl:"/test",
                        title : GetSystemLanguage() == "Tr" ? "VNS Intranet ürünümüz çok yakında canlı kullanıma açılıyor :)" : "Our VNS Intranet product is going live very soon :)",
                        bannerImageId: 3
                    }
                ];
        case "activities":
            return [
                {
                    "start":"2024-05-01T08:30:00",
                    "end":"2024-05-03T17:30:00",
                    "title": GetSystemLanguage() == "Tr" ? "Proje Yönetim Eğitimi" : "Project Management Training",
                    "description": GetSystemLanguage() == "Tr" ? "Proje Yönetim Eğitimi" : "Project Management Training",                    
                    "imageUrl" : require("../assets/images/birthday-templates/12.png").default,
                    "color" : "blue"
                },
                {
                    "start":GetTodayWithShortFormat(1,"08:30:00"),
                    "end":GetTodayWithShortFormat(1,"12:30:00"),
                    "title": GetSystemLanguage() == "Tr" ? "Ürün v1.2 analizin tamamlanması" : "Completion of product v1.2 analysis",
                    "description": GetSystemLanguage() == "Tr" ? "Ürün v1.2 analizin tamamlanması" : "Completion of product v1.2 analysis",                    
                    "imageUrl" : "",
                    "color" : "red",
                    "owner" : "Volkan Seçginli"
                },
                {
                    "start":GetTodayWithShortFormat(3,"08:30:00"),
                    "end":GetTodayWithShortFormat(3,"12:30:00"),
                    "title": GetSystemLanguage() == "Tr" ? "Eylül24 canlı yayın sunumunun iletilmesi" : "September24 transmission of live broadcast presentation",
                    "description": GetSystemLanguage() == "Tr" ? "Eylül24 canlı yayın sunumunun iletilmesi" : "September24 transmission of live broadcast presentation",                    
                    "imageUrl" : "",
                    "color" : "red",
                    "owner" : "Volkan Seçginli"
                },                
                {
                    "start":GetTodayWithShortFormat(9,"08:30:00"),
                    "end":GetTodayWithShortFormat(9,"12:30:00"),
                    "title": GetSystemLanguage() == "Tr" ? "ABC müşterisine teklif hazırlanıp iletilmesi" : "Preparation and submission of an offer to ABC customer",
                    "description": GetSystemLanguage() == "Tr" ? "ABC müşterisine teklif hazırlanıp iletilmesi" : "Preparation and submission of an offer to ABC customer",                    
                    "imageUrl" : "",
                    "color" : "red",
                    "owner" : "Volkan Seçginli"
                },
                {
                    "start":GetTodayWithShortFormat(0,"08:30:00"),
                    "end":GetTodayWithShortFormat(0,"12:30:00"),
                    "title": GetSystemLanguage() == "Tr" ? "Kıdem Töreni" : "Seniority Ceremony",
                    "description": 
                    
                    "<p><strong>08:30 - 09:00:</strong> Açık Büfe Kahvaltı ve Networking</p> "+
                
                    "<p><strong>09:00 - 09:10:</strong> Hoşgeldiniz Konuşması (Şirket CEO’su)</p> "+
                    "<p><strong>09:10 - 09:30:</strong> Etkinlik Programının Tanıtımı (Organizatör)</p> "+
                    "<p><strong>09:30 - 10:00:</strong> Şirketin Son Dönem Başarıları ve Gelecek Planları (CEO veya Yönetici)</p> "+
                
                    "<p><strong>10:00 - 11:00:</strong> Ana Oturum - İnovasyon ve Teknoloji (Konuk Konuşmacı)</p> "+
                    "<p><strong>11:00 - 11:15:</strong> Kahve Molası</p> "+
                    "<p><strong>11:15 - 12:00:</strong> Paralele Çalıştaylar:</p> "+
                    "<ul> "+
                    "    <li>Atölye A: Dijital Dönüşüm Stratejileri</li> "+
                    "    <li>Atölye B: Liderlik ve Takım Yönetimi</li> "+
                    "</ul> "+
                
                    "<p><strong>12:00 - 12:10:</strong> Öğle Yemeğine Geçiş</p> "+
                    "<p><strong>12:10 - 13:00:</strong> Açık Büfe Öğle Yemeği ve Networking</p> "+

                    "<p><strong>13:00 - 14:00:</strong> Panel - Endüstri Trendleri ve Gelecek Öngörüleri (Panelistlerle)</p> "+
                    "<p><strong>14:00 - 15:00:</strong> Paralele Çalıştaylar:</p> "+
                    "<ul> "+
                    "    <li>Atölye C: Müşteri Deneyimi ve Memnuniyeti</li> "+
                    "    <li>Atölye D: Verimlilik ve Performans Artırma</li> "+
                    "</ul> "+
                
                    "<p><strong>15:00 - 15:10:</strong> Ara ve Atıştırmalık</p> "+
                    "<p><strong>15:10 - 15:30:</strong> Hafif Egzersiz veya Networking Zamanı</p> "+
                
                    "<p><strong>15:30 - 16:15:</strong> Grup Çalışması - Yenilikçi Proje Fikirleri Geliştirme</p> "+
                    "<p><strong>16:15 - 17:00:</strong> Sunumlar ve Geri Bildirim Oturumu</p> "+
                
                    "<p><strong>17:00 - 17:30:</strong> Günün Özeti ve Kapanış Konuşması (Organizatör)</p> ",           
                    "imageUrl" : require("../assets/images/samples/Ceremony2.jpg").default,
                    "color" : "green"
                },
                {
                    "start":GetTodayWithShortFormat(0,"12:00:00"),
                    "end":GetTodayWithShortFormat(0,"14:00:00"),
                    "title": GetSystemLanguage() == "Tr" ? "Dünya Mutfağından Lezzetler - İtalya" : "Flavors from World Cuisine - Italy",
                    "description": ""+
                    "<h1 style='font-size:20px !important; font-weight:bold'>Antipasti (Başlangıçlar)</h1>"+
                    "<p><strong>Bruschetta</strong>: Genellikle sarımsakla ovulmuş ve zeytinyağı ile servis edilen kızarmış ekmek dilimleri üzerine taze domates, fesleğen ve mozzarella konulur.</p>"+
                    "<p><strong>Caprese Salatası</strong>: Taze mozzarella, domates ve fesleğenden yapılan basit ama lezzetli bir salata, zeytinyağı ve balsamik sirke ile tatlandırılır.</p>"+
                    "<p><strong>Carpaccio</strong>: İnce dilimlenmiş çiğ dana eti, limon suyu, zeytinyağı, peynir ve kapari ile servis edilir.</p>"+
                    "<h1 style='font-size:20px !important; font-weight:bold'>Primo (İlk Ana Yemek)</h1>"+
                    "<p><strong>Spaghetti alla Carbonara</strong>: Yumurta, peynir (genellikle Pecorino Romano), guanciale (domuz yanak yağı) ve karabiber ile yapılan kremasız makarna yemeği.</p>"+
                    "<p><strong>Risotto alla Milanese</strong>: Safranla tatlandırılmış, zengin ve kremsi bir pirinç yemeği.</p>"+
                    "<p><strong>Lasagna</strong>: Kıymalı domates sosu (ragù), beşamel sos ve peynir (genellikle Parmigiano Reggiano veya mozzarella) ile katmanlı olarak yapılan fırında makarna yemeği.</p>"+
                    "<h1 style='font-size:20px !important; font-weight:bold'>Secondo (İkinci Ana Yemek)</h1>"+
                    "<p><strong>Osso Buco</strong>: Genellikle beyaz şarap, sebzeler ve et suyu ile pişirilen dana incik.</p>"+
                    "<p><strong>Saltimbocca</strong>: Dana eti dilimleri üzerine prosciutto ve adaçayı yaprağı konulup beyaz şarap ve tereyağı ile pişirilen geleneksel bir yemek.</p>"+
                    "<p><strong>Pollo alla Cacciatora</strong>: Domates, biber, soğan, zeytin ve beyaz şarap ile pişirilmiş tavuk yemeği.</p>"+
                    "<h1 style='font-size:20px !important; font-weight:bold'>Contorni (Yan Yemekler)</h1>"+
                    "<p><strong>Caponata</strong>: Sicilya'ya özgü bir patlıcan yemeği, domates, soğan, kereviz ve kapari ile yapılan tatlı-ekşi bir sebze yemeği.</p>"+
                    "<p><strong>Fagiolini</strong>: Genellikle sarımsak ve zeytinyağı ile pişirilmiş taze yeşil fasulye.</p>"+
                    "<h1 style='font-size:20px !important; font-weight:bold'>Dolce (Tatlılar)</h1>"+
                    "<p><strong>Tiramisu</strong>: Kahveye batırılmış savoiardi (ladyfinger) bisküvileri, mascarpone peyniri ve kakao ile yapılan ünlü tatlı.</p>"+
                    "<p><strong>Gelato</strong>: İtalyan usulü dondurma, geleneksel dondurmadan daha yoğundur ve birçok farklı lezzette gelir.</p>"+
                    "<p><strong>Panna Cotta</strong>: Kremanın vanilya ile tatlandırılıp jelatinle katılaştırılmasıyla yapılan tatlı, genellikle meyve soslarıyla servis edilir.</p>"+
                    "<h1 style='font-size:20px !important; font-weight:bold'>İçecekler</h1>"+
                    "<p><strong>Espresso</strong>: Küçük ve güçlü bir kahve, İtalyan kültürünün ayrılmaz bir parçasıdır.</p>"+
                    "<p><strong>Limoncello</strong>: Limon kabukları, alkol ve şekerle yapılan tatlı bir likör, özellikle yemek sonrası içilir.</p>"+
                    "<p><strong>Prosecco</strong>: İtalya'nın Veneto bölgesinden gelen köpüklü beyaz şarap.</p>",
                    "imageUrl" : require("../assets/images/samples/ItalianFood.jpg").default,
                    "color" : "red"
                },
                {
                    "start":GetTodayWithShortFormat(3),
                    "end":GetTodayWithShortFormat(5),
                    "title": GetSystemLanguage() == "Tr" ? "OKR Belirleme Dönemi" : "OKR Determination Period",
                    "description": GetSystemLanguage() == "Tr" ? "Check-In 1 de OKR'larını belirlemeyi unutma!" : "Don't forget to set your OKRs at Check-In 1!",                    
                    "imageUrl" : require("../assets/images/birthday-templates/12.png").default,
                    "color" : "orange"
                },
                {
                    "start":GetTodayWithShortFormat(-2,"08:30:00"),
                    "end":GetTodayWithShortFormat(0,"17:30:00"),
                    "title": GetSystemLanguage() == "Tr" ? "Çalışanın Sesi" : "Employee Voice",
                    "description": GetSystemLanguage() == "Tr" ? "Çalışanların öneri ve şikayetlerini ilettiği bi oturumu kaçırma!" : "Don't miss a session where employees give their suggestions and complaints!",                    
                    "imageUrl" : require("../assets/images/samples/EmployeeSound.jpg").default,
                    "color" : "orange"
                },
                {
                    "start":GetTodayWithShortFormat(-5),
                    "end":GetTodayWithShortFormat(-2),
                    "title": GetSystemLanguage() == "Tr" ? "ISO 27001 Denetimleri" : "ISO 27001 Audits",
                    "description": GetSystemLanguage() == "Tr" ? "Çalışanların öneri ve şikayetlerini ilettiği bi oturumu kaçırma!" : "Don't miss a session where employees give their suggestions and complaints!",                    
                    "imageUrl" : require("../assets/images/birthday-templates/12.png").default,
                    "color" : "blue"
                },
                {
                    "start":GetTodayWithShortFormat(1,"14:00:00"),
                    "end":GetTodayWithShortFormat(1,"15:00:00"),
                    "title": GetSystemLanguage() == "Tr" ? "CEO'muz ile canlı yayındayız!" : "We are live with our CEO!",
                    "description": GetSystemLanguage() == "Tr" ? "Çalışanların öneri ve şikayetlerini ilettiği bi oturumu kaçırma!" : "Don't miss a session where employees give their suggestions and complaints!",                    
                    "imageUrl" : require("../assets/images/birthday-templates/12.png").default,
                    "color" : "red"
                },
                ,
                {
                    "start":"2024-05-10T16:00:00",
                    "end":"2024-05-10T17:30:00",
                    "title": GetSystemLanguage() == "Tr" ? "Happy Hours!" : "Happy Hours!",
                    "description": GetSystemLanguage() == "Tr" ? "Çalışanların öneri ve şikayetlerini ilettiği bi oturumu kaçırma!" : "Don't miss a session where employees give their suggestions and complaints!",                    
                    "imageUrl" : require("../assets/images/birthday-templates/12.png").default,
                    "color" : "purple"
                }
            ];
            case "tasks":
                return [
                    {
                        "start":GetTodayWithShortFormat(2,"08:30:00"),
                        "end":GetTodayWithShortFormat(2,"17:30:00"),
                        "title":"Ürün v1.2 analizin tamamlanması",
                        "description": "Ürün v1.2 analizin tamamlanması",                    
                        "imageUrl" : "",
                        "color" : "red",
                        "owner" : "Volkan Seçginli",
                    },
                    {
                        "start":GetTodayWithShortFormat(8,"08:30:00"),
                        "end":GetTodayWithShortFormat(8,"17:30:00"),
                        "title":"Eylül24 canlı yayın sunumunun iletilmesi",
                        "description": "Eylül24 canlı yayın sunumunun iletilmesi",                    
                        "imageUrl" : "",
                        "color" : "red",
                        "owner" : "Volkan Seçginli"
                    },
                    
            ];
            
            case "tasksMyTeam":
                return [
                    {
                        "start":GetTodayWithShortFormat(2,"08:30:00"),
                        "end":GetTodayWithShortFormat(2,"17:30:00"),
                        "title":"Ürün v1.2 analizin tamamlanması",
                        "description": "Ürün v1.2 analizin tamamlanması",                    
                        "imageUrl" : "",
                        "color" : "red",
                        "owner" : "Volkan Seçginli",
                    },
                    {
                        "start":GetTodayWithShortFormat(8,"08:30:00"),
                        "end":GetTodayWithShortFormat(8,"17:30:00"),
                        "title":"Eylül24 canlı yayın sunumunun iletilmesi",
                        "description": "Eylül24 canlı yayın sunumunun iletilmesi",                    
                        "imageUrl" : "",
                        "color" : "red",
                        "owner" : "Volkan Seçginli"
                    },
                    {
                        "start":GetTodayWithShortFormat(4,"08:30:00"),
                        "end":GetTodayWithShortFormat(4,"17:30:00"),
                        "title":"ABC müşterisine teklif hazırlanıp iletilmesi",
                        "description": "ABC müşterisine teklif hazırlanıp iletilmesi",                    
                        "imageUrl" : "",
                        "color" : "red",
                        "owner" : getRandomName() + getRandomSurName()
                    },
                    {
                        "start":GetTodayWithShortFormat(10,"08:30:00"),
                        "end":GetTodayWithShortFormat(10,"17:30:00"),
                        "title":"CRM ekibine VNS sunumunun planlaması",
                        "description": "Görev talebi kısa açıklaması",                    
                        "imageUrl" : "",
                        "color" : "red",
                        "owner" : getRandomName() + getRandomSurName()
                    }
                    
            ];
    
            default:
            return null;
    }
}