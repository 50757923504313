import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../state';
import {  AutoComplete, Button, Card, Checkbox, Col, Collapse, DatePicker, Divider, Form, Input, Modal, Popconfirm, Row,  Select,  Space,  Table, Tabs,  TabsProps,  Tooltip, Upload } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import Column from 'antd/lib/table/Column';
import { GetOperationResourceData, GetResourceData } from '../helpers/basemethods';
import { getMyDemandContent, getTaskTrackingMyTasks, getTaskTrackingMyTeamTasks, setMyDemandContent } from '../state/reducers/DemandReducer';
import { CostRequestType, DayOffRequestType, emptyGuid, VacationRequestType } from '../helpers/constantData';
import RequestTable from './RequestTable';
import { isMobile } from 'react-device-detect';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import TaskTrackingTable from './TaskTrackingTable';
import {UploadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import TaskTrackingTaskCalendar from './TaskTrackingTaskCalendar';
import { GetSearchDelegatedResult } from '../business/Public/CommonBusiness';
import TextArea from 'antd/lib/input/TextArea';
import { showNoti } from '../helpers/root';
import { NotifierHeaderInfoType, NotifierResultType } from '../helpers/enums';

export interface TaskTrackingAddNewTaskProps { isMyTeam : boolean, showNewItem, setShowNewItem }
const TaskTrackingAddNewTask: React.FC<TaskTrackingAddNewTaskProps> = (props) => {
    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false)

    const [SearchResults, setSearchResults] = useState<any[]>([])
    const [SearchLoading, setSearchLoading] = useState<boolean>(false);
    const [SearchUser, setSearchUser] = useState<string>("");
    const [delegatedUser, setDelegatedUser] = useState<string>("");

    const [form] = Form.useForm();

   

      function searchHandler (e) {
        if (e.target.value.length>=3) {            
            setSearchLoading(true);
            findUser(SearchUser);
        }
        else{
            setSearchResults([])
        }
     
    }

    const findUser = async (searchKey) => {
        let searchResultData = await GetSearchDelegatedResult(searchKey);
        if (searchKey === null || searchKey === undefined || searchKey === '') {
            setSearchResults(searchResultData.filter(x=> x.value.includes("xyzt")))
        }
        else{
            const filteredResult = searchResultData.filter(item =>
                item.value.toLowerCase().includes(searchKey.toLowerCase())
              );
            setSearchResults(filteredResult)
        }
        setSearchLoading(false)    
    }

    const _save = (ok) => { 
    console.log(form.getFieldValue("taskName"));
    if(ok){
        showNoti(NotifierResultType.success, GetOperationResourceData(NotifierHeaderInfoType.notifierSuccess), GetOperationResourceData("AddTaskSuccess"))
    }    
    props.setShowNewItem(null)
    form.resetFields();
    // let postParams = {
    //     sequence : params.sequence,
    //     redirectUrl : params.redirectUrl,
    //     isActive : isBannerActive,
    //     startDate: moment(params.startDate).format("YYYY-MM-DDT00:00:00"),
    //     endDate: moment(params.endDate).format("YYYY-MM-DDT23:59:00"),
    //     entityHeader: entityHeaderConstant,
    //     values : ValidLanguages.map(lang => ((
    //         {
    //             lang: lang,
    //             title: titleValues.find(x=> x.lang == lang).value,
    //             description: descValues.find(x=> x.lang == lang).value,
    //             bannerImageId: bannerImgValues.find(x=> x.lang == lang).value,
    //             id : mode === formModes.EDIT ? bannerDetail.values.find(x=> x.lang == lang).id : emptyGuid
    //         })))
    // }      
       
    }

    return (
        <React.Fragment>    
            <>   
             {
              <Form form={form} layout='vertical' >
                 <Modal
                    width={"600px"}
                    open={props.showNewItem}
                    onCancel={() => { props.setShowNewItem(null) }}
                    destroyOnClose
                    footer={null}
                    className='thanks-modal birthday-greeting detail-modal'
                    title={props.isMyTeam ? "Ekibime Görev Ekle" : "Yeni Görev Ekle"}
                >
                

                <Row gutter={{ sm: 20 }}>

                    
                    {
                      props.isMyTeam &&
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Form.Item name="taskOwner" rules={[{ required: true, message: 'Lütfen görev sorumlusu giriniz!' }]}>
                          <AutoComplete
                                                options={SearchResults} // Arama sonuçlarını options prop'una uygun formata dönüştürüyoruz
                                                onSelect={setDelegatedUser}
                                                value={SearchUser}
                                                onKeyUp={searchHandler}
                                                placeholder={"Görev Atanacak Sorumlu Kişi"}
                                                onChange={(e) => { setSearchUser(e) }}
                                                allowClear={true}
                                                onClear={() => { setDelegatedUser(""); setSearchResults([]) }}
                                                >
                                                {/* <Input.Search  onKeyDown={(e) => { if (e.key === "Enter") { searchHandler() } }} placeholder={GetResourceData("SearchByName")} enterButton={GetResourceData("Search")} addonBefore={<SearchOutlined />} loading={SearchLoading} /> */}
                                            </AutoComplete>

                          </Form.Item>
                      </Col>
                    }                    
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item  name="taskName" rules={[{ required: true, message: 'Lütfen görev adı giriniz!' }]}>
                            <Input placeholder='Görev Adı' />
                        </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item  name="taskTargetDate" rules={[{ required: true, message: 'Lütfen hedef tarihini giriniz!' }]}>
                            <DatePicker placeholder='Hedef Tarih' style={{ width: "100%" }} format="DD.MM.YYYY" />
                        </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item name="taskDesc" rules={[{ required: true, message: 'Lütfen görev açıklaması giriniz!' }]}>
                            <TextArea rows={4}  placeholder='Görev Açıklaması' />
                        </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item name="tasktPriority" rules={[{ required: true, message: 'Lütfen görev önceliği giriniz!' }]}>
                          <Select placeholder="Görev Önceliği">
                              <Select.Option value="1">Kritik</Select.Option>
                              <Select.Option value="2">Yüksek</Select.Option>
                              <Select.Option value="3">Orta</Select.Option>
                              <Select.Option value="4">Düşük</Select.Option>
                          </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item>
                      <Upload >
                      <Button icon={<UploadOutlined />}>Dosya Eki</Button>
                      </Upload>
                  </Form.Item>
                    </Col>

                </Row>

                    <Row>
                      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                      <Form.Item>
                          <Space size={8}>
                              <Button loading={loading} htmlType="submit" onClick={() => _save(true)}>Kaydet</Button>
                          </Space>
                      </Form.Item>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                      <Form.Item>
                          <Space size={8}>
                              <Button loading={loading} htmlType="submit" onClick={() => _save(false)}>İptal</Button>
                          </Space>
                      </Form.Item>
                      </Col>
                    </Row>
                

                </Modal>
                </Form>

              }
           </>
        </React.Fragment>
    );
}

export default TaskTrackingAddNewTask;