import { createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios";
import { AppDispatch, AppThunk } from "..";
import { getUser } from "../../helpers/root";
import { callApi } from "../../helpers/service";
import { ServiceResult } from "../../type/ServiceResult";
import { User } from "../../type/User";
import { SetUserLanguage } from "../../helpers/basemethods";


export type AuthReducerType = {
    loading: boolean;
    currentUser: User | null;
    delegatedUser: User | null;
    realUser: User | null;
    currentUserPic: string | null;
}

const initialState: AuthReducerType = {
    loading: false,
    currentUser: null,
    delegatedUser: null,
    realUser: null,
    currentUserPic: null
}

const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCurrentUser: (state, action) => void (state.currentUser = action.payload),
        setDelegatedUser: (state, action) => void (state.delegatedUser = action.payload),
        setRealUser: (state, action) => void (state.realUser = action.payload),
        setCurrenUserPic: (state, action) => void (state.currentUserPic = action.payload),
        setLoading: (state, action) => void (state.loading = action.payload),
    }
});

export const {
    setLoading,
    setCurrentUser,
    setCurrenUserPic,
    setDelegatedUser,
    setRealUser
} = authReducer.actions;


export const loginDelegatedUser = (user: User) => async (dispatch: AppDispatch) => {
    dispatch(setDelegatedUser(user));
    dispatch(setRealUser(getUser()))
    sessionStorage.setItem("delegatedUser", JSON.stringify(user));
    sessionStorage.setItem("realUser", JSON.stringify(getUser()));
    window.location.reload();
};

export const logoutDelegatedUser = () => async (dispatch: AppDispatch) => {
    dispatch(setDelegatedUser(null));
    dispatch(setRealUser(null));
    sessionStorage.removeItem("delegatedUser");
    sessionStorage.removeItem("realUser");
    window.location.reload();
};

export const getDelegatedUserFromSession = () => async (dispatch: AppDispatch) => {
    try {
        const delegatedUserFromSession = sessionStorage.getItem("delegatedUser");
        const realUserFromSession = sessionStorage.getItem("realUser");
        if (delegatedUserFromSession && realUserFromSession) {
            const delegatedUserJson = JSON.parse(delegatedUserFromSession);
            const realUserJson = JSON.parse(realUserFromSession);
            dispatch(setDelegatedUser(delegatedUserJson));
            dispatch(setRealUser(realUserJson));

        } else {
            console.warn("Can not find the delegated user");
        }
    } catch (e) {
        console.error("Can not find or parse the delegated user: ", e);
    }
};

export const getCurrentUser = () => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    const userData = {
        mood:2,
        id:1,
        email:"volkan.secginli@vnsteknoloji.com",
        profilePhoto:"dasdas",
        roles:["Admin"],
        name: "Volkan",
        userName: "volkan.secginli",
        fullName: "Volkan Seçginli",
        unvan: "IT Manager",
        department: "IT",
        hireDate : "01.01.2020",
        managerUserName : "volkan.secginli",
        managerFullName : "Volkan Seçginli",
        phoneNumber : "5436566565",
        mobileShortCode: "9488",
        birthDay : "01.01.1999",
        currentLocation : "Kadıköy",
        currentLocationId : 1,
        language : "En"
    }
    SetUserLanguage(userData.language);
    dispatch(setCurrentUser(userData));
    dispatch(setLoading(false));
};


export default authReducer.reducer;