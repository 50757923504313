import { AxiosResponse } from "axios";
import { urlGetAllContentCategoriesForUI } from "../../helpers/apiUrls";
import { GetSystemLanguage, GetTodayWithCertainFormat, GetTodayWithCertainFormatAddDays, GetTodayWithShortFormat } from "../../helpers/basemethods";
import { callApi } from "../../helpers/service";
import { BusinessResponseType } from "../../type/Common/BusinessResponseType";
import { ServiceResult } from "../../type/ServiceResult";
import { getRandomDepartment, getRandomEmail, getRandomName, getRandomUsername } from "../../helpers/samples";
//Banner itemlarının alındığı servis

export const GetBannerItems = async () =>{
    let resultData:any = null;
    await callApi({
        url: "/admin/Banner/GetActiveBanners",
        useToken: true,
        successCallback:(response: AxiosResponse<any>) => {
            resultData = response.data.result;
        },
        errorCallback: (e) => {
            resultData = [];
        },
        finish: () =>{
            
        }
        
    })
    return resultData;
  }


//Etkinlik itemlarının alındığı servis
export function GetActivityCalendarItems():BusinessResponseType<any> {
    // let params = {
    //     startDate: moment().startOf("month").add(-7, "day").format("YYYY-MM-DDT00:00:00"),
    //     endDate: moment().endOf("month").add(7, "day").format("YYYY-MM-DDT23:59:00"),
    // }
    // callApi({
    //     url: "api/EventCalendar/GetEventCalendarByDate",
    //     useToken: true, method: methods.POST, data: params,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             let $events: any[] = [];
    //             res.data.result.forEach(element => {
    //                 $events.push({ ...element, start: element.eventStartDate, end: element.eventEndDate })
    //             });
    //             dispatch(setActivities($events));
    //         }
    //     }
    // })
    return {
        isSuccessful : true,
        result : [
            {
                "start":GetTodayWithShortFormat(0),
                "end":GetTodayWithShortFormat(2),
                "title": GetSystemLanguage() == "Tr" ? "Kıdem Töreni" : "Seniority Ceremony",
                "description": GetSystemLanguage() == "Tr" ? "Kıymetli çalışanlarımızın kıdem töreni :)" : "Seniority ceremony of our valuable employees :)"
            },
            {
                "start":GetTodayWithShortFormat(3),
                "end":GetTodayWithShortFormat(5),
                "title": GetSystemLanguage() == "Tr" ? "OKR Belirleme Dönemi" : "OKR Determination Period",
                "description": GetSystemLanguage() == "Tr" ? "Check-In 1 de OKR'larını belirlemeyi unutma!" : "Don't forget to set your OKRs at Check-In 1!"
            },
            {
                "start":"2024-02-29T09:00:00",
                "end":"2024-02-29T12:00:00",
                "title": GetSystemLanguage() == "Tr" ? "Çalışanın Sesi" : "Employee Voice",
                "description": GetSystemLanguage() == "Tr" ? "Çalışanların öneri ve şikayetlerini ilettiği bi oturumu kaçırma!" : "Don't miss a session where employees give their suggestions and complaints!"
            },
            {
                "start":"2024-02-29T09:00:00",
                "end":"2024-03-16T12:00:00",
                "title": GetSystemLanguage() == "Tr" ? "Çalışanın Sesi" : "Employee Voice",
                "description": GetSystemLanguage() == "Tr" ? "Çalışanların öneri ve şikayetlerini ilettiği bi oturumu kaçırma!" : "Don't miss a session where employees give their suggestions and complaints!"
            }
        ]
    };
}

//Büyük takvimde ileri geri gidildiğinde
export function GetEventCalendarByDate(startDate, endDate):BusinessResponseType<any> {
    // callApi({
    //     url: `api/EventCalendar/GetEventCalendarByDate`,
    //     data: params,
    //     method: methods.POST,
    //     useToken: true,
    //     successCallback: (response) => {
    //       if (response.data.isSuccessful) {
    //         let $events: any[] = [];
    //         response.data.result.forEach(element => {
    //           $events.push({ ...element, start: element.eventStartDate, end: element.eventEndDate })
    //         });
    //         setCalendarData($events)
    //       }
    //     },
    //     finish: () => {
    //       console.log("bitti"); 
    //     }
    //   })
    return {
        isSuccessful : true,
        result : [
            {
                "start":GetTodayWithShortFormat(0),
                "end":GetTodayWithShortFormat(2),
                "title": GetSystemLanguage() == "Tr" ? "Kıdem Töreni" : "Seniority Ceremony",
                "description": GetSystemLanguage() == "Tr" ? "Kıymetli çalışanlarımızın kıdem töreni :)" : "Seniority ceremony of our valuable employees :)"
            },
            {
                "start":GetTodayWithShortFormat(3),
                "end":GetTodayWithShortFormat(5),
                "title": GetSystemLanguage() == "Tr" ? "OKR Belirleme Dönemi" : "OKR Determination Period",
                "description": GetSystemLanguage() == "Tr" ? "Check-In 1 de OKR'larını belirlemeyi unutma!" : "Don't forget to set your OKRs at Check-In 1!"
            },
            {
                "start":"2024-02-29T09:00:00",
                "end":"2024-02-29T12:00:00",
                "title": GetSystemLanguage() == "Tr" ? "Çalışanın Sesi" : "Employee Voice",
                "description": GetSystemLanguage() == "Tr" ? "Çalışanların öneri ve şikayetlerini ilettiği bi oturumu kaçırma!" : "Don't miss a session where employees give their suggestions and complaints!"
            },
            {
                "start":"2024-02-29T09:00:00",
                "end":"2024-03-16T12:00:00",
                "title": GetSystemLanguage() == "Tr" ? "Çalışanın Sesi" : "Employee Voice",
                "description": GetSystemLanguage() == "Tr" ? "Çalışanların öneri ve şikayetlerini ilettiği bi oturumu kaçırma!" : "Don't miss a session where employees give their suggestions and complaints!"
            }
        ]
    };
}


//Fikir tahtasının dolduğu yer
export function getBoardIdeaItems():BusinessResponseType<any> {
    // callApi({
    //     url: "User/GetCurrentWeekBirthdays",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setBornToWeek(res.data.result));
    //         }
    //     }
    // })
    return {
        isSuccessful : true,
        result : [
            {
                "Key":1,
                "Owner": getRandomName(),
                "Title" : GetSystemLanguage() == "Tr" ? "Yemekhane kapılarına kamera koyulması" : "Installing cameras at the dining hall doors",
                "Content" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac iaculis sem. Duis gravida, felis in aliquam auctor, tortor arcu auctor urna, quis convallis odio eros ut mauris. ",
                "Date" : "24.04.2024",
                "Rate" : "4/5",
                "Types": ["1","6"],
                "UserName": getRandomName().replace(" ","."),
                "Voted" : false,
                "VoteRate" : 0,
                "Updatable" : true
            },
            {
                "Key":2,
                "Owner": getRandomName(),
                "Title" : GetSystemLanguage() == "Tr" ? "Giriş-Çıkış turnikelerine QR entegrasyonu getirilmesi" : "Introducing QR integration to Entrance-Exit turnstiles",
                "Content" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac iaculis sem. Duis gravida, felis in aliquam auctor, tortor arcu auctor urna, quis convallis odio eros ut mauris. ",
                "Date" : "28.04.2024",
                "Rate" : "3/5",
                "Types": ["1"],
                "UserName": getRandomName().replace(" ","."),
                "Voted" : true,
                "VoteRate" : 4,
                "Updatable" : true
            }            
            ]
    };
}


//Fikir oylamasının gönderildiği metod
export function sendIdeaVote(IdeaItem, point):BusinessResponseType<any> {
    // callApi({
    //     url: `/User/SendIdeaVote`, useToken: true, data: data, method: methods.POST,
    return {
        isSuccessful : true,
        result : []
    };
}

//Bu hafta doğan kişilerin alındığı yer
export function getBornToWeekItems():BusinessResponseType<any> {
    // callApi({
    //     url: "User/GetCurrentWeekBirthdays",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setBornToWeek(res.data.result));
    //         }
    //     }
    // })
    return {
        isSuccessful : true,
        result : [
            {
                "birthDay": GetTodayWithCertainFormatAddDays(-2),
                "users":[{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },
                {
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                }]
            },
            {
                "birthDay": GetTodayWithCertainFormatAddDays(-1),
                "users":[{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },
            ]
            },
            {
                "birthDay": GetTodayWithCertainFormat(),
                "users":[{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },
                {
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },
                {
                    userName: "volkan.secginli",
                    Owner : "Volkan Seçginli",
                    email : "volkansecginli@vns.com.tr"
                    
                },
                {
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },
                {
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                }]
            },
            
            {
                "birthDay": GetTodayWithCertainFormatAddDays(1),
                "users":[{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                }
            ]
            },
            
            {
                "birthDay": GetTodayWithCertainFormatAddDays(2),
                "users":[{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                }
            ]
            },
            
            {
                "birthDay": GetTodayWithCertainFormatAddDays(3),
                "users":[{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                },{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                }
            ]
            },
            
            {
                "birthDay": GetTodayWithCertainFormatAddDays(4),
                "users":[{
                    userName: getRandomUsername(),
                    Owner : getRandomName(),
                    email : getRandomEmail()
                }
            ]
            },
        ]
    };
}

//Tebrik et metodunun arka planı
export function sendGiftToUser(data):BusinessResponseType<any> {
    // callApi({
    //     url: `/User/SendBirthdayMail`, useToken: true, data: data, method: methods.POST,
    //     successCallback: (response: any) => {
    //       if (response.data.isSuccessful) {
    //         setGreetingItem(null)
    //         showNoti(NotifierResultType.success, "Başarılı", `Mesajınız ${getRandomName()} kişisine başarıyla gönderilmiştir!`);
    //       } else {
    //         showNoti(NotifierResultType.error, "Hata", "Mesajınız gönderilirken bir hata meydana geldi!");
    //       }
    //     }
    //   });
    return {
        isSuccessful : true,
        result : []
    };
}


//Günlük yemek menüsü getiren servis
export function getMealMenuItems():BusinessResponseType<any> {
    // callApi({
    //     url: "api/MealMenu/GetCurrentWeekMenu",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setMealMenu(res.data.result));
    //         }
    //     }
    // })
    return {
        isSuccessful : true,
        result : [
            {
                "date" : GetTodayWithShortFormat(0) ,
                "desserts":[
                    {
                        "allergen": [1,2,6],
                        "calorie":"269",
                        "name":GetSystemLanguage() == "Tr" ? "Kırmızı Kadife Kek" : "Red Velvet Cake"
                    },
                    {
                        "allergen": [],
                        "calorie":"123",
                        "name": GetSystemLanguage() == "Tr" ? "Meyve" : "Fruit"
                    }
                ],
                "mainMeals":[
                    {
                        "allergen": [5,3,2],
                        "calorie":"111",
                        "name":  GetSystemLanguage() == "Tr" ?  "Yayla Çorbası" : "Highland Soup :)"
                    },
                    {
                        "allergen": [8],
                        "calorie":"44",
                        "name": GetSystemLanguage() == "Tr" ?  "Çoban Kavurma" : "Shepherd Roasting"
                    }, {
                        "allergen": [1,3,4],
                        "calorie":"269",
                        "name":GetSystemLanguage() == "Tr" ?  "Domatesli Semiz Otu" : "Purslane with Tomato"
                    },
                    {
                        "allergen": [],
                        "calorie":"123",
                        "name":GetSystemLanguage() == "Tr" ?  "Karnabahar" : "Cauliflower"
                    }
                ],
                "salads": [
                    {
                        "allergen": [1],
                        "calorie":"269",
                        "name": GetSystemLanguage() == "Tr" ? "Haydari" : "Haydari"
                    },
                    {
                        "allergen": [],
                        "calorie":"666",
                        "name":GetSystemLanguage() == "Tr" ? "Yoğurt" : "Yogurt"
                    }
                ],
            }
        ]
    };
}

//Yeni katılan kullanıcılar listesi
export function getNewEmployeeItems():BusinessResponseType<any> {
    // callApi({
    //     url: "User/GetNewUsers",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setNewEmployees(res.data.result));
    //         }
    //     }
    // })
    return {
        isSuccessful : true,
        result : [
            {
                "userName" : getRandomUsername(),
                "Owner" : getRandomName(),
                "Department" : getRandomDepartment()
            },
            {
                "userName" : getRandomUsername(),
                "Owner" : getRandomName(),
                "Department" : getRandomDepartment()
            },
            {
                "userName" : getRandomUsername(),
                "Owner" : getRandomName(),
                "Department" : getRandomDepartment()
            },
            {
                "userName" : getRandomUsername(),
                "Owner" : getRandomName(),
                "Department" : getRandomDepartment()
            },
            {
                "userName" : getRandomUsername(),
                "Owner" : getRandomName(),
                "Department" : getRandomDepartment()
            },
            {
                "userName" : getRandomUsername(),
                "Owner" : getRandomName(),
                "Department" : getRandomDepartment()
            },
            {
                "userName" : getRandomUsername(),
                "Owner" : getRandomName(),
                "Department" : getRandomDepartment()
            }
    ]
    };
}

//bizden haberler modülünü dolduran servis
export function getOurNewsItems():BusinessResponseType<any> {
    // callApi({
    //     url: "User/GetCurrentWeekBirthdays",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setBornToWeek(res.data.result));
    //         }
    //     }
    // })
    return {
        isSuccessful : true,
        result : [            
            {
                title : GetSystemLanguage() == "Tr" ? "THY ile 5 yıllık anlaşmamızı imzaladık!" : "We signed a 5-year agreement with THY!" ,
                date: GetSystemLanguage() == "Tr" ? "3 gün önce" : "3 days ago",
                img: require('../../assets/images/samples/thy.jpg'),
                header: require('../../assets/images/samples/thy-header.png').default,
                id : "1",
    
            },
            {
                title : GetSystemLanguage() == "Tr" ? "AI’ın Geleceği Hangisi: AGI mı yoksa GenAI mı?" : "Which is the Future of AI: AGI or GenAI?" ,
                date: GetSystemLanguage() == "Tr" ? "5 gün önce" : "5 days ago",
                img: require('../../assets/images/samples/genai.jpg'),
                header: require('../../assets/images/samples/genai-header.png').default,
                id : "2",
    
            },
            {
                title : GetSystemLanguage() == "Tr" ? "İzmir Yeni Veri merkezimizde hizmete geçtik." : "We started service in our new data center in Izmir.",
                date: GetSystemLanguage() == "Tr" ? "7 gün önce" : "7 days ago",
                img: require('../../assets/images/samples/izmir.jpg'),
                header: require('../../assets/images/samples/veri-header.png').default,
                id : "3",
    
            },
            {
                title : GetSystemLanguage() == "Tr" ? "Teknoloji ARGE ekibimiz ile İTÜ Arasında İşbirliği imzalandı." : "Cooperation was signed between our Technology R&D team and ITU.",
                date: GetSystemLanguage() == "Tr" ? "9 gün önce" : "9 days ago",
                img: require('../../assets/images/samples/itu.jpg'),
                header: require('../../assets/images/samples/arge-header.png').default,
                id : "4",
    
            }
    ]
    };
}

export const GetNewsFromUsDetail = async (currentNews,id)  => {
    let result = {};
    await callApi({
      url: urlGetAllContentCategoriesForUI,
      useToken: true,
      successCallback: (res: AxiosResponse<any>) => {
          if (res.status === 200) { 
            
          }
      },
      errorCallback: (e) => {  
      },
      finish: () =>{           
        result = {
            "imageUrl" : currentNews[0]['header'],
            "title" : currentNews[0]['title'],
            "content" : "<div id='lipsum'> "+
            "<p>"+
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis nisl quis velit posuere, in congue mi semper. Nunc at ex sit amet lorem varius placerat. Fusce vestibulum, velit at ultrices iaculis, sapien arcu cursus felis, in bibendum tortor enim sit amet nulla. Proin non tortor quis sapien sodales blandit vestibulum sit amet nulla. Nullam dui nibh, efficitur ac enim et, pellentesque congue est. Ut porta libero sit amet urna ultricies, vel posuere ante tempor. Morbi vitae congue augue."+
            "</p><br/>"+
            "<p>"+
            "Suspendisse dolor turpis, finibus vitae enim et, consectetur varius eros. Ut vel velit quis elit convallis porta in ut ligula. Sed enim quam, bibendum vitae bibendum sit amet, luctus eu ipsum. Cras sodales enim et magna tempus, vitae finibus diam tincidunt. In ultricies lectus sed diam vulputate ultricies. Quisque ut erat ipsum. Integer eleifend diam orci, eget convallis ipsum commodo sit amet. Integer tortor turpis, mattis et lectus non, elementum facilisis mi. Vivamus aliquam, velit a porta congue, lectus risus porttitor libero, vel tempus urna magna vitae orci. Proin tempor turpis scelerisque, eleifend sapien ut, efficitur lorem. Donec et congue arcu. Aliquam erat volutpat. Duis vitae commodo turpis. Duis faucibus scelerisque ligula vitae imperdiet. Integer consequat convallis erat vitae tincidunt."+
            "</p><br/>"+
            "<p>"+
            "Praesent aliquam, lorem ornare dictum mollis, urna magna imperdiet tortor, ac gravida lacus neque vel odio. Phasellus id arcu porta, efficitur eros quis, varius mi. Aliquam erat volutpat. Nulla feugiat maximus dui sit amet suscipit. Integer hendrerit, nunc in ullamcorper tincidunt, dui nulla efficitur dolor, vitae gravida tellus nisi quis nulla. Nulla nisi metus, pulvinar ac neque at, commodo porta elit. Aenean viverra maximus elit a sodales. Aenean fringilla, ante non lobortis ornare, magna ex fermentum ante, at mattis mauris mauris id leo. Nunc sed sagittis tortor. Quisque a imperdiet est. Nulla ac leo nulla. Suspendisse potenti. Maecenas rhoncus odio vel ante mollis, nec volutpat ante lacinia. Donec ut lectus ligula."+
            "</p><br/>"+
            "<p>"+
            "Proin in sem vulputate, pulvinar ante et, ornare odio. Donec vitae fermentum dolor. Vivamus scelerisque vehicula sagittis. Vivamus dictum, nisi nec sollicitudin tincidunt, sem felis euismod enim, et feugiat metus ipsum sit amet sem. Suspendisse aliquam, eros non mattis pellentesque, orci nisi eleifend metus, at ullamcorper ligula tellus id nisl. Nullam ullamcorper pulvinar eros et ullamcorper. Nam quis euismod diam, vitae dapibus quam."+
            "</p><br/>"+
            "<p>"+
            "Cras ac malesuada eros. Morbi nec eros eu orci eleifend tincidunt condimentum vitae magna. In sed tellus id urna tincidunt congue. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec posuere interdum maximus. Aliquam blandit vel nunc at pharetra. Mauris aliquam, felis quis tristique lacinia, massa magna sagittis odio, vel elementum tortor nisi eu ex. Sed tristique, neque eu imperdiet maximus, nibh nisl aliquam arcu, et sollicitudin nibh enim eget elit. Etiam tempus, urna et dapibus tempor, ex enim tristique purus, eget sagittis metus nulla quis purus. Pellentesque hendrerit tempus nisi, vitae lobortis lectus consequat non."+
            "</p></div>"
            
        }
      }});
  
    return result;

  };

  
export const GetNewsFromUsTree = async ()  => {
    let result = {};
    await callApi({
      url: urlGetAllContentCategoriesForUI,
      useToken: true,
      successCallback: (res: AxiosResponse<any>) => {
          if (res.status === 200) { 
            
          }
      },
      errorCallback: (e) => {  
      },
      finish: () =>{           
        result = {
            "years" : [
                {
                "year" : "2024",
                "months" : [
                    {
                        "Month" : "Ocak",
                        "Titles" : ["Lorem ipsum dolor sit amet, consectetur adipiscing elit.","Cras aliquam nisl ac lorem varius accumsan." ] 
                    },
                    {
                        "Month" : "Mart",
                        "Titles" : ["Duis vitae urna pharetra, sollicitudin enim nec, rhoncus nunc."] 
                    },
                    {
                        "Month" : "Nisan",
                        "Titles" : ["Aenean gravida tortor vel eros consectetur placerat.", "Nulla accumsan felis pharetra augue laoreet commodo" ] 
                    },
                ]
                },
                {
                    "year" : "2023",
                    "months" : [
                        {
                            "Month" : "Eylül",
                            "Titles" : ["Lorem ipsum dolor sit amet, consectetur adipiscing elit.","Cras aliquam nisl ac lorem varius accumsan." ] 
                        },
                        {
                            "Month" : "Ekim",
                            "Titles" : ["Duis vitae urna pharetra, sollicitudin enim nec, rhoncus nunc."] 
                        },
                        {
                            "Month" : "Aralık",
                            "Titles" : ["Aenean gravida tortor vel eros consectetur placerat.", "Nulla accumsan felis pharetra augue laoreet commodo" ] 
                        },
                    ]
                }
            ]
        }
      }});
  
    return result;

  };