import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { formItemLayout, getUser, hasAdminRole } from '../../../helpers/root';
import history from '../../../history';
import { useDispatch, useSelector } from 'react-redux';
import store, { Store } from '../../../state';
import { getMainMenu, setMenuCalled } from '../../../state/reducers/MenuReducer';
import { Button, Divider, Form, Modal, Radio } from 'antd';
import { GetResourceData } from '../../../helpers/basemethods';
import Survey from '../../../components/Survey';

const MainMenu = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const { mainMenuContext, menuCalled } = useSelector((s: Store) => s.menu);
    const { currentUser } = useSelector((s: Store) => s.auth);
    const { sysLang, isSurveyAnswered } = useSelector((s: Store) => s.common);
    const [activeSurvey, setActiveSurvey] = useState<any>(null)  

    useEffect(() => {
        if(!menuCalled && (!mainMenuContext || sysLang!=mainMenuContext.lang)){   
            dispatch(setMenuCalled(true));
            dispatch(getMainMenu());
        }      
    },[]);

    const formItemLayout = { labelCol: { span: 4 }, wrapperCol: { span: 14 } };

    const closeSurvey = () => {
        setActiveSurvey(false)
    }

    return (
        <React.Fragment>
            <div className={`sidebar-menu`}>
                <div className='menu'>
                    <div className='menu-items'>
                        {mainMenuContext?.data?.map((item, i)  => (
                            
                            item.url ?
                                <a key={item.id} href={item.url} ><div className='menu-item-icon'><i className={item.icon ? item.icon : "fas fa-dot-circle"} /></div><div className='menu-item-name'>{item.name}</div></a>
                                :
                                <a key={item.id} onClick={() => { history.push(`/Content?categoryId=${item.firstContentCategoryId}`); onClose() }}><div className='menu-item-icon'><i className={item.icon ? item.icon : "fas fa-dot-circle"} /></div><div className='menu-item-name'>{item.name}</div></a>
                        ))}
                        {<a onClick={() => { history.push(`/corporate-assets`) }}><div className='menu-item-icon'><i className="fal fa-file-alt"></i></div><div className='menu-item-name'>{GetResourceData("CorporateDocuments")}</div></a>}
                        {<a onClick={() => { history.push(`/corporate-demands`) }}><div className='menu-item-icon'><i className="fal fa-business-time"></i></div><div className='menu-item-name'>{GetResourceData("CorporateDemands")}</div></a>}
                        {hasAdminRole() ? <a href={window.location.origin + ":5555/alfa"}><div className='menu-item-icon'><i className="fas fa-user-cog" /></div><div className='menu-item-name'>{GetResourceData("Admin")}</div></a> : "" }

                        <Divider/>
                        {
                            <div className='surveyMenuContainer'>
                                <a className='surveyMenu' onClick={() => { setActiveSurvey(true)}}><div className='menu-item-icon'><i className="fal fa-poll newMenuItem" /></div>
                                {/* <i className="fa fa-light fa-comment info"></i> */}
                                { !isSurveyAnswered &&  <i className="fa fa-exclamation info"></i>}
                                {GetResourceData("RemoteWorkSurvey")}
                                </a>
                            </div>                            
                        }
                        {<a onClick={() => { history.push(`/task-tracking`) }}><div className='menu-item-icon'><i className="fal fa-tasks"></i></div><div className='menu-item-name'>{GetResourceData("TaskTracking")}</div></a>}

                        </div>
                </div>
            </div>
            <Survey activeSurvey={activeSurvey} closeSurvey={closeSurvey} isNew={true} />            
            
        </React.Fragment>
    )
}

export default MainMenu;