import React, { useEffect, useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { callApi } from '../helpers/service';
import { methods } from '../helpers/enums';
import { AxiosResponse } from 'axios';

export interface VoteContentProps {
    contentId: string | null;
}

const VoteContent: React.FC<VoteContentProps> = (props) => {

    const { contentId } = props;
    const [Value, setValue] = useState(0)
    const [Disabled, setDisabled] = useState(true)
    const [Show, setShow] = useState(false)
    useEffect(() => {
        getData();
    }, [contentId])

    const getData = () => {
        // callApi({
        //     url: `/Content/GetContentPointByContentId/${contentId}`, useToken: true,
        //     method: methods.GET,
        //     successCallback: (response: AxiosResponse) => {
        //         if (response.data.isSuccessful) {
        //             const result = response.data.result;
        //             setValue(result.point)
        //             setDisabled(true)
        //         } else {
        //             setDisabled(false)
        //             setValue(0)
        //         }
        //         setShow(true)
        //     },
        //     errorCallback: (err: any) => {
        //         setDisabled(false)
        //         setValue(0)
        //         setShow(true)
        //     }
        // });
    }
    const vote = (point) => {
        setValue(point)
        setDisabled(true)
        // callApi({ url: `/Content/CreateContentPoint`, useToken: true, method: methods.POST, data: { contentId: contentId, point: point } });
    }
    return (
        <React.Fragment>
            {Show &&
                <div className="vote-content">
                    <p><InfoCircleFilled /> Bu İçeriği Ne Kadar Faydalı Buldun?</p>
                    <ul className={(Disabled ? "disabled" : "")}>
                        <li className={(Value === 1 ? 'active' : '')} onClick={() => { vote(1) }}>1</li>
                        <li className={(Value === 2 ? 'active' : '')} onClick={() => { vote(2) }}>2</li>
                        <li className={(Value === 3 ? 'active' : '')} onClick={() => { vote(3) }}>3</li>
                        <li className={(Value === 4 ? 'active' : '')} onClick={() => { vote(4) }}>4</li>
                        <li className={(Value === 5 ? 'active' : '')} onClick={() => { vote(5) }}>5</li>
                    </ul>
                </div>
            }
        </React.Fragment>

    );
}

export default VoteContent;