import React, { useEffect, useState, useRef } from 'react';
import { LaptopOutlined , EnvironmentOutlined, RightOutlined, CloseOutlined, MenuOutlined, UserOutlined, ArrowLeftOutlined, DownOutlined, SmileOutlined, CaretDownOutlined, SearchOutlined, BellOutlined } from '@ant-design/icons';
import IntranetLogo from '../../../assets/images/Logo.svg';
import { Avatar, Button, Dropdown, List, Menu, MenuProps, Modal, Image, Space, Switch, Tooltip } from 'antd';
import history from '../../../history';
import { getMenuItem } from '../../../helpers/menu';
import { NotifierHeaderInfoType, NotifierResultType } from '../../../helpers/enums';
import { isMobile } from "react-device-detect";
import MainMenu from './MainMenu';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../state';
import { setCurrentUser } from '../../../state/reducers/AuthReducer';
import {  GetResourceData, SetSystemLanguage, GetSystemLanguageDesc, GetLanguages, GetOperationResourceData } from '../../../helpers/basemethods';
import { formatPhoneNumber,  getResource,  showNoti } from '../../../helpers/root';
import OutsideClickDetector from '../../../components/OutsideClickDetector';
import { setOfficeListCalling , setMyTasks, setMyTasksCalling, setPopularSearchCalling, setPopularSearch, setShowPublicSearch, setSysLang, setOfficeList } from '../../../state/reducers/CommonReducer';
import QRGenerator from '../../../pages/Public/VCard';
import ViewQrCode from '../../../components/ViewQrCode';
import { getRandomDepartment, getRandomEmail, getRandomImage, getRandomName,  getRandomTitle, getRandomUsername } from '../../../helpers/samples';
import { MoodList } from '../../../helpers/constantData';
import { Spinner } from '../../../components';
import { GetHeaderMenuItems, UpdateLocation, UpdateMood, getLanguageResource } from '../../../business/Public/HeaderBusiness';
import { getPopularSearch, setDarkMode } from '../../../state/reducers/CommonReducer';
import { sysIsDarkMode } from '../../../helpers/storagekeys';
import { GetSearchResults, testApiCall } from '../../../business/Public/CommonBusiness';
import { setBanners } from '../../../state/reducers/HomePageReducer';
import { callApi } from '../../../helpers/service';
import { AxiosResponse } from 'axios';
import { ApiTestResults } from '../../../helpers/testApiResponses';

const uuidv1 = require("uuid/v1");


const HeaderComponent =  ({ }) => {


    const dispatch = useDispatch()

    const { currentUser } = useSelector((s: Store) => s.auth);
    const { officeList, officeListCalling, popularSearchCalling, myTasksCalling, popularSearch, showPublicSearch } = useSelector((s: Store) => s.common);
    const { myTasks } = useSelector((s: Store) => s.common);
    const pp = useRef<any>()
    const [searchInput, setSearchInput] = useState('');
    const [searchResult, setSearchResult] = useState<any[]>([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [IsSearchOpen, setIsSearchOpen] = useState(false)
    const [MegaMenu, setMegaMenu] = useState<any>(null)
    const [ShowUserMenuSection, setShowUserMenuSection] = useState(false)
    const [ShowOutlookSignature, setShowOutlookSignature] = useState<boolean>(false)
    const [Searching, setSearching] = useState<boolean>(false)
    const [SearchCount, setSearchCount] = useState<number>(0)
    const { isDarkMode } = useSelector((s: Store) => s.common);
    const [ThemeChanging, SetThemeChanging] = useState<boolean>(false)
    const [isOfficeSelectionOpen, SetIsOfficeSelectionOpen] = useState<boolean>(false)

    const moodList = MoodList;
    useEffect(() => {
        if (searchInput.length === 0) {
            setSearchCount(0)
        }
        const timerId = setTimeout(() => { search(searchInput); }, 300);
        return () => { clearTimeout(timerId) }
    }, [searchInput]);

    dispatch(setShowPublicSearch(true));
    //Sık arananlar dolduruluyor
    useEffect(() => { if (!popularSearch) { 
        getPopularSearch() 
    } }, [popularSearch]);
    useEffect(() => { if (!myTasks) { 
            getMyTasks()     
    } }, [myTasks]);
    useEffect(() => { if (!officeList) { 
        getOfficeList()     
    } }, [officeList]);

    const getOfficeList = async () => {
        if (!officeListCalling) {
            dispatch(setOfficeListCalling(true));
            let result = await testApiCall("officeList")
            dispatch(setOfficeList(result));      
            dispatch(setOfficeListCalling(false));      
        }        
    }

    const getPopularSearch = async () => {
        if (!popularSearchCalling) {
            dispatch(setPopularSearchCalling(true));
            let result = await testApiCall("popularSearch")
            dispatch(setPopularSearch(result));      
            dispatch(setPopularSearchCalling(false));      
        }        
    }

    const getMyTasks = async () => {
        if (!myTasksCalling) {
            dispatch(setMyTasksCalling(true));
            let result = await testApiCall("notifications")
            dispatch(setMyTasks(result));      
            dispatch(setMyTasksCalling(false));      
        }        
    }

    //Gece modu - gündüz modu değişimi. Animasyonu kesmemek için 185 ms'lik bir delay ile state güncelliyoruz.
    function  handleThemeChange (checked, event) {
        SetThemeChanging(true);
        setTimeout(() => {
            localStorage.setItem(sysIsDarkMode, checked ? "1" : "0");
            dispatch(setDarkMode(checked));            
            SetThemeChanging(false);
        }, 185);
      };

    //Sistem Dillerini img'leri setliyoruz.
    const systemLanguages = GetLanguages();
    const menuLanguages = systemLanguages.map(item => ({
        key : item.key,
        label : item.label,
        icon: <img width="32px" src={getLanguageResource(item.key)} alt={item.label} />
    }));

    //Sistem dili değiştirildiğinde güncelleyip anasayfaya döndürüyoruz.
    const languageSelectonClick: any = ({ key }) => {
        SetSystemLanguage(key);
        dispatch(setSysLang(key));
        dispatch(setBanners(null))
        // window.location.href = './' 
    };

    //Mood değiştiren servis
    const changeMood = (e) => {
        let apiCall = UpdateMood(e.key);
        if (apiCall.isSuccessful) {      
            dispatch(setCurrentUser(apiCall.result)); 
        }
    }

    //Mood değiştiren servis
    const changeOffice = (e) => {
        SetIsOfficeSelectionOpen(false);
        let apiCall = UpdateLocation(e.key, e.label);
        if (apiCall.isSuccessful) {      
            dispatch(setCurrentUser(apiCall.result)); 
        }
    }

    //Arama kısmını servis mimarisine göre tekrar mapleyelim.
    const search = async (key) => {
        if (searchInput.length > 2) {
            setSearching(true)
            let _constResults = ApiTestResults("quickSearch");
            let _searchResults = await GetSearchResults(key) //GetSearchResults
            let _finalResults = [..._constResults , ..._searchResults];
            setSearchResult(_finalResults);
            setSearching(false)
            setSearchCount(SearchCount + 1)  
        }
    }

    

    let image = getRandomImage()

    let _headerMenuItems = GetHeaderMenuItems();
    const headerMenuItems: MenuProps['items']=_headerMenuItems.map(item => ({
        label: (<span><i className={item.mainIconClass}></i>{item.content}</span>),
        key: item.key,
        children : item.children.map(child => ({
            label: (
                <a href={child.link} rel="noopener noreferrer">
                  <i className={child.childIconClass}></i> {child.content}
                </a>
              ),
              key: child.key       
        }))
    }));
    


    //Search Click eventleri 
    const logSearchKey = (key: any) => {
        //LogApi({ action :  "Search", actionName : key, logType : 3  });
        history.push(`${getMenuItem("SearchResult").path}?key=${key}`)
    }
    const logSearchUserProfile = (searcKey: any, item: any) => {
        //LogApi({ action :  "Search", actionName : key, logType : 3  });
        history.push(getMenuItem("ProfileDetail").path.replace(":id", item.user.userName));
    }
    const getContentSearchResult = (searcKey: any, item: any) => {
        //LogApi({ action :  "Search", actionName : key, logType : 3  });
        history.push(`/Content?id=${item.content.id}`);
    }
    const getFileSearchResult = (searcKey: any, item: any) => {
        //LogApi({ action :  "Search", actionName : key, logType : 3  });
        window.open("/file?id=" + item.document.id, '_blank')
    }
    const getClickedLink = (item) => {
        //LogApi({ action :  "Url", actionName : item.url, logType : 2 ,logTimeOut: 60 , itemId: item.id });
    }

    //imza kopyalama metodu
    const copyToClipboard = () => {
        const imza = document.getElementById("imza");
        if (imza != null) {
            const range = document.createRange();
            range.selectNode(imza);
            if (window != null && window.getSelection() != null) {
                window!.getSelection()!.removeAllRanges();
                window!.getSelection()!.addRange(range);
                try {
                    document.execCommand("copy");
                    showNoti(NotifierResultType.success, GetOperationResourceData(NotifierHeaderInfoType.notifierInfo), GetOperationResourceData("CopyToCliboardSuccess"))
                } catch (err) {
                    showNoti(NotifierResultType.error, GetOperationResourceData(NotifierHeaderInfoType.notifierInfo), GetOperationResourceData("CopyToCliboardFail"))
                }
                window!.getSelection()!.removeAllRanges();
            }
        }
    }

    const focusSearchInput = () => {
        setTimeout(() => {
            var denemeElement = document.getElementById('searchInput');
            if (denemeElement) {
                denemeElement.focus();
            }
        }, 100);
    }
   
    return (
        <header>

            <div className='header-top'>
            <div className='menu-btn' onClick={() => { setIsMenuOpen(true) }}>
                <div className='icon'><MenuOutlined /></div>
                </div>
                    <div className='header-bar'>
                            <div className='logo'><a onClick={() => { history.push(`/`) }}><img src={IntranetLogo} alt="Intranet" /></a></div>
                            <OutsideClickDetector 
                            className={`search-container ${IsSearchOpen ? 'show' : ''}`} 
                            onClickOutside={() => { setIsSearchOpen(false) }}>
                                {showPublicSearch && <div className='icon' onClick={() => { if (!isMobile) { if (IsSearchOpen) { setSearchInput(""); } setIsSearchOpen(!IsSearchOpen); focusSearchInput(); } else { history.push(getMenuItem("SearchResult").path); } }}><SearchOutlined /><span className={`search-info-text ${IsSearchOpen ? 'hide' : ''}`}>{GetResourceData("SearchContainerPlaceHolder")}</span></div>}
                                <div className='search-area'>
                                    <input autoFocus type='text' value={searchInput} id='searchInput'
                                        placeholder={GetResourceData("SearchContainerPlaceHolder")}
                                        onChange={(e) => { setSearchInput(e.target.value) }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") { if (searchInput && searchInput.length > 2) { logSearchKey(searchInput); setSearchInput(""); setIsSearchOpen(false); } }
                                        }}
                                    ></input>

                                    <div className='search-results'>
                                        {(popularSearch) && <div className='short-searchs'>
                                            <h3>{GetResourceData("FrequentlySarched")}</h3>
                                            <ul>{popularSearch.map((item, i) => (<li key={i} onClick={() => { logSearchKey(item) }}>{item}</li>))}</ul>
                                        </div>}

                                        {
                                            Searching &&
                                            <div className='loading'>
                                                <Spinner />
                                                <span>{GetResourceData("Searching")}</span>
                                            </div>
                                        }

                                        <div className='list'>
                                            {
                                                (searchResult.filter(x => x.user).length === 0 && searchResult.filter(x => x.content).length === 0 && searchResult.filter(x => x.document).length === 0 && SearchCount > 0) &&
                                                <p>
                                                    {GetOperationResourceData("QuickSearchNotFound")}
                                                </p>
                                            }
                                            {
                                                searchResult.filter(x => x.content).length > 0 &&
                                                <div className='result-group'>
                                                    <div className='result-group-header'>
                                                        <h5>{GetResourceData("Content")}</h5>
                                                        {
                                                            searchResult.filter(x => x.content).length > 3 &&
                                                            <a href='#' onClick={() => { logSearchKey(searchInput); setSearchInput(""); setIsSearchOpen(false); }}>{GetResourceData("ShowAll")}</a>
                                                        }
                                                    </div>
                                                    <ul>
                                                        {
                                                            searchResult.filter(x => x.content).slice(0, 3).map((item, i) => (
                                                                <li key={i} onClick={() => { getContentSearchResult(searchInput, item); setIsSearchOpen(false) }}>
                                                                    <span className='title'>{item.content.title}</span>
                                                                    <span className='description' dangerouslySetInnerHTML={{ __html: `${item.content.description.substr(0, 150)}...` }}></span>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            {
                                                searchResult.filter(x => x.user).length > 0 &&
                                                <div className='result-group'>
                                                    <div className='result-group-header'>
                                                        <h5>{GetResourceData("Person")}</h5>
                                                        {
                                                            searchResult.filter(x => x.user).length > 3 &&
                                                            <a href='#' onClick={() => { logSearchKey(searchInput); setSearchInput(""); setIsSearchOpen(false); }}>{GetResourceData("ShowAll")}</a>
                                                        }
                                                    </div>
                                                    <ul>
                                                        {
                                                            searchResult.filter(x => x.user).slice(0, 3).map((item, i) => (
                                                                <li className='user' key={i} onClick={() => { logSearchUserProfile(searchInput, item); setSearchInput(""); setIsSearchOpen(false); }} ><Avatar src={image} />{item.user.fullName}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            {
                                                searchResult.filter(x => x.document).length > 0 &&
                                                <div className='result-group'>
                                                    <div className='result-group-header'>
                                                        <h5>{GetResourceData("Document")}</h5>
                                                        {
                                                            searchResult.filter(x => x.document).length > 3 &&
                                                            <a href='#' onClick={() => { logSearchKey(searchInput); setSearchInput(""); setIsSearchOpen(false); }}>{GetResourceData("ShowAll")}</a>
                                                        }
                                                    </div>
                                                    <ul>
                                                        {

                                                            searchResult.filter(x => x.document).slice(0, 3).map((item, i) => (
                                                                <li key={i} onClick={() => { setIsSearchOpen(false); getFileSearchResult(searchInput, item) }}>
                                                                    <span className='title'>{item.document.fileTitle}</span>
                                                                    <span className='description' dangerouslySetInnerHTML={{ __html: `${item.document.fileName.substr(0, 150)}...` }}></span>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            }

                                            {

                                                searchResult.filter(x => x.link).length > 0 &&
                                                <div className='result-group'>
                                                    <div className='result-group-header'>
                                                        <h5>Link</h5>
                                                        {
                                                            searchResult.filter(x => x.link).length > 3 &&
                                                            <a href='#' onClick={() => { logSearchKey(searchInput); setSearchInput(""); setIsSearchOpen(false); }}>{GetResourceData("ShowAll")}</a>
                                                        }
                                                    </div>
                                                    <ul>
                                                        {

                                                            searchResult.filter(x => x.link).slice(0, 3).map((item, i) => (
                                                                <li key={i} onClick={() => { setIsSearchOpen(false); }}>
                                                                    <a key={item.link.id} href={item.link.url} target={'_blank'}>

                                                                        <i className={item.link.icon ? item.link.icon : "fas fa-dot-circle"} /> {item.link.name}</a>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>


                            </OutsideClickDetector>
    
                            <div className='user-profile' style={{display:"flex", gap:"10px", alignItems:"center"}} >
                            
                                
                                <div className='langSelector'>
                                    <Dropdown menu={{  items:menuLanguages, onClick:languageSelectonClick }}>
                                        <a>
                                        <Space style={{color:"#39b3f5"}}>
                                            <img width="32px" src={getLanguageResource(null)}/> 
                                            <span className='langSelectorText'>{GetSystemLanguageDesc()}</span> 
                                            <DownOutlined color='#39b3f5' />
                                        </Space>
                                        </a>
                                    </Dropdown>
                                </div>
                                
                                <div className='themeSelector'>
                                    
                                <Switch 
                                    unCheckedChildren={<i style={{color:"yellow"}} className="fas fa-sun"></i>}
                                    checkedChildren={<i style={{color:"blueviolet"}} className="fas fa-moon-stars"></i>}
                                    defaultChecked ={isDarkMode}
                                    onChange={ handleThemeChange }
                                                             />
                                </div>
                                {/* <div>
                                {
                                    <>                           
                                        <Dropdown menu={{ items: officeList, className: "office-list" }}
                                            trigger={['click']}
                                            arrow={true}
                                            dropdownRender={() => {
                                                return (
                                                <div className='office-list'>
                                                    {officeList.map((item, i) => (
                                                        <div onClick={() => { changeOffice(item) }} className={"officeSelection" + (item.key === currentUser?.currentLocationId ? " selectedOffice" : "") } >
                                                            <div className={'item'} key={i} >
                                                            <Space>
                                                                <i className={item.icon} aria-hidden="true"/>
                                                                {item.label}

                                                                </Space> 
                                                            </div>
                                                            <div className='officeImage'>
                                                                <Image preview={false} width={64} src={item.src.default}/>                                                                                        
                                                            </div>
                                                        </div>
                                                        
                                                    ))}
                                                </div>)
                                            }}
                                        >
                                            <a className='officeSelector' onClick={(e) => e.preventDefault()}>
                                               <span className='officeSelectorText'>{currentUser.currentLocation}</span> 
                                                <EnvironmentOutlined className='officeSelectorIcon' />
                                            </a>
                                        </Dropdown>   
                                    </>
                                }
                            </div> */}
                                <div>
                                {
                                    <>                           
                                        <Dropdown trigger={['click']} dropdownRender={() => (
                                            <div className='my-tasks-dropdown'>
                                                <List>
                                                    {(myTasks && myTasks.totalCount > 0) ? myTasks.jobs.filter(x => x.count > 0).map((item, i) => (
                                                        <List.Item key={i} onClick={() => { window.open(item.url, "_self") }}>
                                                            <List.Item.Meta title={<React.Fragment>{item.title}<span>{item.count}</span></React.Fragment>} description={item.description}></List.Item.Meta>
                                                        </List.Item>
                                                    )) : <List.Item>{GetResourceData("NoPendingJob")}</List.Item>}
                                                </List>
                                            </div>
                                        )}>   
                                        
                                        <div style={{cursor:"pointer"}}>
                                            
                                                <BellOutlined/>
                                                {myTasks && myTasks.totalCount > 0 && 
                                                <>                                                                       
                                                    <span className='count'>{myTasks ? myTasks.totalCount : 0}</span>
                                                </>}
                                            
                                           
                                        </div>                                 
                                        </Dropdown>
                                    </>
                                }
                                </div>
                                
                                <div className="welcome-screen">
                                    {GetResourceData("Hello")}, <span style={{fontWeight:"bold"}}>{currentUser?.name}</span>
                                </div>
                                
                                <div className='avatar-container'>
                                    <Dropdown trigger={['click']} onOpenChange={() => { if (officeList) {
                                        setShowUserMenuSection(false)
                                        }}}
                                        dropdownRender={() => (
                                            <div className='user-profile-dropdown'>
                                                {
                                                    !ShowUserMenuSection ?
                                                        <div className='item-list'>
                                                            <div className='item'>
                                                                <div className='icon green moodIcon'><SmileOutlined /></div>
                                                                <div className='text'>

                                                                    <Dropdown menu={{ items: moodList, className: "mood-list" }}
                                                                        trigger={['click']}
                                                                        arrow={true}
                                                                        dropdownRender={() => {
                                                                            return (<div className='mood-list'>
                                                                                {moodList.map((item, i) => (
                                                                                    <div className='item' key={i} onClick={() => { changeMood(item) }}>
                                                                                        <img src={item.image} />
                                                                                    </div>
                                                                                ))}
                                                                            </div>)
                                                                        }}
                                                                    >
                                                                        <a onClick={(e) => e.preventDefault()}>
                                                                            {GetResourceData("MoodSelection")}
                                                                            <img src={moodList.find(x => x?.key === currentUser?.mood)?.image} /><CaretDownOutlined />
                                                                        </a>
                                                                    </Dropdown>

                                                                </div>
                                                            </div>
                                                            <div className='item officeTab'>
                                                                <div className='icon blue officeIcon'><i className="fa fa-suitcase" aria-hidden="true"></i></div>
                                                                <div className='officeTabRightPane'>
                                                                <div className='text'>                                                                
                                                                    <Dropdown menu={{ items: officeList, className: "office-list" }}
                                                                        trigger={['click']}
                                                                        arrow={true}
                                                                        open={isOfficeSelectionOpen}
                                                                        dropdownRender={() => {                                                                                                                                                       
                                                                            return (
                                                                                <OutsideClickDetector onClickOutside={() => { SetIsOfficeSelectionOpen(false) }}>
                                                                                <div className='office-list'>
                                                                                    {officeList.map((item, i) => (
                                                                                        <div onClick={() => { changeOffice(item) }} className={"officeSelection" + (item.key === currentUser?.currentLocationId ? " selectedOffice" : "") } >
                                                                                            <div className={'item'} key={i} >
                                                                                            <Space>
                                                                                                <i className={item.icon} aria-hidden="true"/>
                                                                                                {item.label}

                                                                                                </Space> 
                                                                                            </div>
                                                                                            <div className='officeImage'>
                                                                                                <Image preview={false} width={64} src={item.src.default}/>                                                                                        
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    ))}
                                                                                </div>
                                                                                </OutsideClickDetector>
                                                                            )

                                                                        }}
                                                                    >
                                                                        <a style={{height:"100% !important"}} onClick={(e) => {e.preventDefault(); SetIsOfficeSelectionOpen(true);} }>
                                                                            {GetResourceData("OfficeSelection")}
                                                                            <CaretDownOutlined />
                                                                        </a>
                                                                    </Dropdown>                                                                        
                                                                </div>  
                                                                
                                                                <div className='officePreviewImg'>
                                                                    <Image  width={48} src={officeList?.find(x => x?.key === currentUser?.currentLocationId)?.src.default}/>
                                                                </div>
                                                                
                                                                </div>

                                                            </div>
                                                            <div className='item with-qr'>
                                                                <div className='menu-detail' onClick={() => { setShowUserMenuSection(true) }}>
                                                                    <div className='icon red'><UserOutlined /></div>
                                                                    <div className='text'>{GetResourceData("MyProfile")}</div>
                                                                </div>
                                                                <ViewQrCode user={currentUser} />
                                                            </div>
                                                            {/* <div className='item'>
                                                                <div className='icon yellow'><SettingOutlined /></div>
                                                                <div className='text'>Şifre İşlemleri</div>
                                                            </div> */}
                                                            {/* <div className='item' onClick={() => dispatch(logout())}>
                                                                <div className='icon gray'><LogoutOutlined /></div>
                                                                <div className='text'>Çıkış</div>
                                                            </div> */}
                                                        </div> :
                                                        <div className='profile-content'>
                                                            <div className='action' onClick={() => { setShowUserMenuSection(false) }}>
                                                                <ArrowLeftOutlined />
                                                            </div>
                                                            <h2>{GetResourceData("MyProfile")}</h2>
                                                            <hr />
                                                            <h3>Volkan Seçginli</h3>
                                                            <a onClick={() => { setShowOutlookSignature(true) }}>{GetResourceData("Signature")}</a>
                                                            <hr />
                                                            <h3>{GetResourceData("Registry")}: {getRandomUsername()}</h3>
                                                            <h3>{GetResourceData("Department")}: {getRandomDepartment()}</h3>
                                                            <h3>{GetResourceData("Manager")}: {getRandomName()}</h3>
                                                            <hr />
                                                            <a onClick={() => { history.push("/my-profile") }}>{GetResourceData("GoToMyProfile")}</a>

                                                        </div>
                                                }
                                            </div>
                                        )}
                                    >
                                        <div className='avatar'>
                                            {
                                                currentUser?.profilePhoto ?
                                                    <img ref={pp} src={require('../../../assets/images/volkan.secginli.jpg')} alt={getRandomName()}
                                                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                            const el = e.target as HTMLImageElement
                                                            el.onerror = null;
                                                            el.src = "https://www.w3schools.com/howto/img_avatar.png"
                                                        }}
                                                    />
                                                    :
                                                    <img src="https://www.w3schools.com/howto/img_avatar.png" alt={getRandomName()} />
                                            }
                                            {/* <span className='mood'>
                                                <img src={moodList.find(x => x?.key === currentUser?.mood)?.image} />
                                            </span> */}
                                            {
                                                officeList &&                                               
                                                <span className='officeType'>
                                                    <i className={officeList.find(x => x?.key === currentUser?.currentLocationId)?.icon} aria-hidden="true"/>
                                                </span>
                                                
                                            }
                                            
                                        </div>
                                    </Dropdown>
                                </div>
                            </div>

                            {/* </Row>
                            </Col> 

                        </Row> */}
                    </div>
                
            </div>
            <div className='header-bottom'>
                { headerMenuItems && 
                <Menu  mode="horizontal" items={headerMenuItems} />
                }
                <OutsideClickDetector onClickOutside={() => { setMegaMenu(null) }}>
                    <div className={`mega-menu ${MegaMenu ? 'show' : ''}`}>
                        <div className='mega-menu-content'>
                            <div className='mega-menu-header'>
                                <h2>{MegaMenu?.categoryName}</h2>

                                <div className='close-btn' onClick={() => { setMegaMenu(null) }}>{GetResourceData("CloseUPPER")} <CloseOutlined /></div>
                            </div>
                            <ul>
                                <li key="0">...</li>
                                {MegaMenu?.links.length > 0 && MegaMenu.links.map((sitem, s) => (<li key={s}><a onClick={() => { getClickedLink(sitem) }} href={sitem.url} target={sitem.openNewTab ? '_blank' : '_parent'}>{sitem.name} <RightOutlined /></a></li>))}
                            </ul>
                        </div>
                    </div>
                </OutsideClickDetector>
            </div>
            <MainMenu open={isMenuOpen} onClose={() => { setIsMenuOpen(false) }} />
            <Modal footer={false} title={"Outlook İmzam"} width={""} className="imza-modal" centered open={ShowOutlookSignature} onCancel={() => { setShowOutlookSignature(false) }}>
                <span id="imza" style={{ fontFamily: "Trebuchet MS !important", fontSize: "13px" }}>

                    <table style={{ lineHeight: "1.4", fontFamily: "Segoe UI", fontSize: "10pt" }}>
                        <tbody>
                            <div style={{ display: "flex", justifyContent: "center", width: "max-content" }}>
                                <div style={{ flex: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ padding: "10px" }}>
                                        <div>
                                            <span id='kisi' style={{ fontWeight: "bold", fontFamily: "Segoe UI", fontSize: "10pt" }}>
                                                {"Volkan Seçginli"}
                                            </span>
                                            <br />
                                            {getRandomTitle()}
                                            <br />
                                            {getRandomDepartment()}
                                        </div>
                                        <div style={{ verticalAlign: "middle", marginTop: 20 }}>
                                            <span style={{ fontWeight: "bold", fontFamily: "Segoe UI", fontSize: "10pt" }}>VNS Teknoloji</span>
                                            <br />
                                            <span style={{ fontWeight: "bold", fontFamily: "Segoe UI", fontSize: "10pt" }}>T: {formatPhoneNumber("+90 532 210 12 10")}</span>
                                            <br />
                                            <span style={{ fontWeight: "bold", fontFamily: "Segoe UI", fontSize: "10pt" }}>M: {formatPhoneNumber("+90 532 210 12 10")}</span>
                                        </div>
                                    </div>
                                </div>

                                {!isMobile && <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 20 }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <QRGenerator fullName={"Volkan Seçginli"} telephoneNumber={formatPhoneNumber("+90 532 210 12 10")} mobilPhoneNumber={formatPhoneNumber("+90 532 210 12 10")} email={getRandomEmail()} title={getRandomTitle()} />
                                    </div>
                                </div>}

                            </div>
                        </tbody>
                    </table>
                </span>

                <Button htmlType='button' onClick={copyToClipboard}>{GetResourceData("CopySignature")}</Button>

            </Modal>
        </header>
    )
}

export default React.memo(HeaderComponent)