import React from "react";
// import { HeaderComponent } from "../..components";
import Footer from "./components/Footer";
import { HeaderComponent } from "../../components";
export interface PublicLayoutProps { 
 }

const PublicLayout: React.FC<PublicLayoutProps> = ({ children  }) => {
  return (
    <React.Fragment>
      <HeaderComponent />
      <div className="headerComp">
        {children}
      </div>
      
      <Footer />
    </React.Fragment>
  );
};

export default PublicLayout;
