import { notification } from "antd";
import { AxiosResponse } from "axios";
import moment, { Moment } from 'moment';
import store from "../state";
import { ServiceResult } from "../type/ServiceResult";
import { User } from "../type/User";
import { Languages, ResourceData, ValidLanguages } from "../helpers/resources";
import { NotifierHeaderInfoType, NotifierResultType } from "../helpers/enums";

export const numberRegex = /\d/g;
export const logoPath =  require("../assets/images/Logo.svg");
export const logoAdminPath = require("../assets/images/Logo.svg");
export const logoSmall = "/logo-small.svg";
export const companyLogo = "/companyLogo.svg"
export const shortDate = 'DD.MM.YYYY';
export const fullDate = 'DD.MM.YYYY HH:mm:ss';
export const hourAndMin = 'HH:mm';
export const dateFormatForService = 'YYYY-MM-DD';
export const fullDateFormatForService = 'YYYY-MM-DDTHH:mm:ssZ';
export const fullDateFormatStart = dateFormatForService + ' 00:00:00';
export const fullDateFormatEnd = dateFormatForService + ' 23:59:59';
export const gutterSize = 16;
export const dashboardSize = 350;

export const roles = {
  RUN_AS_DIFFERENT_USER: "RunAsDifferentUser",
  HR: "HR",
  ADMIN: "Admin",
  TEST_ROLE: "TestRole",
  GRADE_INCREASE_HR: "GradeIncreaseHR"
}

export const samaUserValidator = (_, value) => {

  const user = getUser();

  if (!value || user!.id !== value) {

    return Promise.resolve();
  }
  return Promise.reject(new Error('Kendinizi seçemezsiniz!'));

}


export const extractNumberFromString = (str: string) => {
  const numbers = str.match(numberRegex);
  return parseInt(numbers?.join("")!);
}

export const isDate = (dateStr: string) => {
  return !isNaN(new Date(dateStr).getDate());
}


export const shortDateFormat = (date: any) => {
  return moment(date).format(shortDate);
}

export const fullDateFormat = (date: any) => {
  return moment(date).format(fullDate);
}
export const hourFormat = (date) => {
  return moment(date).format(hourAndMin);
}

export const hourRangeFormat = (startDate: string, endDate: string) => {
  return `${shortDateFormat(startDate)}  ${hourFormat(startDate)} - ${hourFormat(endDate)}`
}

export const inViewport = (els: any) => {
  let matches: any = [],
    elCt = els.length;

  for (let i = 0; i < elCt; ++i) {
    let el = els[i],
      b = el.getBoundingClientRect(), c;

    if (b.width > 0 && b.height > 0 &&
      b.left + b.width > 0 && b.right - b.width < window.outerWidth &&
      b.top + b.height > 0 && b.bottom - b.width < window.outerHeight &&
      (c = window.getComputedStyle(el)) &&
      c.getPropertyValue('visibility') === 'visible' &&
      c.getPropertyValue('opacity') !== 'none') {
      matches.push(el);
    }
  }
  return matches;
}

export const anyMatchInArray = (target, toMatch) => {
  var found, targetMap, i, j, cur;

  found = false;
  targetMap = {};

  // Put all values in the `target` array into a map, where
  //  the keys are the values from the array
  for (i = 0, j = target.length; i < j; i++) {
    cur = target[i];
    targetMap[cur] = true;
  }

  // Loop over all items in the `toMatch` array and see if any of
  //  their values are in the map from before
  for (i = 0, j = toMatch.length; !found && (i < j); i++) {
    cur = toMatch[i];
    found = !!targetMap[cur];
    // If found, `targetMap[cur]` will return true, otherwise it
    //  will return `undefined`...that's what the `!!` is for
  }

  return found;
};






export const recursiveFind = (array, key, value, childsPropName) => {
  var o;

  array.some(function iter(a) {
    if (a[key].includes(value)) {
      o = a;
      return true;
    }
    return Array.isArray(a[childsPropName]) && a[childsPropName].some(iter);
  });
  return o;
}


export const showNoti = (type: NotifierResultType, title: string, description: string, duration?: number) => {
  notification[type]({
    message: title,
    description,
    duration: duration || 4.5
  });
};

export const isLocalOrTest = () => {
  const returnVal = process.env.NODE_ENV === "development" || process.env.REACT_APP_ENVIRONMENT === 'test';
  return returnVal;
}


export const isTest = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'test';
}

export const isDev = () => {
  const returnVal = process.env.NODE_ENV === "development";
  return returnVal;
}

export const isProd = () => {
  const returnVal = process.env.REACT_APP_ENVIRONMENT === "production";
  return returnVal;
}

export const isDevIdentity = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'dev_identity' || process.env.REACT_APP_ENVIRONMENT === 'dev_identity_local';
}

export const isTestIdentity = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'test_identity';
}

export const isPreprod = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'preprod';
}

export const isUsingIdentity = () => {

  // let val = isProd() || isDevIdentity() || isTestIdentity() || isPreprod();
  return true
}

export const hasNoIdentity = () => {
  return isDev() || isTest()
}


export const getProfileImageResource = (userId: string) => {
  try {
    return `/users/${userId}.jpg`;
  } catch (error) {
    return `/users/default.jpg`;
  }
}


export const getToken = () => {
  const token = localStorage.getItem("access_token");
  if (isUsingIdentity()) {
    return token;
  }
}

export const getResource = (url: string) => {
  // const hasFirstParam = url.includes("?");
  // const token = null;
  // const delegatedUser = getDelegatedUser();
  // const urlNew = `${getApiBaseUrl()}${url}${hasFirstParam ? `&` : `?`}access_token=${token}&${delegatedUser && `DelegateUserName=${delegatedUser.userName}`}`;
  const urlNew = "http://85.190.254.174:3333/api/Test/file?id="+url;
  return urlNew
}


export const getBannerResource = (id: string) => {
  // const token = null;
  // const delegatedUser = getDelegatedUser();
  // const urlNew = `${getApiBaseUrl()}api/Banner/GetBannerImage?id=${id}&access_token=${token}&${delegatedUser && `DelegateUserName=${delegatedUser.userName}`}`;

  // return urlNew
  return require("../assets/images/samples/" + id + ".png").default
}

export const getUrlParamValue = (param) => {
  const search = window.location.search;
  var searchParams = new URLSearchParams(search);
  return searchParams.get(param)
}

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}


export const dateToWcf = (d) => {
  if (isNaN(d)) return null;
  return '/Date(' + d.getTime() + '-0000)/';
}

export const getDateForService = (d) => {
  return dateToWcf(new Date(d));
}

export const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 8
    },
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 16
    },
  },
};


export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export const getRootSystemLanguage = () => {
  return store.getState().common.sysLang;
}

export const getCurrentUser = () => {
  return store.getState().auth.currentUser;
}

export const getUser = () => {
  return store.getState().auth.currentUser;
}

export const getDelegatedUser = () => {
  return store.getState().auth.delegatedUser;
}

export const isAdmin = () => {
  const currentUser: User | null = getCurrentUser();

  if (currentUser) {
    return currentUser.roles && currentUser.roles.filter(f => f === "Admin").length > 0;
  } else {
    return false
  }
}

export const isHR = () => {
  const currentUser: User | null = getUser();

  if (currentUser) {
    return currentUser.roles && currentUser.roles.filter(f => f === "HR").length > 0;
  } else {
    return false
  }
}

export const isManager = () => {
  const currentUser: User | null = getUser();

  if (currentUser) {
    return currentUser.isManager;
  } else {
    return false
  }
}

export const getMainRole = () => {
  if (isManager()) {
    return "Manager"
  } else if (isHR()) {
    return "HR"
  } else {
    return "Employee"
  }
}

export const hasAdminRole = (): boolean =>  isAdmin() || isHR();

// export const isMasterAdmin = () => {
//   const currentUser: IKraxResult<ServiceResultModel<User>>= getState().currentUser;

//   if(currentUser && currentUser.payload && currentUser.payload.data){
//       return currentUser.payload.data.roles.filter(f => f === "MasterAdmin").length > 0;
//   }else{
//       return false
//   }
// } 


export const getPublicUrl = () => {
  return process.env.REACT_APP_PUBLIC_URL
};

export const getApiBaseUrl = () => {
  return process.env.REACT_APP_API_URL
};

export const getcCommonUserApiBaseUrl = () => {
  return process.env.REACT_APP_COMMON_USER_API_URL
};

export const getSourceUrl = (suffix: any) => {
  return `${process.env.REACT_APP_API_BASE_URL}${suffix}`;
};


export const tokenIsExpired = (token: any) => {
  if (isLocalApi()) {
    return true;
  }
  const jwt: any = parseJwt(token);
  const current_time: any = new Date().getTime() / 1000;
  return current_time > jwt.exp;
};

export const parseJwt = (token: any) => {
  const base64Url: any = token.split('.')[1];
  const base64: any = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const apiStatus = (statusCode: number) => {
  return statusCode >= 200 && statusCode < 300
};

export const turkishLiraFormatter = (value: any) => `TL ${value}`.replace(/^\d*\.?\d*$/g, ',')

export const isLocalApi = () => window.location.hostname === 'localhost' && process.env.REACT_APP_USE_LOCAL_API === '1'

export const formatNumber = (value) => {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {

    if(phoneNumber.includes("("))
    {
      return phoneNumber;
    }
    
    phoneNumber = phoneNumber.replace(/\s/g, "");
    if (phoneNumber.startsWith("5")) {
      phoneNumber = "+90" + phoneNumber;
    }
    else if (phoneNumber.startsWith("0")) {
      phoneNumber = "+9" + phoneNumber;
    }
    else if (phoneNumber.startsWith("9")) {
      phoneNumber = "+" + phoneNumber;
    }

    return phoneNumber.slice(0, 3) + " (" + phoneNumber.slice(3, 6) + ") " + phoneNumber.slice(6, 9) + " " + phoneNumber.slice(9);
  }
  return phoneNumber;
}

export const setErrorCallback = (errMsg: string | null, errObj: any) => {
  if (errObj.response) {
    const res: AxiosResponse<ServiceResult<any>> = errObj.response;
    showNoti(NotifierResultType.error, "Hatalı", res.data.error.message);
  } else {
    showNoti(NotifierResultType.error, "Hatalı", errMsg || "İşlem hatalı!");
  }
}

// export const getFileListWithLink = (fileList: FileResult[]) => {
//   const certificateLinksArray: IdName[] = [];

//   for (const file of fileList) {
//     const link = getResource("file?id=" + file.id);
//     certificateLinksArray.push({ id: link, name: file.fileName });
//   }

//   return certificateLinksArray;
// }


export const disabledDateAfterToday = (current) => {
  if (current > moment()) {
    return true;
  }

  return false;
}

export const disabledDateDiffYear = (current: Moment, diffYear: number) => {

  // const x = current.add('year', diffYear) ;

  const yearDiffCase = moment().diff(current, 'years') >= diffYear;
  if (disabledDateAfterToday(current) || yearDiffCase) {
    return true;
  }

  return false;
}

export const getBrowserInfo = () => {
  var sBrowser, sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
  } else {
    sBrowser = "unknown";
  }
  return sBrowser;
}

export const getMonths = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];

export const multiLangHandler = (e,_valueList,) => {
  let _value = e.target.value;
  let _valLang = e.target.name.substring(0,2);
  let _titleValues = _valueList.map(item => ((
      {
          lang : item.lang,
          value : item.lang == _valLang ? _value : item.value
      }
  )));
  return _titleValues;
}