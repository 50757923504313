import React from "react";
// import { Header } from '../components';
import { Layout } from "antd";
import { isMobile } from "react-device-detect";
import AdminMenu from "./components/AdminMenu";
import Logo from "../../components/Logo";
import { HeaderAdmin } from "../../components";
// import { EnvBanner, FooterCustom, HeaderAdmin, Logo } from "../../components";
// import AdminMenu from "./components/AdminMenu";

const { Footer, Sider } = Layout;

export interface PublicLayoutProps {}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  return (
    <>
      {/* <EnvBanner /> */}
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          // onBreakpoint={(broken) => {
          // }}
          // onCollapse={(collapsed, type) => {
          // }}
          width={isMobile ? "100%" : 300}
        >
          <div className="menu-container">
            <Logo isAdmin />
            <AdminMenu />
          </div>  

          <div className="menu-info-box"></div>
        </Sider>
        <Layout>
          {/* <HeaderAdmin /> */}
          {children}
          {/* <FooterCustom/>           */}
        </Layout>
      </Layout>
    </>
  );
};

export default PublicLayout;
