import { Button, Card, Col, Popconfirm, Row, Table, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import { GetResourceData } from "../helpers/basemethods";
import { VacationRequestType } from "../helpers/constantData";
import { DeleteOutlined } from '@ant-design/icons';
import { isMobile } from "react-device-detect";

export interface RequestTableProps {
    _isStateOpen:boolean
    _myDemandContent:any
    _requestDetail:any
    _cancelRequest?:any
} 
const RequestTable: React.FC<RequestTableProps> = (props) => {
    
    const myDemandContent = props._myDemandContent;

    return ( 
    <React.Fragment>
        {
            isMobile && myDemandContent &&
            <>
            {
                myDemandContent.data?.filter(x=> x.isActive == props._isStateOpen ).map((element,i) => {
                    return(
                        <Card className="boardIdeaCard">
                            <Row>
                                                                        
                                {/* <Col xs={24} sm={24}>
                                <strong>{GetResourceData("RequestNo")}</strong> 
                                </Col>                                           
                                <Col xs={24} sm={24}>
                                {"Req-" + element.id}
                                </Col>                                      */}
                                <Col xs={24} sm={24}>
                                <strong>{GetResourceData("RequestType")}</strong> 
                                </Col>                                            
                                <Col xs={24} sm={24}>
                                {element.typeDesc}
                                </Col>    
                                <Col xs={24} sm={24}>
                                <strong>{GetResourceData("RequestDate")}</strong> 
                                </Col>                                            
                                <Col xs={24} sm={24}>
                                {element.date}
                                </Col>                                    
                                <Col xs={24} sm={24}>
                                <strong>{GetResourceData("Description")}</strong> 
                                </Col>                                            
                                <Col xs={24} sm={24}>
                                {element.desc}
                                </Col>                                     
                                <Col xs={24} sm={24}>
                                <strong>{GetResourceData("State")}</strong>  
                                </Col>                                                                              
                                <Col xs={24} sm={24}>
                                {element.state + (element.activeApproveManager ? " (" + element.activeApproveManager + ")" : "")}
                                </Col>
                                <Col xs={24} sm={24}>
                                <div style={{display:"flex", justifyContent:"flex-start", columnGap:"10px"}}>
                                    <Button disabled={element.type === VacationRequestType} onClick={() => { props._requestDetail(element.id); }} type='primary'>{GetResourceData("Detail")}</Button>
                                    {
                                        props._isStateOpen &&
                                        <Tooltip title={GetResourceData("CancelRequest")}>
                                            <Popconfirm
                                                title={GetResourceData("CancelRequestQuestion")}
                                                onConfirm={() => props._cancelRequest(element.id)}
                                                okText={GetResourceData("Yes")}                                            
                                                cancelText={GetResourceData("No")}
                                                disabled={element.type === VacationRequestType}
                                            >
                                                <Button icon={<DeleteOutlined />} type='primary' danger disabled={(element.type) === VacationRequestType}/>
                                            </Popconfirm>

                                        </Tooltip>
                                    }
                                </div>                                                   
                                </Col> 
                            </Row>
                        </Card>
                    
                )
                })
            }
            </>
        }
        {
            !isMobile &&
            <>
            <Table pagination={{pageSize:5}} dataSource={myDemandContent?.data?.filter(x=> x.isActive == props._isStateOpen )}>
                {/* <Column title={GetResourceData("RequestNo")} dataIndex="id" key="requestNo" render={(val) => { return("Req-" + val)}} /> */}
                <Column title={GetResourceData("RequestType")} dataIndex="typeDesc" key="typeDesc" />
                <Column title={GetResourceData("RequestDate")} dataIndex="date" key="date" />
                <Column title={GetResourceData("Description")} dataIndex="desc" key="desc" />
                <Column title={GetResourceData("State")} key="state" render={(item) => {return( item.state + (item.activeApproveManager ? " (" + item.activeApproveManager + ")" : ""));}} />
                <Column title={GetResourceData("Detail")} key="detail" render={(item) => {
                    return (
                        <div style={{display:"flex", justifyContent:"flex-start", columnGap:"10px"}}>
                            <Button disabled={item.type === VacationRequestType} onClick={() => { props._requestDetail(item.id); }} type='primary'>{GetResourceData("Detail")}</Button>
                            {
                                props._isStateOpen &&
                                <Tooltip title={GetResourceData("CancelRequest")}>
                                    <Popconfirm
                                        title={GetResourceData("CancelRequestQuestion")}
                                        onConfirm={() => props._cancelRequest(item.id)}
                                        okText={GetResourceData("Yes")}                                            
                                        cancelText={GetResourceData("No")}
                                        disabled={item.type === VacationRequestType}
                                    >
                                        <Button icon={<DeleteOutlined />} type='primary' danger disabled={(item.type) === VacationRequestType}/>
                                    </Popconfirm>

                                </Tooltip>
                            }
                        </div>
                    );}} />
            </Table>
            </>
        }
    </React.Fragment>
    )
}
 
export default RequestTable;