import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Tooltip } from 'antd';
import { PageBase } from '../../../../components';
import { getMenuItem } from '../../../../helpers/menu';
import history from '../../../../history';
import { callApi } from '../../../../helpers/service';
import { AxiosResponse } from 'axios';
import { ServiceResult } from '../../../../type/ServiceResult';
import { methods } from '../../../../helpers/enums';
import { trueFalseRender } from '../../../../helpers/columns';
import { EditOutlined, EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Content, ContentWithHeader } from '../../../../type/ContentManagement/Content';
import { getUrlParamValue } from '../../../../helpers/root';

export interface ContentListProps { }

const ContentList: React.FC<ContentListProps> = () => {
    const categoryId = getUrlParamValue("id");

    const [loading, setLoading] = useState<boolean>(false);
    const [Data, setData] = useState<any[]>([]);
    const [PageTitle, setPageTitle] = useState<string>(getMenuItem("ContentList").text);

    const cols: any = [
        { title: "Sıra", key: "sequence", width: 30, render: (item: ContentWithHeader) => { return item.content.sequence; } },
        { title: "Başlık", key: "name", render: (item: ContentWithHeader) => { return item.content.title; } },
        { title: 'Aktif', key: 'isActive', width: 100, render: (item: ContentWithHeader) => trueFalseRender(item.content.isActive, "Aktif", "Pasif") },
        { title: 'Ortalama Oy Puanı', key: 'averagePoint', width: 150, render: (item: ContentWithHeader) => {return item.content.averagePoint! > 0 ?`${item.content.averagePoint} (${item.content.votersCount} Kişi)` : 0 }},
        {
            title: '', key: 'operation', fixed: 'right', width: 100,
            render: (item: ContentWithHeader) => {
                return (
                    <div className='d-flex gap-5'>
                        <Tooltip title="Görüntüle">
                            <a className='ant-btn ant-btn-circle ant-btn-primary ant-btn-icon-only' target='_blank' href={`/Content?id=${item.content.id}`}><EyeOutlined /></a>
                        </Tooltip>
                        <Tooltip title="S.S.S">
                            <Button onClick={() => history.push(getMenuItem("FAQList").path + "?id=" + item.content.id)} type="primary" shape="circle" icon={<QuestionCircleOutlined />} />
                        </Tooltip>
                        <Tooltip title="Düzenle">
                            <Button onClick={() => history.push(getMenuItem("ContentEdit").path + "?id=" + item.content.id)} type="primary" shape="circle" icon={<EditOutlined />} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (categoryId) {
            getData();
        } else {
            getIndependentData()
        }
    }, []);

    const getData = () => {
        setLoading(true)
        callApi({
            url: `/admin/Content/GetContentCategoryById/${categoryId}`, useToken: true, method: methods.GET,
            successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
                const result = res.data.result;
                setPageTitle(`${getMenuItem("ContentList").text} (${result.contentCategory.name})`)
            },
        });
        callApi({
            url: `/admin/Content/GetContentsByCategoryId/${categoryId}`, useToken: true,
            method: methods.GET,
            successCallback: (res: AxiosResponse<ServiceResult<Content[]>>) => {
                setLoading(false)
                if (res.data.isSuccessful) {
                    const result = res.data.result;
                    setData(result)
                } else {
                    setData([])
                }
            },
        });
    }
    const getIndependentData = () => {
        setPageTitle("Bağımsız İçerik Listesi")
        setLoading(true)
        callApi({
            url: `/admin/Content/GetindependentContents`, useToken: true,
            method: methods.POST,
            data: { "pageSize": 99999, },
            successCallback: (res: AxiosResponse) => {
                setLoading(false)
                if (res.data.isSuccessful) {
                    const result = res.data.result.items;
                    setData(result)
                } else {
                    setData([])
                }
            },
        });
    }
    return (
        <PageBase showBreadcrump showPageHeader title={PageTitle} menuName={PageTitle}  >
            <Form layout="inline" className="components-table-demo-control-bar mb-15 flex-end" >
                <Form.Item>
                    {
                        categoryId ?
                            <Button type="dashed" onClick={() => history.push(`${getMenuItem("ContentNew").path}?categoryId=${categoryId}`)}>Yeni İçerik Ekle </Button> :
                            <Button type="dashed" onClick={() => history.push(`${getMenuItem("ContentNew").path}`)}>Yeni Bağımsız İçerik Ekle </Button>
                    }
                </Form.Item>
            </Form>
            <Table dataSource={Data} columns={cols} loading={false} />
        </PageBase>
    );
}

export default ContentList;