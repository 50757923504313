import React, { useEffect, useRef } from "react"
import { getRandomImage } from "../helpers/samples";
import { isMobile } from "react-device-detect";
import { getMenuItem } from "../helpers/menu";
import { formatPhoneNumber } from "../helpers/root";
// import ViewQrCode from "./ViewQrCode";
import { useHistory } from "react-router-dom";
import  history  from "../../src/history";
import { MailOutlined, PhoneOutlined, GiftOutlined, EnvironmentOutlined } from '@ant-design/icons';
import ViewQrCode from "./ViewQrCode";
import { GetResourceData } from "../helpers/basemethods";
import {  useSelector } from "react-redux";
import { Store } from "../state";


export interface UserDetailProps {
    user : any
}

const UserDetail: React.FC<UserDetailProps> = (props) => {
    const { officeList,officeListCalling } = useSelector((s: Store) => s.common);
    const pp = useRef<any>();
    const his = useHistory();
    const user = props.user;
    
    return (
        <React.Fragment>
            
            <>{
                user && officeList &&
                <><div key="userDetailKey" className={`item`}>
                <div className="top">
                    <div className="avatar">
                        <img ref={pp} src={getRandomImage(user.userName)} alt={user.fullName}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                const el = e.target as HTMLImageElement
                                el.onerror = null;
                                el.src = "https://www.w3schools.com/howto/img_avatar.png"
                            }}
                        />

                    </div>
                    <div className="content">
                        <div className="row-item first">
                            <div className="detail big">{user.fullName}</div>
                            <div className="detail"><strong>{GetResourceData("Registry")} No:</strong> {user.userName}</div>
                        </div>
                        <div className="row-item">
                            <div className="detail"><strong>{GetResourceData("OrgTitle")}:</strong> {user.unvan}</div>
                            {/* <div className="detail"><strong>Takım:</strong> {item.user.team}</div> */}
                            <div className="detail"><strong>{GetResourceData("Department")}:</strong> {user.department}</div>
                        </div>
                        <div className="row-item">
                            {/* <div className="detail"><strong>Direktörlük:</strong> {item.user.director}</div> */}
                            <div className="detail"><strong>{GetResourceData("HireDate")}:</strong> {user.hireDate}</div>
                            <div className="detail"><strong>{GetResourceData("Manager")}:</strong> <a onClick={() => {
                                if (user.managerUserName) {
                                    history.push(getMenuItem("ProfileDetail").path.replace(":id", user.managerUserName))
                                }
                            }}>{user.managerFullName}</a></div>
                        </div>
                        {
                            !isMobile &&
                            <ViewQrCode  user={user} />
                        }
                    </div>
                </div>
                <div className="bottom">

                    <div className="row-item">
                        <div className="detail"><strong><PhoneOutlined /> {GetResourceData("GSM")}:</strong> {formatPhoneNumber(user.phoneNumber)}</div>
                        <div className="detail"><strong><PhoneOutlined /> {GetResourceData("ShortCode")}:</strong> {user.mobileShortCode}</div>                        </div>
                    <div className="row-item">
                        <div className="detail"><strong><GiftOutlined /> {GetResourceData("BirthDate")}:</strong> {user.birthDay}</div>
                        <div className="detail"><strong><MailOutlined /> {GetResourceData("Email")}:</strong> <a href={`mailto:${user.email}`}>{user.email}</a></div>
                    </div>
                    <div className="row-item">
                        <div className="detail"><strong><EnvironmentOutlined /> {GetResourceData("WorkOffice")}:</strong> {user.currentLocation}</div>
                    </div>

                </div>

            </div>
                </>
            }
                
            </>
        </React.Fragment>
    )
}

export default UserDetail;