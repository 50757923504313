import React from 'react';
import Menu, { MenuProps } from 'antd/lib/menu';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getMenuItem, menu } from '../../../helpers/menu';
import { anyMatchInArray } from '../../../helpers/root';
import { Store } from '../../../state';


const AdminMenu: React.FC = () => {

    const { selectedMenu } = useSelector((s: Store) => s.common);
    const { currentUser } = useSelector((s: Store) => s.auth);

    const createMenuItems = (item: any = null, hasChild: any = null) => {
      const userRoles = [...currentUser!.roles, "User"];
      //Render items with childs
      if (item !== null && hasChild === true) {
        const subMenu = (
  
          //Render Parents
          <SubMenu
            key={item && item!.val!}
            icon={item.icon && <i className={`icon fal fa-${item.icon}`} /> }
            title={
                <span>
                  {item!.text!}
                </span>
            }
          >
  
  
            {menu.filter(f => f.parent === item.name).map(child => {
              if (child.show) {
                const childMenuRoles = child.roles;
                const childMatchRole = anyMatchInArray(userRoles, childMenuRoles);
                if (childMatchRole) {
                  return  (
                    <Menu.Item key={child.val} icon={child.icon && <i className={`icon fal fa-${child.icon}`} />}>
                      <Link to={child.path}>
                        {child.text}
                      </Link> 
                    </Menu.Item>
                  )
                } else {
                  return null;
                }
              } else {
                return null
              }
            })
            }
  
          </SubMenu>
        );

          
        return subMenu;
      }

      let menuItems: any = [];

    for (let i = 0; i < (menu || []).length; i++) {
      const item = menu[i];
      let element;

      if (item && item.show === true) {
        if (item.hasChild) {
          element = createMenuItems(item, true);
        } else if (!item.parent) {

          //Menu items without childs
          element = (
            <Menu.Item key={item.val} icon={item.icon && <i className={`icon fal fa-${item.icon}`} />}>
              <Link to={item.path}>
                {item.text}
              </Link>
            </Menu.Item>
          );
        }

        const menuRoles = item.roles || [];
        const matchRole = anyMatchInArray(userRoles, menuRoles);

        if (matchRole) {
          menuItems.push(element);
        }

      }

    }
    return menuItems;
  }

  let menuProps: MenuProps = {
    theme:"light",
    mode:"inline",
    defaultSelectedKeys:[`${getMenuItem("AdminDashboard").val}`]
  }

  if (selectedMenu) {
    menuProps.selectedKeys = [selectedMenu.toString()];
    menuProps.defaultOpenKeys = [`${selectedMenu.toString().substring(0,4)}`];
  }

    return ( 
        <Menu
         {...menuProps}
      >
        {createMenuItems()}
       
      </Menu>
     );
}
 
export default AdminMenu;