import { Button, Form, FormInstance, Input, Modal, Radio, Row } from "antd"
import { showNoti } from "../helpers/root";
import { NotifierHeaderInfoType, NotifierResultType } from "../helpers/enums";
import { GetOperationResourceData, GetResourceData } from "../helpers/basemethods";
import React from "react";
import { EmptySurveyExample , AnsweredSurveyExample, DemoSurveyExampleEmpty, DemoSurveyExampleAnswered } from "../helpers/constantData";
import { SubmitButton } from "./SubmitButton";
import Col from "antd/es/grid/col";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../state";
import { setIsSurveyAnswered } from "../state/reducers/CommonReducer";



export interface SurveyProps {
    activeSurvey : boolean,
    closeSurvey : any,
    isNew : boolean
}
const Survey: React.FC<SurveyProps> = (props) => {
    const dispatch = useDispatch()
    const {  isSurveyAnswered } = useSelector((s: Store) => s.common);

    const [form] = Form.useForm();
    // const SurveyItem = props?.isNew ? EmptySurveyExample : AnsweredSurveyExample;
    const isAnswered = props?.isNew ? isSurveyAnswered ? true : false : true;
    const SurveyItem = isAnswered ? DemoSurveyExampleAnswered :  DemoSurveyExampleEmpty;

    const sendSurveyAnswers = () => {
        showNoti(NotifierResultType.success, GetOperationResourceData(NotifierHeaderInfoType.notifierSuccess), GetOperationResourceData("SurveySend"))
        dispatch(setIsSurveyAnswered(true))
    }

    const closeSurvey = () => {
        form.resetFields();
        props.closeSurvey();
    }


    return (
        <React.Fragment>
            <Modal title={SurveyItem.title} className="survey-modal" open={props.activeSurvey} width={720} onCancel={() => { closeSurvey() }} >
                <Form            
                    wrapperCol={{ span: 14 }}
                    layout="vertical"
                    style={{ width: "100%" }}
                    className='surveyForm'       
                    form={form}  
                    onFinish={sendSurveyAnswers}           
                >    
                          
                {
                    SurveyItem && 
                    SurveyItem.questions.map((question) => (
                        <>
                            <img src={question.img}/>
                            <Form.Item className={isAnswered ? "disabledQuestion" : ""} name={question.key} label={question.questionTitle} rules={[{ required: question.isRequired }]}>
                                <Radio.Group defaultValue={question.questionAnswer}>
                                    {
                                        question.questionOptions.map((option,i) => ((
                                            <Radio 
                                                disabled={isAnswered}
                                                value={option.value}
                                            > 
                                                {option.text} 
                                            </Radio>
                                        )))
                                    }
                                </Radio.Group>  
                                                            
                            </Form.Item>
                            {
                                    isAnswered &&
                                    <div style={{height:"10px"}}>


                                    </div>
                                }
                                {
                                    isAnswered &&
                                    question.questionOptions.map((answer) => (
                                        <Row>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                                                <div style={{paddingLeft:"5px", paddingRight:"5px", boxShadow:" 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 5px 3px rgba(0, 0, 0, 0.19)", marginTop:"5px", border:"1px solid black", borderRadius:"10px", display:"flex", justifyContent:"space-between", background: "linear-gradient(45deg, "+(answer.isWinner?"#89bee9 ":"#ccc8c2")+" "+answer.rate+",  #fff "+answer.rate+")"}}>
                                                <span style={{fontWeight:"bold"}}>{answer.text}</span> <span style={{fontWeight:"bold"}}>{answer.rate}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        ))
                                }     
                        </>                        
                        ))
                }           
                {
                    !isAnswered &&
                    <Form.Item shouldUpdate>
                        <SubmitButton form={form}>{GetResourceData("SendUpper")}</SubmitButton>
                    </Form.Item>
                }   
                
            
                </Form>
            </Modal>    
        </React.Fragment>
        

    )
}

export default Survey;
            