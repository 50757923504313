import React, { Suspense, lazy } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import PublicLayout from '../layouts/PublicLayout';
// import { getMenuItem } from '../helpers/menu';
import { Skeleton } from 'antd';
// import AdminRoutes from './AdminRoutes';
import Intro from '../components/IntroLoader';
import { getMenuItem } from '../helpers/menu';
import CorporateDemands from '../pages/Public/CorporateDemands';
import ProfileDetail from '../pages/Public/ProfileDetail';
import AdminRoutes from './AdminRoutes';
import TaskTracking from '../pages/Public/TaskTracking';
const NewsFromUs = lazy(() => import('../pages/Public/NewsFromUs'));
const MyProfile = lazy(() => import('../pages/Public/MyProfile'));
const Home = lazy(() => import('../pages/Public/Home'));
const SearchResult = lazy(() => import('../pages/Public/SearchResult'));
const CorporateAssets = lazy(() => import('../pages/Public/CorporateAssets'));
const Content = lazy(() => import('../pages/Public/Content'));
const Ideas = lazy(() => import('../pages/Public/Ideas'));

export interface RoutesProps {
}

const Routes: React.FC<RoutesProps> = ({}) => {
    const PublicRoute = ({ children, ...rest }: RouteProps) => {
        return (
            
            <Route {...rest}> 
                <PublicLayout >
                    {children}                    
                </PublicLayout>
            </Route>
        );
    }

    const [isInitialSetupDone, setIsInitialSetupDone] = React.useState(false);


    if (!isInitialSetupDone) {
        return <Intro onChange={(val: any) => {
            setTimeout(() => {
                setIsInitialSetupDone(val)
              }, 200);

           
        } } />
    }

    return (<>

        <Suspense fallback={<Skeleton active />}>
            <AdminRoutes />
            <PublicRoute path="/" exact >
                <Home />
            </PublicRoute> 
            <PublicRoute path={`${getMenuItem("SearchResult").path}`} exact >
                <SearchResult />
            </PublicRoute>   
            <PublicRoute path={`${getMenuItem("CorporateAssets").path}`} exact >
                <CorporateAssets />
            </PublicRoute>     
            <PublicRoute path={`${getMenuItem("CorporateDemands").path}`} exact >
                <CorporateDemands />
            </PublicRoute>
            <PublicRoute path={`${getMenuItem("Content").path}`} exact >
                <Content />
            </PublicRoute>   
            <PublicRoute path={`${getMenuItem("ProfileDetail").path}`} exact >
                <ProfileDetail  />
            </PublicRoute>      
            <PublicRoute path={`${getMenuItem("NewsFromUs").path}`} exact >
                <NewsFromUs  />
            </PublicRoute>     
            <PublicRoute path={`${getMenuItem("MyProfile").path}`} exact >
                <MyProfile  />
            </PublicRoute>       
            <PublicRoute path={`${getMenuItem("Ideas").path}`} exact >
                <Ideas  />
            </PublicRoute>      
            <PublicRoute path={`${getMenuItem("TaskTracking").path}`} exact >
                <TaskTracking  />
            </PublicRoute>
        </Suspense>

    </>);
}

export default Routes;