import { DefaultPortalLanguage, IdeaTypesAll } from "./constantData";
import { ErrorMessages } from "./customValidateMessages";
import { OperationResourceData } from "./operationResources";
import { Languages, ResourceData, ValidLanguages } from "./resources";
import { getRandomImage } from "./samples";
import { sysLanguageKey , usrLanguageKey } from "./storagekeys";

export var SystemLanguage:string = "";
export var UserLanguage:string = "";

  export const setStyleProps = () => {

  }

  export const GetLanguages = () => {
    return Languages;
  }  
  
  export const GetSystemLanguage = () => {
    if (SystemLanguage!="") {
      return SystemLanguage;
    }
    var systemLang = localStorage.getItem(sysLanguageKey);
    if(!systemLang) {
      systemLang = DefaultPortalLanguage;
      SetSystemLanguage (systemLang);
    }    
    SystemLanguage = systemLang;
    return systemLang;
  }

  export const GetUserLanguage = () => {
    if (UserLanguage!="") {
      return UserLanguage;
    }
    var userLang = localStorage.getItem(usrLanguageKey);
    if(!userLang) {
      userLang = DefaultPortalLanguage;
      SetUserLanguage (userLang);
    }    
    SystemLanguage = userLang;
    return userLang;
  }
  
  export const GetSystemLanguageDesc = () => {
    var sysLang = GetSystemLanguage();
    return GetLanguages().find(language => language.key === sysLang)?.label;
  }
  
  export const SetSystemLanguage = (lang) => {
    SystemLanguage = lang;
    var langChoice = DefaultPortalLanguage;
    if(ValidLanguages.includes(lang)){
      langChoice = lang;
    }
    localStorage.setItem(sysLanguageKey, langChoice);
  }
  
  export const SetUserLanguage = (lang) => {
    UserLanguage = lang;
    var langChoice = DefaultPortalLanguage;
    if(ValidLanguages.includes(lang)){
      langChoice = lang;
    }
    localStorage.setItem(usrLanguageKey, langChoice);
  }

  export const GetCustomValidateMessage = (key,isAdmin?) => {
    var systemLanguage = isAdmin ? GetUserLanguage() : GetSystemLanguage();
    var searchKey = "val"+systemLanguage;
    var searchWord = ErrorMessages[key];
    if(searchWord){
      return searchWord[searchKey];
    }
  }
  
  export const GetResourceData = (key,isAdmin?) => {
    var systemLanguage = isAdmin ? GetUserLanguage() : GetSystemLanguage();
    var searchKey = "val"+systemLanguage;
    var searchWord = ResourceData[key];
    if(searchWord){
      return searchWord[searchKey];
    }
  }
  export const GetAdminResourceData = (key) => {
    return GetResourceData(key,true);
  }
  
  export const GetResourceDataForSpecificLanguage = (lang,key) => {
    var searchKey = "val"+lang;
    var searchWord = ResourceData[key];
    if(searchWord){
      return searchWord[searchKey];
    }
  }

  export const GetOperationResourceData = (key) => {
    var systemLanguage = GetSystemLanguage();
    var searchKey = "val"+systemLanguage;
    var searchWord = OperationResourceData[key];
    if(searchWord){
      return searchWord[searchKey];
    }
  
  }

  export const GetTodayWithCertainFormat = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const timezoneOffset = today.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneSign = timezoneOffset >= 0 ? '-' : '+';
    const timezoneString = `${timezoneSign}${String(timezoneOffsetHours).padStart(2, '0')}:${String(timezoneOffsetMinutes).padStart(2, '0')}`;
  
    const formattedDate = `${year}-${month}-${day}T00:00:00${timezoneString}`;
    return formattedDate;
  
  }

  
  export const GetTodayWithCertainFormatAddDays = (addDays) => {
    const today = new Date();
    today.setDate(today.getDate() + addDays); // 2 gün ekleyerek ileri tarihi alıyoruz
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const timezoneOffset = today.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneSign = timezoneOffset >= 0 ? '-' : '+';
    const timezoneString = `${timezoneSign}${String(timezoneOffsetHours).padStart(2, '0')}:${String(timezoneOffsetMinutes).padStart(2, '0')}`;
  
    const formattedDate = `${year}-${month}-${day}T00:00:00${timezoneString}`;
    return formattedDate;
  
  }


  export const GetTodayWithShortFormat = (addDays,timeInfo = null) => {
    const today = new Date();
    today.setDate(today.getDate() + addDays); // 2 gün ekleyerek ileri tarihi alıyoruz
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = timeInfo ? `${year}-${month}-${day}T`+timeInfo : `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  
  }
  
  export const GetIconPathDarkMode = (name,isDark,ext,isDefault = false) => {
    let path = isDark ? "darktheme/" : "";
    return isDefault ?require("../assets/images/"+path+"icons/"+name+"."+ext).default : require("../assets/images/"+path+"icons/"+name+"."+ext);
  }

  
  export const GetIconPath = (name,ext,isDefault = false) => {
    return isDefault ? require("../assets/images/icons/"+name+"."+ext).default : require("../assets/images/icons/"+name+"."+ext);
  }

  //TODO : bu akış kurgulanacak
  export const getUserAvatar = (username) => {
    return getRandomImage(username)
  }

  
export const GetTooltipTitle = (id) => {
  return GetResourceData(IdeaTypesAll.find(x=> x.id == id).value);
}