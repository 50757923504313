import React, { useState } from "react";
import _ from 'lodash';
import { fullDateFormat, shortDateFormat } from "./root";

import { Button, Tag } from "antd";
import history from "../history";
const textLimit = 80;


const RenderTextLimit = ( {text, limit} ) => {

  const [ showLimit, setshowLimit ] = useState(limit !== null);

  if(!showLimit){
    return <>{text}</>;
  }else{
    return <>
      {text && text.substr(0, limit)} ... <br/><br/>
      <Button type="link" onClick={() => setshowLimit(false)}>Devamı</Button>
    </>
  }
}


export const detailButton = (path, params) => {
  return {
    key: "",
    dataIndex: "",
    title: "",
    isExcel: false,
    render: (data, record) => {
      
      let parsedParams = "";
      for (var prop in params) {
        parsedParams += `${prop}=${_.get(record, params[prop])}&`;
      }

      return (
        <Button
          type="dashed"
          onClick={() => {
            history.push(`${path}?${parsedParams}`);
          }}
        >
          Detay 
          {/* <Icon type="right" /> */}
        </Button>
      );
    }
  };
};

export const dateRender = (text, isFull) => {
  if(text){
    return isFull ?  fullDateFormat(text) : shortDateFormat(text);
  }else{
    return null;
  }
  
};

export const trueFalseRender = (value, trueText, falseText) => {
  if (value === true) {
    return <Tag color={"green"}>{trueText}</Tag>;
  } else {
    return <Tag color={"red"}>{falseText}</Tag>;
  }
};

