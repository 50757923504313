import React, { useEffect } from "react";
import { getCurrentUser, getDelegatedUserFromSession } from "../state/reducers/AuthReducer";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../state";
import { useDidUpdate } from "../hooks/common";
import { logoPath } from "../helpers/root";

interface IProps {
  onChange?: Function
}

const Intro = (props: IProps) => {

  const { onChange } = props;
  const dispatch = useDispatch();
  const { currentUser } = useSelector((s: Store) => s.auth);

  useEffect(() => {
    // if (!currentUser) {
      dispatch(getCurrentUser());
    // }
    dispatch(getDelegatedUserFromSession())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useDidUpdate(() => {
    const isOk = (currentUser && Object.keys(currentUser).length > 0);
    onChange && onChange(isOk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);





  return (
    <div className="intro">
      <div className="logo">
        {/* <img src={logoPath} alt="VNS Teknoloji" style={{ width: "250px" }} /> */}
        <span style={{fontSize:"85px", color:"white", fontWeight:"bold"}} >VNS <span style={{fontWeight:"lighter"}}>Intranet</span></span>
      </div>
    </div>
  );

}

export default Intro;
