import moment from "moment";
import { GetResourceData, GetSystemLanguage } from "./basemethods";
import { sysLanguageKey } from "./storagekeys";

export const DefaultPortalLanguage = "Tr";

export const IdeaTypesAll = [
  { id: "1", value:"InnovativeIdea", image: require("../assets/images/idea-types/idea1.png").default },
  { id: "2", value:"Technology", image: require("../assets/images/idea-types/idea2.png").default},
  { id: "3", value:"SocialResponsibility", image: require("../assets/images/idea-types/idea3.png").default},
  { id: "4", value:"EntertainmentAndHobby", image: require("../assets/images/idea-types/idea4.png").default},
  { id: "5", value:"Education", image: require("../assets/images/idea-types/idea5.png").default},
  { id: "6", value:"MakingLifeEasier", image: require("../assets/images/idea-types/idea6.png").default},
  { id: "7", value:"BudgetSaving", image: require("../assets/images/idea-types/idea7.png").default},
]

export const BirtyDayTemplates = [
    { id: 1, image: require("../assets/images/birthday-templates/1.png").default },
    { id: 2, image: require("../assets/images/birthday-templates/2.png").default },
    { id: 3, image: require("../assets/images/birthday-templates/3.png").default },
    { id: 4, image: require("../assets/images/birthday-templates/4.png").default },
    { id: 5, image: require("../assets/images/birthday-templates/5.png").default },
    { id: 6, image: require("../assets/images/birthday-templates/6.png").default },
    { id: 7, image: require("../assets/images/birthday-templates/7.png").default },
    { id: 8, image: require("../assets/images/birthday-templates/8.png").default },
    { id: 9, image: require("../assets/images/birthday-templates/9.png").default },
    { id: 10, image: require("../assets/images/birthday-templates/10.png").default },
    { id: 11, image: require("../assets/images/birthday-templates/11.png").default },
    { id: 12, image: require("../assets/images/birthday-templates/12.png").default },
    { id: 13, image: require("../assets/images/birthday-templates/13.png").default },
    { id: 14, image: require("../assets/images/birthday-templates/14.png").default },
    { id: 15, image: require("../assets/images/birthday-templates/15.png").default },
    { id: 16, image: require("../assets/images/birthday-templates/16.png").default },
    { id: 17, image: require("../assets/images/birthday-templates/17.png").default },
    { id: 18, image: require("../assets/images/birthday-templates/18.png").default },
    { id: 19, image: require("../assets/images/birthday-templates/19.png").default },
    { id: 20, image: require("../assets/images/birthday-templates/20.png").default },
  ];

export const AllergenList = [
    { id: 1, name: "Milk", image: require("../assets/images/meal-types/1.png") },
    { id: 2, name: "Egg", image: require("../assets/images/meal-types/2.png").default },
    { id: 3, name: "Celery", image: require("../assets/images/meal-types/3.png").default },
    { id: 4, name: "Peanut", image: require("../assets/images/meal-types/4.png").default },
    { id: 5, name: "Fish", image: require("../assets/images/meal-types/5.png").default },
    { id: 6, name: "Gluten", image: require("../assets/images/meal-types/6.png").default },
    { id: 7, name: "Soy", image: require("../assets/images/meal-types/7.png").default },
    { id: 8, name: "Lentil", image: require("../assets/images/meal-types/8.png").default },
    { id: 9, name: "Hazelnut", image: require("../assets/images/meal-types/9.png").default },
    { id: 10, name: "Almond", image: require("../assets/images/meal-types/10.png").default },
    { id: 11, name: "Mustard", image: require("../assets/images/meal-types/11.png").default },
    { id: 12, name: "Shellfish", image: require("../assets/images/meal-types/12.png").default },
    { id: 13, name: "Sulfite", image: require("../assets/images/meal-types/13.png").default },
    { id: 14, name: "Honey", image: require("../assets/images/meal-types/14.png").default },
    { id: 15, name: "Corn", image: require("../assets/images/meal-types/15.png").default },
    { id: 16, name: "Sesame", image: require("../assets/images/meal-types/16.png").default },
    { id: 17, name: "Sugar", image: require("../assets/images/meal-types/17.png").default },
    { id: 18, name: "Spice", image: require("../assets/images/meal-types/18.png").default },
    { id: 19, name: "Vegetarian", image: require("../assets/images/meal-types/19.png").default },
    { id: 20, name: "Organic", image: require("../assets/images/meal-types/20.png").default },
    { id: 21, name: "GMO", image: require("../assets/images/meal-types/21.png").default },
    { id: 22, name: "Mollusk", image: require("../assets/images/meal-types/22.png").default },
    { id: 23, name: "SO2", image: require("../assets/images/meal-types/23.png").default },
    { id: 24, name: "Mushroom", image: require("../assets/images/meal-types/24.png").default },
    { id: 25, name: "Alcohol", image: require("../assets/images/meal-types/25.png").default }
  ]

  export const MoodList = [
    { image: require("../assets/images/moods/boom.png").default, key: 0 },
    { image: require("../assets/images/moods/angry.png").default, key: 1 },
    { image: require("../assets/images/moods/happy.png").default, key: 2 },
    { image: require("../assets/images/moods/in-love.png").default, key: 3 },
    { image: require("../assets/images/moods/sad.png").default, key: 4 },
    { image: require("../assets/images/moods/party.png").default, key: 5 },
    { image: require("../assets/images/moods/sleepy.png").default, key: 6 },
    { image: require("../assets/images/moods/thinking.png").default, key: 7 },
    { image: require("../assets/images/moods/tongue.png").default, key: 8 },
]


export const WeekConstantData = (Data) => [
    {
      day: GetResourceData("Monday"),
      date: moment().startOf("week").format("DD.MM.YYYY"),
      children: Data.filter(x => (moment(x.start) <= moment().startOf("week") && moment(x.end) >=moment().startOf("week")))
    },
    {
      day: GetResourceData("Tuesday"),
      date: moment().startOf("week").add(1, "day").format("DD.MM.YYYY"),
      children: Data.filter(x => (moment(x.start).set({ hour: 0, minute: 0}) <= moment().startOf("week").add(1, "day") && moment(x.end) >= moment().startOf("week").add(1, "day")))
    },
    {
      day: GetResourceData("Wednesday"),
      date: moment().startOf("week").add(2, "day").format("DD.MM.YYYY"),
      children: Data.filter(x => (moment(x.start).set({ hour: 0, minute: 0}) <= moment().startOf("week").add(2, "day") && moment(x.end) >= moment().startOf("week").add(2, "day")))
    },
    {
      day: GetResourceData("Thursday"),
      date: moment().startOf("week").add(3, "day").format("DD.MM.YYYY"),
      children: Data.filter(x => (moment(x.start).set({ hour: 0, minute: 0}) <= moment().startOf("week").add(3, "day") && moment(x.end) >= moment().startOf("week").add(3, "day")))
    },
    {
      day: GetResourceData("Friday"),
      date: moment().startOf("week").add(4, "day").format("DD.MM.YYYY"),
      children: Data.filter(x => (moment(x.start).set({ hour: 0, minute: 0}) <= moment().startOf("week").add(4, "day") && moment(x.end) >= moment().startOf("week").add(4, "day")))
    },
    {
      day: GetResourceData("Saturday"),
      date: moment().startOf("week").add(5, "day").format("DD.MM.YYYY"),
      children: Data.filter(x => (moment(x.start).set({ hour: 0, minute: 0}) <= moment().startOf("week").add(5, "day") && moment(x.end) >= moment().startOf("week").add(5, "day")))
    },
    {
      day: GetResourceData("Sunday"),
      date: moment().startOf("week").add(6, "day").format("DD.MM.YYYY"),
      children: Data.filter(x => (moment(x.start).set({ hour: 0, minute: 0}) <= moment().startOf("week").add(6, "day") && moment(x.end) >= moment().startOf("week").add(6, "day")))
    },
  ]

  
export const BornToWeekConstantData = (bornToWeek) => 
[
  {
    day: GetResourceData("Monday"),
    children: bornToWeek.find(x => moment(x.birthDay).format("DD-MM") === moment().startOf("week").format("DD-MM"))?.users
  },
  {
    day: GetResourceData("Tuesday"),
    children: bornToWeek.find(x => moment(x.birthDay).format("DD-MM") === moment().startOf("week").add(1, "day").format("DD-MM"))?.users
  },
  {
    day: GetResourceData("Wednesday"),
    children: bornToWeek.find(x => moment(x.birthDay).format("DD-MM") === moment().startOf("week").add(2, "day").format("DD-MM"))?.users
  },
  {
    day: GetResourceData("Thursday"),
    children: bornToWeek.find(x => moment(x.birthDay).format("DD-MM") === moment().startOf("week").add(3, "day").format("DD-MM"))?.users
  },
  {
    day: GetResourceData("Friday"),
    children: bornToWeek.find(x => moment(x.birthDay).format("DD-MM") === moment().startOf("week").add(4, "day").format("DD-MM"))?.users
  },
  {
    day: GetResourceData("Saturday"),
    children: bornToWeek.find(x => moment(x.birthDay).format("DD-MM") === moment().startOf("week").add(5, "day").format("DD-MM"))?.users
  },
  {
    day: GetResourceData("Sunday"),
    children: bornToWeek.find(x => moment(x.birthDay).format("DD-MM") === moment().startOf("week").add(6, "day").format("DD-MM"))?.users
  },
]

  export const EmptyWeekData = [
    { day: "PZT", children: [] },
    { day: "SAL", children: [] },
    { day: "ÇAR", children: [] },
    { day: "PER", children: [] },
    { day: "CUM", children: [] },
    { day: "CTS", children: [] },
    { day: "PAZ", children: [] }
  ];

  export const EmptyMealWeekData = [
    { day: "PZT", mainMeals: [], salads: [], desserts: [] },
    { day: "SAL", mainMeals: [], salads: [], desserts: [] },
    { day: "ÇAR", mainMeals: [], salads: [], desserts: [] },
    { day: "PER", mainMeals: [], salads: [], desserts: [] },
    { day: "CUM", mainMeals: [], salads: [], desserts: [] },
    { day: "CTS", mainMeals: [], salads: [], desserts: [] },
    { day: "PAZ", mainMeals: [], salads: [], desserts: [] }
  ];

  export const MealWeekData = (pzt,sal,car,per,cum,cmt,paz) => [
    {
      day: GetResourceData("Monday"),
      mainMeals: pzt ? pzt.mainMeals : [],
      salads: pzt ? pzt.salads : [],
      desserts: pzt ? pzt.desserts : [],
    },
    {
      day: GetResourceData("Tuesday"),
      mainMeals: sal ? sal.mainMeals : [],
      salads: sal ? sal.salads : [],
      desserts: sal ? sal.desserts : [],
    },
    {
      day: GetResourceData("Wednesday"),
      mainMeals: car ? car.mainMeals : [],
      salads: car ? car.salads : [],
      desserts: car ? car.desserts : [],
    },
    {
      day: GetResourceData("Thursday"),
      mainMeals: per ? per.mainMeals : [],
      salads: per ? per.salads : [],
      desserts: per ? per.desserts : [],
    },
    {
      day: GetResourceData("Friday"),
      mainMeals: cum ? cum.mainMeals : [],
      salads: cum ? cum.salads : [],
      desserts: cum ? cum.desserts : [],
    },
    {
      day: GetResourceData("Saturday"),
      mainMeals: cmt ? cmt.mainMeals : [],
      salads: cmt ? cmt.salads : [],
      desserts: cmt ? cmt.desserts : [],
    },
    {
      day: GetResourceData("Sunday"),
      mainMeals: paz ? paz.mainMeals : [],
      salads: paz ? paz.salads : [],
      desserts: paz ? paz.desserts : [],
    }
  ];

  export const CurrencyCodes = [
    {
      "text" : "TRY",
      "value" : "TRY"      
    },
    {
      "text" : "USD",
      "value" : "USD"      
    },
    {
      "text" : "EUR",
      "value" : "EUR"      
    }
  ]

  export const CostTypes = [
    {
      "text" : "Köprü Ücreti",
      "value" : "Köprü Ücreti"      
    },
    {
      "text" : "Taksi Ücreti",
      "value" : "Taksi Ücreti"      
    },
    {
      "text" : "Yemek Ücreti",
      "value" : "Yemek Ücreti"      
    }
  ]
  
  export const TravelItemTypes = [
    "Accommodation",  
    "Airplane",
    "Train",
    "Bus",
    "Visa",
    "Other"
  ]

  export const DayOffRequestType = "1";
  export const CostRequestType = "2";
  export const VacationRequestType = "3";

  const GetSystemLanguageForDemo = () => {
    var systemLang = localStorage.getItem(sysLanguageKey);
    if(!systemLang) {
      systemLang = DefaultPortalLanguage;
    }    
    return systemLang;
  }
  export const DemoSurveyExampleEmpty ={
    isAnswered : false,
    title : GetSystemLanguageForDemo() == "Tr" ? "Çalışma Modeli Anketi" : "Working Model Survey",
    questions : [
        {
            "key" : 1,
            "img" : require("../assets/images/samples/anket.jpeg").default,
            "isRequired" : true,
            "questionTitle" : GetSystemLanguageForDemo() == "Tr" ? "Çalışma modelimiz nasıl olsun ?" : "How should our working model be ?" , 
            "questionAnswer" : null,
            "questionOptions" : [
                {
                    "value" : "remote",
                    "text" : "Remote",
                    "rate" : "",
                    "isWinner" : false
                },
                {
                    "value" : "hybrid",
                    "text" : "Hybrid",
                    "rate" : "",
                    "isWinner" : false
                },
                {
                    "value" : "onsite",
                    "text" : "Onsite",
                    "rate" : "",
                    "isWinner" : false
                }
            ]
        }
    ]
};
export const DemoSurveyExampleAnswered ={
  isAnswered : true,
  title : GetSystemLanguageForDemo() == "Tr" ? "Çalışma Modeli Anketi" : "Working Model Survey",
  questions : [
      {
          "key" : 1,
          "img" : require("../assets/images/samples/anket.jpeg").default,
          "isRequired" : true,
          "questionTitle" : GetSystemLanguageForDemo() == "Tr" ? "Çalışma modelimiz nasıl olsun ?" : "How should our working model be ?" , 
          "questionAnswer" : "remote",
          "questionOptions" : [
              {
                  "value" : "remote",
                  "text" : "Remote",
                  "rate" : "70%",
                  "isWinner" :true
              },
              {
                  "value" : "hybrid",
                  "text" : "Hybrid",
                  "rate" : "20%",
                  "isWinner" : false
              },
              {
                  "value" : "onsite",
                  "text" : "Onsite",
                  "rate" : "10%",
                  "isWinner" : false
              }
          ]
      }
  ]
};

  export const EmptySurveyExample ={
    title : "Anket Başlığı",
    questions : [
        {
            "key" : 1,
            "img" : "https://img-s2.onedio.com/id-56f1945d1c75a16007229334/rev-0/w-600/h-413/f-jpg/s-d97365b01b4a6dfe624a4b798dd7f86eacf7885c.jpg",
            "isRequired" : true,
            "questionTitle" : "Resimli zorunlu soru örneği.",
            "questionAnswer" : null,
            "questionOptions" : [
                {
                    "value" : "1",
                    "text" : "Cevap 1"
                },
                {
                    "value" : "2",
                    "text" : "Cevap 2"
                },
                {
                    "value" : "3",
                    "text" : "Cevap 3"
                }
            ]
        },
        {
            "key" : 2,
            "img" : null,
            "isRequired" : true,
            "questionTitle" : "Resimsiz zorunlu soru örneği.",
            "questionAnswer" : null,
            "questionOptions" : [
                {
                    "value" : "1",
                    "text" : "Cevap 1"
                },
                {
                    "value" : "2",
                    "text" : "Cevap 2"
                },
                {
                    "value" : "3",
                    "text" : "Cevap 3"
                },
                {
                    "value" : "4",
                    "text" : "Cevap 4"
                },
                {
                    "value" : "5",
                    "text" : "Cevap 5"
                }
            ]
        },
        {
            "key" : 3,
            "img" : null,
            "isRequired" : false,
            "questionTitle" : "Opsiyonel soru örneği.",
            "questionAnswer" : null,
            "questionOptions" : [
                {
                    "value" : "1",
                    "text" : "Evet"
                },
                {
                    "value" : "2",
                    "text" : "Hayır"
                }
            ]
        }
    ]
};
export const AnsweredSurveyExample ={
  title : "Anket Başlığı",
  questions : [
      {
          "key" : 1,
          "img" : "https://img-s2.onedio.com/id-56f1945d1c75a16007229334/rev-0/w-600/h-413/f-jpg/s-d97365b01b4a6dfe624a4b798dd7f86eacf7885c.jpg",
          "isRequired" : true,
          "questionTitle" : "Resimli zorunlu soru örneği.",
          "questionAnswer" : "2",
          "questionOptions" : [
              {
                  "value" : "1",
                  "text" : "Cevap 1"
              },
              {
                  "value" : "2",
                  "text" : "Cevap 2"
              },
              {
                  "value" : "3",
                  "text" : "Cevap 3"
              }
          ]
      },
      {
          "key" : 2,
          "img" : null,
          "isRequired" : true,
          "questionTitle" : "Resimsiz zorunlu soru örneği.",
          "questionAnswer" : "4",
          "questionOptions" : [
              {
                  "value" : "1",
                  "text" : "Cevap 1"
              },
              {
                  "value" : "2",
                  "text" : "Cevap 2"
              },
              {
                  "value" : "3",
                  "text" : "Cevap 3"
              },
              {
                  "value" : "4",
                  "text" : "Cevap 4"
              },
              {
                  "value" : "5",
                  "text" : "Cevap 5"
              }
          ]
      },
      {
          "key" : 3,
          "img" : null,
          "isRequired" : false,
          "questionTitle" : "Opsiyonel soru örneği.",
          "questionAnswer" : "2",
          "questionOptions" : [
              {
                  "value" : "1",
                  "text" : "Evet"
              },
              {
                  "value" : "2",
                  "text" : "Hayır"
              }
          ]
      }
  ]
};

export const imageTypes = [
  ".png",
  ".jpg",
  ".jpeg"
];

export const videoTypes = [
  ".mp4"
]

export const entityHeaderConstant = {
  "entityTargets": [
    {
      "company": 0
    }
  ],
  "entityPermissions": [
    
  ]
}

export const emptyGuid = '00000000-0000-0000-0000-000000000000';

export const requestTypeProblem = "requestTypeProblem";
export const requestTypeRequest = "requestTypeRequest";

export const travelTypeDomestic = "travelTypeDomestic";
export const travelTypeAbroad = "travelTypeAbroad";

export const optionYes = "optionYes";
export const optionNo = "optionNo";