import React, { useEffect, useState, useRef } from "react";
import { PageBase } from "../../../components";
import UserDetail from "../../../components/UserDetail";
import { GetUserDetail } from "../../../business/Public/CommonBusiness";
import { GetResourceData } from "../../../helpers/basemethods";
import { Tabs, Table, Button } from "antd";
import Column from "antd/lib/table/Column";
import TabPane from "antd/lib/tabs/TabPane";
import { VacationRequestType } from "../../../helpers/constantData";
import { useSelector } from "react-redux";
import { Store } from "../../../state";
import history from '../../../history';

export interface ProfileDetailProps { }

const ProfileDetail: React.FC<ProfileDetailProps> = () => {
    const [PageLoading, setPageLoading] = useState<boolean>(false)
    const [UserDetails, setUserDetails] = useState<any>(null)
    const [UserName, setUserName] = useState<string | null | undefined>(window.location.href.split("/").pop())
    const { currentUser } = useSelector((s: Store) => s.auth);

 
    if (UserName == currentUser.userName) {
        history.push("/my-profile")
    }

    useEffect(() => {
        if (UserName) {
            getData(UserName)
        }
    }, [0]);

    const getData = async (Id) => {
        setPageLoading(true)
        const userData = await GetUserDetail(Id);
        setUserDetails(userData)
        setPageLoading(false)
    }

    return (
        <PageBase menuName={"ProfileDetail"} className="content-detail" loading={PageLoading}>
            {

                UserDetails &&
                <>
                <div className="search-result-container" style={{ display: "block" }}>
                    <div className="search-result-person-list">
                        <UserDetail user={UserDetails} />
                    </div>
                </div>                         
                
                </>  
            }
            {
                !UserDetails &&
                <div className="search-result-container" style={{ display: "block" }}>
                    <div className="search-result-person-list">    
                        <div key="userDetailKey" className={`item`} style={{display:"flex", justifyContent:"center"}}>
                            <div className="detail big">{GetResourceData("UserNotFound")}</div>
                        </div>
                    </div>
                </div>  
            }
        </PageBase>
    );
};

export default ProfileDetail;
