import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { isUsingIdentity } from './helpers/root';
import { Provider } from 'react-redux';
import store from './state';
import { callApi } from './helpers/service';


const Index = () => {
  const [state, setstate] = React.useState<any>(null);
  

React.useEffect(() => {

    (async () => {
      localStorage.setItem("access_token", "token");
      setstate("token");
      // const userData = {
      //   UserName : "02484569",
      //   Value: "1234"
      // }
      // callApi({
      //     url: `/api/Public/LoginTest/adminuser@VNSTeknoloji.com.tr`,
      //     method: 'POST',
      //     data: null,
      //     useToken: false,
      //     successCallback: (res: any) => {
      //       localStorage.setItem("access_token", res.data.result.token);
      //       setstate(res.data.result.token);
      //     }
      // })

    })();


}, [0]);

// if (window.location.href.indexOf("teams-login") > -1) {
//   return <TeamsLogin />
// }




// React.useEffect(() => {
//     (async () => {

//       const userData:any = "semih"

//       setstate((_:any) => ({ token: userData }));
//       localStorage.setItem("access_token", userData);

//     })();


// }, []);

if (!isUsingIdentity() && !state) {
    return <>Oturum Açılıyor1...</>
}


if(state) {
  
return (
  <Provider store={store}>
    <App />
  </Provider>)
}

  return <>Oturum Açılıyor2...</>
}



ReactDOM.render(
  <Index />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();