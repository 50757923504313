import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../state';
import {  Button, Card, Col, Collapse, Divider, Modal, Popconfirm, Row,  Table, Tabs,  TabsProps,  Tooltip } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import Column from 'antd/lib/table/Column';
import { GetResourceData } from '../helpers/basemethods';
import { getMyDemandContent, setMyDemandContent } from '../state/reducers/DemandReducer';
import { CostRequestType, DayOffRequestType, VacationRequestType } from '../helpers/constantData';
import RequestTable from './RequestTable';
import { isMobile } from 'react-device-detect';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

export interface MyRequestProps { }
const MyRequest: React.FC<MyRequestProps> = (props) => {
    
    const dispatch = useDispatch();

    const [showItemDetailType, SetShowItemDetailType] = useState<any>(null)
    const [showItemDetail, SetShowItemDetail] = useState<any>(null)
    const [showItemHistory, SetShowItemHistory] = useState<any>(null)
    const [activeTab, setActiveTab] = useState<string>("1");

    const { myDemandContent  } = useSelector((s: Store) => s.demand);

    useEffect(() => {      
       if (!myDemandContent) {
        dispatch(getMyDemandContent());
       }
      }, []);

  
    const requestDetail = async (id) => {
        await getItemDetail(id)
        .then((response) => {
            
            SetShowItemDetail(response);
            SetShowItemDetailType(response.type);
            SetShowItemHistory(response.history);
        })
        .catch((error) => {
            SetShowItemDetail(null)            
            SetShowItemDetailType(null);         
            SetShowItemHistory(null);
        });          
    }

    async function getItemDetail(id)  {
        let obj = myDemandContent.data.filter(x=> x.id == id)[0];
        return {
            "id" : obj.id,
            "type" : obj.type,
            "history" : obj.history,
            "dayOffBalance" : "14",
            "startDate" : "29 Şubat 2024",
            "endDate" : "4 Mart 2024",
            "usedDayOff" : "3",
            "startingWorkDate" : "5 Mart 2024",
            "dayOffType" : "Yıllık Ücretli İzin",
            "delegatedUser" : "Volkan Seçginli",
            "description" : obj.desc,
            "demandDate" : obj.date,
            "demandState" : obj.state,
            "currencyType" : "TRY",
            "costList" : [
                {
                    "costDateAndType" : "29 Şubat 2024 - Köprü Ücreti",
                    "amount" : "350 TRY",
                    "desc" : "test açıklama"
                },
                {
                    "costDateAndType" : "29 Şubat 2024 - Yemek Ücreti",
                    "amount" : "200 TRY",
                    "desc" : "test yemek açıklama"
                }
            ]
            

        }
    }

    const CancelRequest = (itemId) => {
        dispatch(setMyDemandContent({
            "lang" : myDemandContent.lang,
            "data" : myDemandContent.data.filter(x=> x.id != itemId)
        }))
    };


    const { Panel } = Collapse;
    const items: TabsProps['items'] = [
        {
          key: '1',
          label: <>{GetResourceData("OpenRequests")}</> ,
          children: <RequestTable _isStateOpen={true} _myDemandContent={myDemandContent} _requestDetail={requestDetail} _cancelRequest={CancelRequest} />
        },
        {
          key: '2',
          label: <>{GetResourceData("ClosedRequests")}</> ,
          children: <RequestTable _isStateOpen={false} _myDemandContent={myDemandContent} _requestDetail={requestDetail}  />
        }
        
      ];

      const onChangeTab = (key) => {
        setActiveTab(key);
      };
  
      const activeKeyLeft = () => {
        let _activeKeyNum = parseInt(activeTab)
        if(_activeKeyNum>1){
          setActiveTab((_activeKeyNum-1).toString())
        }
      }
  
      const activeKeyRight = () => {
        let _activeKeyNum = parseInt(activeTab)
        if(_activeKeyNum<items.length){
          setActiveTab((_activeKeyNum+1).toString())
        }
      }


    return (
        <React.Fragment>    
            <>   
            {
                isMobile &&
                <>
                <a style={{display: parseInt(activeTab)==1 ? "none" : "block"}} onClick={()=>{ activeKeyLeft() }}><div className="corporateButtons left" ><LeftOutlined /></div></a>
                <a style={{display: parseInt(activeTab)==items.length ? "none" : "block"}} onClick={()=>{ activeKeyRight() }}><div className="corporateButtons right"><RightOutlined /></div></a>
                </>
            }     
            {            
                <Tabs
                    defaultActiveKey="1" 
                    activeKey={activeTab} 
                    onChange={onChangeTab}
                    type="card"
                    tabPosition="top"
                    items={items}
                />                
            }
            {
                 <Modal
                    open={showItemDetailType}
                    onCancel={() => { SetShowItemDetailType(null) }}
                    destroyOnClose
                    footer={null}
                    // bodyStyle={{overflowY: 'scroll'}}
                    className='thanks-modal birthday-greeting detail-modal'
                >
                    {
                        showItemDetailType === DayOffRequestType ?
                            <>
                                    <Card type='inner' title={GetResourceData("DemandDetail")} style={{height:"100%"}}>                                 
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestNo")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>Req-{showItemDetail.id}</span>
                                        </Col>
                                    </Row>                                 
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("AvailableBalance")} </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{showItemDetail.dayOffBalance}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("StartDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{showItemDetail.startDate}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("EndDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.endDate}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DayOffBeUsed")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.usedDayOff}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("StartOfWeek")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.startingWorkDate}</span>
                                        </Col>
                                    </Row>
                                    <Divider/>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DayOffType")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.dayOffType}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DelegateUser")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.delegatedUser}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("Detail")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.description}</span>
                                        </Col>
                                    </Row>
                                    <Divider/>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.demandDate}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestState")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.demandState}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Collapse>
                                                <Panel style={{fontSize:"11px"}} header={GetResourceData("RequestHistory")} key="1">
                                                    <Table dataSource={showItemHistory} size='small'>
                                                        <Column title={GetResourceData("Process")} dataIndex="process" key="process"/>
                                                        <Column title={GetResourceData("PerformedBy")} dataIndex="user" key="user" />
                                                        <Column title={GetResourceData("Date")} dataIndex="date" key="date" />
                                                    </Table>
                                                </Panel>                                 
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        : showItemDetailType === CostRequestType ?
                            <>
                                <Card type='inner' title={GetResourceData("DemandDetail")} style={{height:"100%"}}>                             
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("CurrencyCode")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.currencyType}</span>
                                        </Col>
                                    </Row>
                                    {
                                        showItemDetail.costList.map(item => (
                                            <div key={item.id}> {/* Her bir döngü elemanı için benzersiz bir 'key' propu eklemeyi unutmayın */}
                                                <Divider />
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span style={{ fontWeight: "bold" }}>{GetResourceData("RequestTypeDate")} : </span>
                                                    </Col>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span>{item.costDateAndType}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span style={{ fontWeight: "bold" }}>{GetResourceData("Amount")} : </span>
                                                    </Col>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span>{item.amount}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span style={{ fontWeight: "bold" }}>{GetResourceData("Description")} : </span>
                                                    </Col>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span>{item.desc}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))
                                    }

                                    
                                    <Divider/>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>20.02.2024</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestState")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>Onay Bekliyor (Volkan Seçginli)</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Collapse>
                                                <Panel style={{fontSize:"11px"}} header={GetResourceData("RequestHistory")} key="1">
                                                    <Table dataSource={showItemHistory} size='small'>
                                                        <Column title={GetResourceData("Process")} dataIndex="process" key="process"/>
                                                        <Column title={GetResourceData("PerformedBy")} dataIndex="user" key="user" />
                                                        <Column title={GetResourceData("Date")} dataIndex="date" key="date" />
                                                    </Table>
                                                </Panel>                                 
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    
                                </Card>
                            </>   
                        :
                            <>
                                {/* <span>Seyahat Detayı - {showItemDetailType}</span> */}
                            </>                     
                    }
             </Modal>
            }
           </>
        </React.Fragment>
    );
}

export default MyRequest;