import * as React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export interface SpinnerProps {
    size?: "small" | "default" | "large" | undefined
}
 
const Spinner: React.FC<SpinnerProps> = ({size}) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return ( <Spin size={size} indicator={antIcon} /> );
}
 
export default Spinner;