import React, { useEffect, useState } from 'react';
import { Layout, Result, Skeleton } from 'antd';
import { anyMatchInArray } from '../../helpers/root';
import { useSelector } from 'react-redux';
import { Store } from '../../state';
import _ from 'lodash';
const { Content } = Layout;


export interface PageBaseProps {
    menuName?: string;
    transparent?: boolean;
    showBreadcrump?: boolean;
    showPageHeader?: boolean;
    loading?: boolean;
    title?: string | null;
    bottomAction?: React.ReactNode;
    subTitle?: string;
    extra?: React.ReactNode;
    footer?: React.ReactNode;
    className?: string;
}

interface IState {
    is403: boolean;
}

const PageBase: React.FC<PageBaseProps> = (props) => {
    const { transparent, children, menuName, showBreadcrump, showPageHeader, className, loading, title, bottomAction, subTitle, extra, footer } = props;
    const { currentUser } = useSelector((s: Store) => s.auth);

    // const pageHeader = useSetMenu(menuName, title, showBreadcrump, subTitle, extra, footer);

    const [state, setstate] = useState<IState>({
        is403: false
    });

    // useEffect(() => {
    //     if(menuName)
    //     dispatch(setSelectedMenu(getMenuItem(menuName).val));
    // }, [])

    // useEffect(() => {
    //     const menu = getMenuItem(menuName);

    //     if (menu && _.has(menu, "roles")) {
    //         const menuRoles = getMenuItem(props.menuName).roles;
    //         const userRoles = [...currentUser!.roles, "User"];

    //         const roleMatch = anyMatchInArray(userRoles, menuRoles);


    //         setstate({
    //             is403: !roleMatch
    //         });
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    if (state.is403) {
        return (<Result
            status="403"
            title="403"
            subTitle="Üzgünüz, bu sayfayı görüntüleme yetkiniz bulunmuyor."
        //extra={<Button type="primary">Back Home</Button>}
        />)
    }

    return (
        <>
            {showPageHeader}

            <Content className={className}>

                {loading ? <Skeleton active /> : children}

            </Content>

            {bottomAction &&
                <div className="bottom-action">
                    {bottomAction}
                </div>
            }
        </>
    );
}

export default PageBase;