import React, { lazy } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { getMenuItem } from '../helpers/menu';
import AdminLayout from '../layouts/AdminLayout';
// import EventCalendar from '../pages/Admin/EventCalendar';
// import EventCalendarForm from '../pages/Admin/EventCalendar/form';
import ContentCategoryForm from '../pages/Admin/ContentManagement/ContentCategory/form';
import ContentList from '../pages/Admin/ContentManagement/Content';
import ContentForm from '../pages/Admin/ContentManagement/Content/form';
import FAQList from '../pages/Admin/ContentManagement/FAQ';
import FAQForm from '../pages/Admin/ContentManagement/FAQ/form';
// import FileManagement from '../pages/Admin/FileManagement';
// import PopupManagement from '../pages/Admin/PopupManagement';
// import PopupManagementForm from '../pages/Admin/PopupManagement/form';
// import CameraManagement from '../pages/Admin/CameraManagement';
// import CameraManagementForm from '../pages/Admin/CameraManagement/form';
// import ThanksManagement from '../pages/Admin/ThanksManagement';
// import ThanksManagementDetailForm from '../pages/Admin/ThanksManagement/form';
// import ServiceManagement from '../pages/Admin/ServiceManagement';

const AdminDashboard = lazy(() => import('../pages/Admin/Dashboard'));
// const RoleManagement = lazy(() => import('../pages/Admin/RoleManagement/RoleManagement'));
// const GroupManagement = lazy(() => import('../pages/Admin/GroupManagement'));
// const LinkManagement = lazy(() => import('../pages/Admin/LinkManagement/LinkManagement'));
const ContentCategoryList = lazy(() => import('../pages/Admin/ContentManagement/ContentCategory'));
// const MealMenuManagement = lazy(() => import('../pages/Admin/MealMenuManagement'));
const BannerManagement = lazy(() => import('../pages/Admin/Banner/BannerManagement'));
const BannerManagementDetail = lazy(() => import('../pages/Admin/Banner/SubPages/BannerManagementDetail'));
const BannerManagementNew = lazy(() => import('../pages/Admin/Banner/SubPages/BannerManagementNew'));



const AdminRoute = ({ children, ...rest }: RouteProps) => {
    return (
        <Route {...rest}>
            <AdminLayout>
                {children}
            </AdminLayout>
        </Route>
    );
}

const AdminRoutes = () => {
    return (
        <>
            <AdminRoute path={getMenuItem("AdminDashboard").path} exact>
                <AdminDashboard />
            </AdminRoute>
            <AdminRoute path={getMenuItem("BannerManagement").path} exact>
                <BannerManagement />
            </AdminRoute>
            <AdminRoute path={getMenuItem("BannerManagementDetail").path} exact>
                <BannerManagementDetail />
            </AdminRoute>

            <AdminRoute path={getMenuItem("BannerManagementNew").path} exact>
                <BannerManagementNew />
            </AdminRoute>

            <AdminRoute path={getMenuItem("ContentCategoryList").path} exact>
                <ContentCategoryList />
            </AdminRoute>
            <AdminRoute path={getMenuItem("ContentCategoryNew").path} exact>
                <ContentCategoryForm />
            </AdminRoute>
            <AdminRoute path={getMenuItem("ContentCategoryEdit").path} exact>
                <ContentCategoryForm />
            </AdminRoute>

            <AdminRoute path={getMenuItem("ContentList").path} exact>
                <ContentList />
            </AdminRoute>
            <AdminRoute path={getMenuItem("ContentNew").path} exact>
                <ContentForm />
            </AdminRoute>
            <AdminRoute path={getMenuItem("ContentEdit").path} exact>
                <ContentForm />
            </AdminRoute>

            <AdminRoute path={getMenuItem("FAQList").path} exact>
                <FAQList />
            </AdminRoute>
            <AdminRoute path={getMenuItem("FAQNew").path} exact>
                <FAQForm />
            </AdminRoute>
            <AdminRoute path={getMenuItem("FAQEdit").path} exact>
                <FAQForm />
            </AdminRoute>
             {/* 
            <AdminRoute path={getMenuItem("RoleManagement").path} exact>
                <RoleManagement />
            </AdminRoute>

            <AdminRoute path={getMenuItem("GroupManagement").path} exact>
                <GroupManagement />
            </AdminRoute>


            

            <AdminRoute path={getMenuItem("LinkManagement").path} exact>
                <LinkManagement />
            </AdminRoute>


            




            <AdminRoute path={getMenuItem("MealMenuManagement").path} exact>
                <MealMenuManagement />
            </AdminRoute>
            <AdminRoute path={getMenuItem("EventCalendar").path} exact>
                <EventCalendar />
            </AdminRoute>
            <AdminRoute path={getMenuItem("EventCalendarNew").path} exact>
                <EventCalendarForm />
            </AdminRoute>
            <AdminRoute path={getMenuItem("EventCalendarEdit").path} exact>
                <EventCalendarForm />
            </AdminRoute>


            <AdminRoute path={getMenuItem("FileManagement").path} exact>
                <FileManagement />
            </AdminRoute>

            <AdminRoute path={getMenuItem("PopupManagement").path} exact>
                <PopupManagement />
            </AdminRoute>
            <AdminRoute path={getMenuItem("PopupManagementNew").path} exact>
                <PopupManagementForm />
            </AdminRoute>
            <AdminRoute path={getMenuItem("PopupManagementEdit").path} exact>
                <PopupManagementForm />
            </AdminRoute>

            <AdminRoute path={getMenuItem("CameraManagement").path} exact>
                <CameraManagement />
            </AdminRoute>
            <AdminRoute path={getMenuItem("CameraManagementNew").path} exact>
                <CameraManagementForm />
            </AdminRoute>
            <AdminRoute path={getMenuItem("CameraManagementEdit").path} exact>
                <CameraManagementForm />
            </AdminRoute>

            <AdminRoute path={getMenuItem("ThanksManagement").path} exact>
                <ThanksManagement />
            </AdminRoute>
            <AdminRoute path={getMenuItem("ThanksManagementDetail").path} exact>
                <ThanksManagementDetailForm />
            </AdminRoute> */}
            {/* <AdminRoute path={getMenuItem("ServiceManagement").path} exact>
                <ServiceManagement />
            </AdminRoute> */}
 
        </>
    )
}

export default AdminRoutes;