import React, { useEffect, useState } from "react";
import { PageBase, RichTextEditor } from "../../../../components";
import { getMenuItem } from "../../../../helpers/menu";
import { getUrlParamValue, showNoti } from "../../../../helpers/root";
import { Anchor, Button, Col, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Switch } from "antd";
import EntityPermissionManagement from '../../EntityPermissionManagement/EntityPermissionManagement';
import { EntityPermission } from "../../../../type/EntityPermissionManagement/EntityPermissionRequest";
import { EntityTargetInfo } from "../../../../type/EntityPermissionManagement/EntityTargetInfoRequest";
import { EntityHeader } from "../../../../type/EntityPermissionManagement/EntityHeader";
import { EntityType } from "../../../../type/Enum/EntityType";
import { callApi } from "../../../../helpers/service";
import { NotifierHeaderInfoType, NotifierResultType, formModes, methods } from "../../../../helpers/enums";
import history from "../../../../history";
import FileUpload from "../../../../components/FileUpload";
import { Content } from "../../../../type/ContentManagement/Content";
import uuidv1 from "uuid/v1";

const ContentForm: React.FC = () => {
    const id = getUrlParamValue("id");
    const categoryId = getUrlParamValue("categoryId");
    const { Link } = Anchor;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [ShowPermission, setShowPermission] = useState<boolean>(false);
    const [formMode, setFormMode] = useState<any>(formModes.NEW);
    const [ContentCategoryList, setContentCategoryList] = useState<any[]>([]);
    const [entityHeaderData, setEntityHeaderData] = useState<EntityHeader>({ entityPermissions: [], entityTargetInfo: {} });
    const [Category, setCategory] = useState<any>(null);

    useEffect(() => {
        getAllContentCategories()
        if (id) {
            setFormMode(formModes.EDIT)
            getData()
        } else {
            setFormMode(formModes.NEW)
            setShowPermission(true)
        }
    }, [])

    const getData = () => {
        callApi({
            url: `/admin/Content/GetContentById/${id}`, method: methods.GET, useToken: true,
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    form.setFieldsValue({ ...response.data.result.content, categoryId: response.data.result.content.category?.id })
                    setEntityHeaderData({ ...response.data.result.header })
                    setShowPermission(true)
                    setCategory(response.data.result.content.category?.id)
                }
            },
            finish: () => {
                setLoading(false)
            }
        })
    }
    const getAllContentCategories = () => {
        callApi({
            url: `/admin/Content/GetAllContentCategories`, method: methods.GET, useToken: true,
            successCallback: (response) => {
                let $data: any[] = [];
                response.data.result.forEach(element => {
                    if (element.childCategories.length > 0) {
                        $data.push({ label: element.name, options: element.childCategories.map((x => { return { label: x.name, value: x.id } })) })
                    }
                });
                setContentCategoryList($data)
                if (categoryId) {
                    form.setFieldsValue({ categoryId: categoryId })
                    setCategory(categoryId)
                }
            },
            finish: () => {
                setLoading(false)
            }
        })

    }
    const _save = (content: Content) => {

        var params: any = {
            ...content,
            entityHeader: entityHeaderData,
        };
        let apiUrl = "admin/Content/CreateContent";
        if (formMode === formModes.EDIT) {
            params.id = id?.toString();
            apiUrl = "admin/Content/UpdateContent";
        }
        
        setLoading(true);
        callApi({
            url: apiUrl, method: methods.POST, useToken: true, data: params,
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    showNoti(NotifierResultType.success, "Başarılı", "Kaydetme işlemi başarılı")
                    history.goBack()                    
                } else {
                    showNoti(NotifierResultType.error, "Hata!", "Kaydetme işlemi sırasında bir hata meydana geldi")
                }
            },
            finish: () => {
                setLoading(false)
            }
        })
    }
    const _onDelete = () => {
        setLoading(true)
        callApi({
            url: "/admin/Content/DeleteContent",
            method: methods.POST,
            useToken: true,
            data: { id: id },
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    showNoti(NotifierResultType.success, "Başarılı", "Silme işlemi başarılı")
                    history.goBack()
                }
            },
            finish: () => {
                setLoading(false)
            }
        })

    }
    const onChangeEntityPermissions = (permissionList: EntityPermission[]) => {
        const newState = { ...entityHeaderData };
        newState.entityPermissions = permissionList;
        setEntityHeaderData(newState)
    }
    const onChangeEntityTargetInfo = (targetInfo: EntityTargetInfo) => {
        const newState = { ...entityHeaderData };
        newState.entityTargetInfo = targetInfo!;
        setEntityHeaderData(newState)
    }
    const getMenuName = () => {
        if (formMode === formModes.NEW) {
            return getMenuItem('ContentNew').text
        }
        if (formMode === formModes.EDIT) {
            return getMenuItem('ContentEdit').text
        }
        return ""
    }

    const onDeleteImage = (file) => {
        if(form && form.getFieldValue('imageId')){
            if(form.getFieldValue('imageId') == file){
                form.setFieldsValue({ imageId: null})
            }
        }
    }
    return (
        <PageBase menuName={getMenuName()} title={getMenuName()} showPageHeader showBreadcrump>
            <Form form={form} onFinish={_save} layout='vertical' >
                <Row gutter={10}>
                    {
                        Category &&
                        <Col lg={24}>
                            <Form.Item label="Üst Kategori (Seçtili kategori altına yeni kategori ekler.)" name="categoryId" rules={[{ required: true, message: 'Lütfen üst kategöri seçiniz!' }]}>
                                <Select loading={loading} disabled={formMode === formModes.NEW} options={ContentCategoryList} showSearch></Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col lg={24}>
                        <Form.Item label="Başlık" name="title" rules={[{ required: true, message: 'Lütfen başlık giriniz!' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item label="İçerik" name="description" rules={[{ required: true, message: 'Lütfen içerik giriniz' }]}>
                            <RichTextEditor  />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item label="Icon" name="icon" rules={[{ required: false, message: 'Lütfen Icon Adı Giriniz' }]}>
                            <Input style={{ width: "100%" }} />
                        </Form.Item>
                        <Anchor>
                            <Link href="https://fontawesome.com/v5/search" title="Icon listesi için buraya tıklayabilirsiniz. (Örnek Kullanım: fal fa-user)" target="_blank"></Link>
                        </Anchor>
                    </Col>
                    <Col lg={3}>
                        <Form.Item label="Sıra" name="sequence" rules={[{ required: true, message: 'Lütfen sıra numarası giriniz!' }]}>
                            <InputNumber min={1} />
                        </Form.Item>
                    </Col>
                    <Col lg={3}>
                        <Form.Item label="Aktiflik" valuePropName="checked" name="isActive">
                            <Switch defaultChecked={true}/>
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item help="Lütfen görseli 663 x 442 boyutunda yükleyiniz." label="Görsel" name="imageId" rules={[{ required: false, message: 'Lütfen bir görsel yükleyiniz' }]}>
                            <FileUpload onDelete={(file) => { onDeleteImage(file) }} />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item>
                            {
                                ShowPermission &&
                                <EntityPermissionManagement
                                    key={id ?? 0}
                                    entityPermissions={entityHeaderData?.entityPermissions}
                                    entityTargetInfo={entityHeaderData?.entityTargetInfo!}
                                    onChangeEntityPermissions={(data) => onChangeEntityPermissions(data)}
                                    onChangeEntityTargetInfo={(data) => onChangeEntityTargetInfo(data)}
                                    entityType={EntityType.content}
                                />
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Space size={8}>
                        <Button loading={loading} htmlType="submit">Kaydet</Button>
                        {formMode === formModes.EDIT &&
                            <Popconfirm title={"Silmek istediğinize emin misiniz?"} onConfirm={_onDelete} okText="Evet" cancelText="Hayır">
                                <Button className="red" loading={loading}>Sil</Button>
                            </Popconfirm>
                        }
                    </Space>
                </Form.Item>
            </Form>
        </PageBase>
    );
}

export default React.memo(ContentForm);