import React, { useCallback, useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import "react-quill/dist/quill.core.css";

Quill.register('modules/imageResize', ImageResize);


interface IProps {
  onChange?: Function;
  value?: any;
}

const RichTextEditor: React.FC<IProps> = ({ onChange, value }) => {

  const [editorHtml, setEditorHtml] = useState("");

  useEffect(() => {
    if (value) setEditorHtml(value);
  }, [value]);



  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'width', 'height', 'alt', 'style', 'size'
  ];

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };




  const handleChange = useCallback(
    (html: any) => {
      setEditorHtml(html);
      onChange && onChange(html);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



  return (
    <div className="richtext-wrapper"  >
      <ReactQuill
        theme={"snow"}
        defaultValue={value || ""}
        onChange={handleChange}
        value={editorHtml || ""}
        modules={modules}
        formats={formats}
        bounds={'.richtext-wrapper'}
      />
    </div>
  );
}

export default RichTextEditor