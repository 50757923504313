import { GetSystemLanguage } from "../../helpers/basemethods";
import { HeaderMenuType } from "../../type/Header/HeaderMenuType";
import { BusinessResponseType } from "../../type/Common/BusinessResponseType";
import { SearchResultType } from "../../type/Header/SearchResultType";
import { callApi } from "../../helpers/service";
import { AxiosResponse } from "axios";
import { testApiCall } from "./CommonBusiness";
const uuidv1 = require("uuid/v1");

//Linkli kategorileri dönen servis çağrımı
export function GetAllLinkCategoriesWithLinks():BusinessResponseType<any> {
    // callApi({
    //     url: "Link/GetAllLinkCategoriesWithLinks",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             return res.data.result;
    //         }
    //     }
    // })
    return {
        isSuccessful : true,
        result : [
            {
                "categoryName" :"IK Uygulamaları",
                "icon" :"<i className={'fas fa-home'}></i>",
                "links": [
                    {
                        "name": "İzin Talebi",
                        "url":"https://google.com",
                        "openNewTab": true,
                        "icon" : null
                    }
                ]
            }
        ]
    };
}

//sistem dilinin imajını döner
export const getLanguageResource = (key) => {
    var sysLang = GetSystemLanguage();
    if(key) {
        sysLang = key
    }
    return require("../../assets/images/flags/"+sysLang+".png");
}

//Sık arananlar bilgisini dönen servis
export function GetTopSearchKeys():BusinessResponseType<any> {
  // callApi({
  //     url: "Search/GetTopSearchKeys",
  //     useToken: true,
  //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
  //         if (res.status === 200) {
  //             dispatch(setPopularSearch(res.data.result));
  //         }
  //     }
  // })
   return {
       isSuccessful : true,
       result : ["izin Politikası","Kurumsal Haberler","Yan Haklar","Sosyal Aktiviteler","Ulaşım Hizmetleri"]
   };
}

//Mood değiştiren servis
export function UpdateMood(mood):BusinessResponseType<any> {
    // callApi({ 
    //     url: `User/UpdateMood`,
    //     useToken: true,
    //     method: methods.POST, 
    //     data: { 
    //         mood: parseInt(e.key)
    //     }, 
    //     successCallback: (response: AxiosResponse) => { 
    //         dispatch(setCurrenUser(response.data.result)); 
    //     } 
    // });
    const userData = {
        mood:mood,
        id:1,
        email:"volkan.secginli@vnsteknoloji.com",
        profilePhoto:"dasdas",
        roles:["Admin"],
        name: "Volkan",
        userName: "volkan.secginli",
        fullName: "Volkan Seçginli",
        unvan: "IT Manager",
        department: "IT",
        hireDate : "01.01.2020",
        managerUserName : "volkan.secginli",
        managerFullName : "Volkan Seçginli",
        phoneNumber : "5436566565",
        mobileShortCode: "9488",
        birthDay : "01.01.1999",
        "currentLocation" : "Merkez Ofis",
        "currentLocationId" : 1
    }
    return {
         isSuccessful : true,
         result : userData
     };
}

//Location değiştiren servis
export function UpdateLocation(loc,val):BusinessResponseType<any> {
    // callApi({ 
    //     url: `User/UpdateMood`,
    //     useToken: true,
    //     method: methods.POST, 
    //     data: { 
    //         mood: parseInt(e.key)
    //     }, 
    //     successCallback: (response: AxiosResponse) => { 
    //         dispatch(setCurrenUser(response.data.result)); 
    //     } 
    // });
    const userData = {
        mood:2,
        id:1,
        email:"volkan.secginli@vnsteknoloji.com",
        profilePhoto:"dasdas",
        roles:["Admin"],
        name: "Volkan",
        userName: "volkan.secginli",
        fullName: "Volkan Seçginli",
        unvan: "IT Manager",
        department: "IT",
        hireDate : "01.01.2020",
        managerUserName : "volkan.secginli",
        managerFullName : "Volkan Seçginli",
        phoneNumber : "5436566565",
        mobileShortCode: "9488",
        birthDay : "01.01.1999",
        currentLocation : val,
        currentLocationId : loc
    }
    return {
         isSuccessful : true,
         result : userData
     };
}


//Header menü beslenen servis
export function GetHeaderMenuItems() {
    type HeaderMenuList = HeaderMenuType[];
    const menuItems :HeaderMenuList = [
        {
            mainIconClass : "fas fa-dice-d6",
            content : GetSystemLanguage() == "Tr" ? "Çalışan İş Akışları" : "Employee Requests",
            key : 1,
            children : [
                {
                    link : "/corporate-demands?tab=1",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "İzin Talep" : "Day-off Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands?tab=2",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Masraf Talep" : "Cost Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands?tab=3",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Seyahat Talep" : "Travel Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands?tab=3",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Eğitim Talep" : "Education Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands?tab=4",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "BT Talep" : "IT Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands?tab=5",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "İdari İşler Talep" : "Administrative Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Araç/Servis Talep" : "Vehicle/Service Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Masa/Oda Rezervasyon" : "Table/Room Reservation",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "E-İmza Talep" : "E-Signature Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Fikrim Geldi" : "I've Got an Idea",
                    key : uuidv1()
                }
            ]
        },{
            mainIconClass : "fas fa-dice-d6",
            content : GetSystemLanguage() == "Tr" ? "IK Uygulamaları" : "HR Applications",
            key : 2,
            children : [
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "IK Uygulaması" : "HR Practice",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Self Servis Uygulaması" : "Self Service Application",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Akademi Platformu" : "Academy Platform",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Kim Kimdir?" : "Who is Who?",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Organizasyon Şeması YAKINDA!" : "Organization Chart SOON!",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Açık Pozisyonlar" : "Open Positions",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Kurumsal Gelişim Platformu YAKINDA!" : "Corporate Development Platform COMING SOON!",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Çalışan Ödüllendirme/Oyunlaştırma YAKINDA!" : "Employee Rewarding/Gamification COMING SOON!",
                    key : uuidv1()
                }
            ]
        },{
            mainIconClass : "fas fa-dice-d6",
            content : GetSystemLanguage() == "Tr" ? "Kurumsal İş Akışları" : "Corporate Workflows",
            key : 3,
            children : [
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Satınalma Talebi" : "Purchase Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "CRM Duyum Akışı" : "CRM Sensation Flow",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Faturalarım" : "My Bills",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Hizmet/DK Talep" : "Service/DC Request",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Güvenlik İhlal Bildirimi" : "Security Breach Notification",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Demirbaşlarım" : "My Fixtures",
                    key : uuidv1()
                }
            ]
        },{
            mainIconClass : "fas fa-dice-d6",
            content : GetSystemLanguage() == "Tr" ? "Kurumsal Uygulamalar" : "Enterprise Applications",
            key : 4,
            children : [
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "CRM Uygulamaları" : "CRM Application",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "ERP Uygulamaları" : "ERP Application",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "İş Zekası Platformu" : "Business Intelligence Platform",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Süreç-Kalite Platformu" : "Process-Quality Platform",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "QDMS Platformu" : "QDMS Platform",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Web Sitemiz" : "Our Web Site",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "İletişim Platformları (Zoom,Teams)" : "Communication Platforms (Zoom, Teams)",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "E İmza Platformu" : "E Signature Platform",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "ITSM Platformu" : "ITSM Platform",
                    key : uuidv1()
                },
                {
                    link : "/corporate-demands",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Proje Yönetim Platformu" : "Project Management Platform",
                    key : uuidv1()
                }
            ]
        },{
            mainIconClass : "fas fa-dice-d6",
            content : GetSystemLanguage() == "Tr" ? "Kütüphane" : "Library",
            key : 5,
            children : [
                {
                    link : "/corporate-assets",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Kurumsal Şablonlarımız" : "Corporate Templates",
                    key : uuidv1()
                },
                {
                    link : "/corporate-assets",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Kurumsal Resim ve Videolarımız" : "Corporate Pictures and Videos",
                    key : uuidv1()
                },
                {
                    link : "/corporate-assets",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Kalite Dökümanlarımız" : "Quality Documents",
                    key : uuidv1()
                },
                {
                    link : "/corporate-assets",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Ürün ve Hizmetlerimiz" : "Products and Services",
                    key : uuidv1()
                },
                {
                    link : "/corporate-assets",
                    childIconClass : "fas fa-dice-d6",
                    content : GetSystemLanguage() == "Tr" ? "Eğitim Kütüphanesi" : "Education Library",
                    key : uuidv1()
                }
            ]
        }
    ];
    return menuItems;
}


//Sık arananlar bilgisini dönen servis
export function GetMyTasks():BusinessResponseType<any> {
    // callApi({
    //     url: "User/GetMyWaitingJobs",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setMyTasks(res.data.result));
    //         }
    //     }
    // })
     return {
         isSuccessful : true,
         result : {
            "totalCount" : 10,
                "jobs" :[
                    {
                        "title": "İzin Talebi",
                        "count": 3,
                        "description" : "Bekleyen izin talebiniz bulunmaktadır!",
                        "url" : "/corporate-demands?tab=7"
                    },                    
                    {
                        "title": "Seyahat Talebi",
                        "count": 5,
                        "description" : "Bekleyen seyahat talebiniz bulunmaktadır!",
                        "url" : "/corporate-demands?tab=7"
                    },
                    {
                        "title": "Masraf Talebi",
                        "count": 2,
                        "description" : "Bekleyen masraf talebiniz bulunmaktadır!",
                        "url" : "/corporate-demands?tab=7"
                    }
                ]
         }
     };
  }
  