import { Button, Card, Col, Popconfirm, Row, Table, Tooltip } from "antd"
import Column from "antd/lib/table/Column"
import { ESPIPE } from "constants"
import React from "react"
import { GetResourceData } from "../helpers/basemethods"
import { VacationRequestType } from "../helpers/constantData"
import { CheckSquareOutlined, CloseSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { isMobile } from "react-device-detect";

export interface TaskTableProps {
    _isStateOpen:boolean
    _myTasksContent:any
    _requestDetail:any
    _approveRequest?:any
    _declineRequest?:any
} 
const TaskTable: React.FC<TaskTableProps> = (props) => {    

    const myTasksContent = props._myTasksContent;

    return ( 
    <React.Fragment>
        {
            isMobile && myTasksContent &&
            <>
                {
                    myTasksContent.data?.filter(x=> x.isActive == props._isStateOpen ).map((element,i) => {
                        return(
                            <Card className="boardIdeaCard">
                                <Row>
                                                                            
                                    {/* <Col xs={24} sm={24}>
                                    <strong>{GetResourceData("RequestNo")}</strong> 
                                    </Col>                                           
                                    <Col xs={24} sm={24}>
                                    {"Req-" + element.id}
                                    </Col>                                      */}
                                    <Col xs={24} sm={24}>
                                    <strong>{GetResourceData("RequestType")}</strong> 
                                    </Col>                                            
                                    <Col xs={24} sm={24}>
                                    {element.typeDesc}
                                    </Col>    
                                    <Col xs={24} sm={24}>
                                    <strong>{GetResourceData("RequestDate")}</strong> 
                                    </Col>                                            
                                    <Col xs={24} sm={24}>
                                    {element.date}
                                    </Col>                                    
                                    <Col xs={24} sm={24}>
                                    <strong>{GetResourceData("Description")}</strong> 
                                    </Col>                                            
                                    <Col xs={24} sm={24}>
                                    {element.desc}
                                    </Col>                                     
                                    <Col xs={24} sm={24}>
                                    <strong>{GetResourceData("State")}</strong>  
                                    </Col>                                                                              
                                    <Col xs={24} sm={24}>
                                    {element.state + (element.activeApproveManager ? " (" + element.activeApproveManager + ")" : "")}
                                    </Col>
                                    <Col xs={24} sm={24}>
                                    <div style={{display:"flex", justifyContent:"flex-start", columnGap:"10px"}}>
                                        <Button disabled={element.type === VacationRequestType} onClick={() => { props._requestDetail(element.id); }} type='primary'>{GetResourceData("Detail")}</Button>
                                        {
                                            props._isStateOpen &&
                                            <div style={{display:"flex", justifyContent:"flex-start", columnGap:"10px"}}>                                            
                                            <Tooltip title={GetResourceData("Onayla")}>
                                                <Popconfirm
                                                    title={GetResourceData("ConfirmRequest")}
                                                    onConfirm={() => props._declineRequest(element.id)}
                                                    onCancel={() => { } }
                                                    okText={GetResourceData("Yes")}                                           
                                                    cancelText={GetResourceData("No")}
                                                    disabled={element.type === VacationRequestType}
                                                >
                                                    <Button disabled={element.type === VacationRequestType} id='btnTaskApprove' icon={<CheckSquareOutlined />} type='primary' />
                                                </Popconfirm>
                                            </Tooltip>
        
                                            <Tooltip title={GetResourceData("Reject")}>
                                                <Popconfirm
                                                    title={GetResourceData("RejectRequest")}
                                                    onConfirm={() => props._approveRequest(element.id)}
                                                    onCancel={() => { } }
                                                    okText={GetResourceData("Yes")}                                           
                                                    cancelText={GetResourceData("No")}
                                                    disabled={element.type === VacationRequestType}
                                                >
                                                    <Button disabled={element.type === VacationRequestType} id='btnTaskReject' icon={<CloseSquareOutlined />} type='primary' />
                                                </Popconfirm>
                                            </Tooltip>
                                            </div>                                     
                                        }
                                    </div>                                                   
                                    </Col> 
                                </Row>
                            </Card>
                        
                    )
                    })
                }
            </>
        }
        {
            !isMobile &&
            <>
                 <Table pagination={{pageSize:5}} dataSource={myTasksContent?.data?.filter(x=> x.isActive)}>
                        {/* <Column title={GetResourceData("RequestNo")} dataIndex="id" key="requestNo" render={(val) => { return("Req-" + val)}} /> */}
                        <Column title={GetResourceData("RequestType")} dataIndex="typeDesc" key="typeDesc" />
                        <Column title={GetResourceData("RequestDate")} dataIndex="date" key="date" />
                        <Column title={GetResourceData("Description")} dataIndex="desc" key="desc" />
                        <Column title={GetResourceData("State")} key="state" render={(item) => {return( item.state + (item.activeApproveManager ? " (" + item.activeApproveManager + ")" : ""));}} />
                        <Column title={GetResourceData("Detail")} key="detail" render={(item) => {
                            if (props._isStateOpen) {
                                return (                                
                                    <div style={{display:"flex", justifyContent:"flex-start", columnGap:"10px"}}>
                                        <Button disabled={item.type === VacationRequestType} onClick={() => { props._requestDetail(item.id); }} type='primary'>{GetResourceData("Detail")}</Button>
                                        
                                        <Tooltip title={GetResourceData("Onayla")}>
                                            <Popconfirm
                                                title={GetResourceData("ConfirmRequest")}
                                                onConfirm={() => props._declineRequest(item.id)}
                                                onCancel={() => { } }
                                                okText={GetResourceData("Yes")}                                           
                                                cancelText={GetResourceData("No")}
                                                disabled={item.type === VacationRequestType}
                                            >
                                                <Button disabled={item.type === VacationRequestType} id='btnTaskApprove' icon={<CheckSquareOutlined />} type='primary' />
                                            </Popconfirm>
                                        </Tooltip>
    
                                        <Tooltip title={GetResourceData("Reject")}>
                                            <Popconfirm
                                                title={GetResourceData("RejectRequest")}
                                                onConfirm={() => props._approveRequest(item.id)}
                                                onCancel={() => { } }
                                                okText={GetResourceData("Yes")}                                           
                                                cancelText={GetResourceData("No")}
                                                disabled={item.type === VacationRequestType}
                                            >
                                                <Button disabled={item.type === VacationRequestType} id='btnTaskReject' icon={<CloseSquareOutlined />} type='primary' />
                                            </Popconfirm>
                                        </Tooltip>
                                        
    
                                    </div>
                                );
                            }
                            else{
                                return (
                                <Button 
                                disabled={item.type === VacationRequestType} 
                                onClick={() => {props._requestDetail(item.id);}} type='primary'>{GetResourceData("Detail")}
                                </Button>);
                            }
                        }} />
                    </Table>
            </>
        }
    </React.Fragment>
    )
}

export default TaskTable;