import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { Route, Router, Switch } from 'react-router-dom';
import Routes from './routes';
import history from './history'
import 'antd/dist/antd.css'
import './assets/styles/app.scss';
import 'moment/locale/tr';
import trTR from 'antd/es/locale/tr_TR';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from './state';
import { getIsDarkMode, getSysLang } from './state/reducers/CommonReducer';

function App() {

  const dispatch = useDispatch()
  const { isDarkMode , sysLang } = useSelector((s: Store) => s.common);
  useEffect(() => { if (!isDarkMode) { dispatch(getIsDarkMode()) } }, [isDarkMode]);
  useEffect(() => { if (!sysLang) { dispatch(getSysLang()) } }, [sysLang]);
  
  const setStyleProps= () =>{
    let bgColor = isDarkMode ? "rgb(21,32,43)" : "#fff";
    let hiddenColor = isDarkMode ? "#fff" : "black";
    let hiddenSecondColor = isDarkMode ? "#fff" : "rgb(67, 65, 65)";
    let demandColor = isDarkMode ? "#577b94" : "rgb(240 244 247)"
    document.documentElement.style.backgroundColor = bgColor;  
    document.documentElement.style.setProperty('--hidden-popup-background-color', bgColor);
    document.documentElement.style.setProperty('--hidden-popup-color', hiddenColor);
    document.documentElement.style.setProperty('--hidden-popup-secondary-color', hiddenSecondColor);
    document.documentElement.style.setProperty('--primary-demand-color', demandColor);
  }
  
  setStyleProps();  

  return (
    <ConfigProvider locale={trTR}>

      <div className={isDarkMode ? 'App darkTheme' : 'App'}>
      
        <Router history={history}>
          <Switch>
            <Routes />
          </Switch>

        </Router>
        
      </div>
    </ConfigProvider>
  );
}

export default App;
