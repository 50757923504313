import { useSelector } from "react-redux";
import { Store } from "../state";
import { getSysLang } from "../state/reducers/CommonReducer";
import { useEffect } from "react";
import { GetOperationResourceData } from "./basemethods";


export const formModes = {
    NEW: 'NEW',
    EDIT: 'EDIT'
}

export const methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
}


export const actionTypes = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
}

export const contentTypes = {
    multipartFormData: 'multipart/form-data',
    applicationJson: 'application/json'
}

export enum NotifierResultType  {
    success = "success",
    error = "error",
    info = "info",
    warning = "warning"
}

export enum NotifierHeaderInfoType  {
    notifierSuccess = "notifierSuccess",
    notifierError = "notifierError",
    notifierInfo = "notifierInfo",
    notifierWarning = "notifierWarning"
}

function dispatch(arg0: (dispatch: import("redux-thunk").ThunkDispatch<{ auth: import("../state/reducers/AuthReducer").AuthReducerType; common: import("../state/reducers/CommonReducer").CommonReducerType; menu: import("../state/reducers/MenuReducer").MenuReducerType; homepage: import("../state/reducers/HomePageReducer").HomePageReducerType; }, undefined, import("redux").AnyAction> & import("redux").Dispatch<import("redux").AnyAction>) => Promise<void>) {
    throw new Error("Function not implemented.");
}
