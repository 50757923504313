import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { GetAllLinkCategoriesWithLinks } from "../../business/Public/HeaderBusiness";
import { callApi } from "../../helpers/service";
import { AxiosResponse } from "axios";
import { GetSystemLanguage } from "../../helpers/basemethods";
import { LanguageTr } from "../../helpers/resources";
import { urlGetAllContentCategoriesForUI } from "../../helpers/apiUrls";


export type MenuReducerType = {
    links: any | null;
    mainMenuContext: any | null;
    menuCalled: boolean;

}

const initialState: MenuReducerType = {
    links: null,
    mainMenuContext: null,
    menuCalled: false
}

const menuReducer = createSlice({
    name: "menu",
    initialState,
    reducers: {
        setLinks: (state, action) => void (state.links = action.payload),
        setMainMenuContext: (state, action) => void (state.mainMenuContext = action.payload),
        setMenuCalled: (state, action) => void (state.menuCalled = action.payload),

    }
});

export const {
    setLinks,
    setMainMenuContext,
    setMenuCalled
} = menuReducer.actions;




export const getLinks = () => async (dispatch: AppDispatch) => {
    let apiCall = GetAllLinkCategoriesWithLinks();
    if (apiCall.isSuccessful) {      
        dispatch(setLinks(apiCall.result));
    }
};




export const getMainMenu = () => async (dispatch: AppDispatch) => {
    callApi({
        url: urlGetAllContentCategoriesForUI,
        useToken: true,
        successCallback: (res: AxiosResponse<any>) => {
            if (res.status === 200) {
                dispatch(setMainMenuContext(
                    {
                        "lang" : LanguageTr,
                        "data" : res.data.data
                    }          
                ));    
            }
        },
        errorCallback: (e) => {  
        },
        finish: () =>{           
            setMenuCalled(false); 
        }});
    
    
};


export default menuReducer.reducer;