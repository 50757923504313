import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Breadcrumb } from '../components';
import history from '../history';
import { setSelectedMenu } from '../state/reducers/CommonReducer';
import { roles } from './root';
import { PageHeader } from 'antd';
const env = process.env;

const adminPathPrefix = "/alfa";

export const menu = [
  { name: 'UnAuthorizedPage', componentName: 'UnAuthorizedPage', val: 0, text: 'Yetkiniz Bulunmuyor', path: '/unauthorized', icon: 'user-add', show: false, roles: ["User"] },
  { name: 'Home', componentName: 'Home', val: 1, text: 'Home', path: '/', icon: 'home', show: false, roles: ["User"] },
  { name: 'ProffesionPageDetail', componentName: 'ProffesionPageDetail', val: 221, text: 'Detay', path: '/carrier-path/profession/detail', parent: "ProffesionPage", icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'SearchResult', componentName: 'SearchResult', val: 33, text: 'Arama Sonucu', path: `/search-result`, icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'Content', componentName: 'Content', val: 34, text: 'İçerik', path: `/content`, icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'ProfileDetail', componentName: 'ProfileDetail', val: 35, text: 'Kişi Profili', path: `/profile/:id`, icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'LiveBroadcast', componentName: 'LiveBroadcast', val: 37, text: 'Canlı Yayın', path: `/live-broadcast`, icon: 'bullhorn', show: false, roles: ["User"], hasChild: false },
  { name: 'CategoryVideos', componentName: 'CategoryVideos', val: 38, text: 'Videolar', path: `/category-videos`, icon: 'user-friends', show: false, roles: ["User"], hasChild: false },
  { name: 'CorporateAssets', componentName: 'CorporateAssets', val: 39, text: 'Kurumsal Dokümanlar', path: `/corporate-assets`, icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'CorporateDemands', componentName: 'CorporateDemands', val: 40, text: 'Kurumsal Talepler', path: `/corporate-demands`, icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'NewsFromUs', componentName: 'NewsFromUs', val: 41, text: 'Bizden Haberler', path: `/news-from-us/:id`, icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'MyProfile', componentName: 'MyProfile', val: 42, text: 'Profilim', path: `/my-profile`, icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'Ideas', componentName: 'Ideas', val: 43, text: 'Fikirler', path: `/ideas`, icon: 'user-graduate', show: false, roles: ["User"] },
  { name: 'TaskTracking', componentName: 'TaskTracking', val: 44, text: 'Görev Takip', path: `/task-tracking`, icon: 'user-graduate', show: false, roles: ["User"] },

  /**Admin */
  { name: 'AdminDashboard', componentName: 'AdminDashboard', val: 1000, text: 'Dashboard', path: adminPathPrefix, icon: 'tachometer-alt-fast', show: true, roles: ["Admin", "HR"] },
  { name: 'BannerManagement', componentName: 'BannerManagement', val: 1006, text: 'Banner Yönetimi', path: `${adminPathPrefix}/banner-management`, icon: 'conveyor-belt-alt', show: true, roles: [roles.ADMIN, roles.HR] },
  { name: 'BannerManagementDetail', componentName: 'BannerManagementDetail', val: 10061, text: 'Banner Yönetimi Detay', path: `${adminPathPrefix}/banner-management/detail`, icon: 'conveyor-belt-alt', show: false, roles: [roles.ADMIN, roles.HR] },
  { name: 'BannerManagementNew', componentName: 'BannerManagementNew', val: 10062, text: 'Banner Yönetimi Yeni', path: `${adminPathPrefix}/banner-management/new`, icon: 'conveyor-belt-alt', show: false, roles: [roles.ADMIN, roles.HR] },
  { name: 'RoleManagement', componentName: 'RoleManagement', val: 1007, text: 'Rol Yönetimi', path: `${adminPathPrefix}/role-management`, icon: 'user-cog', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'GroupManagement', componentName: 'GroupManagement', val: 1008, text: 'Grup Yönetimi', path: `${adminPathPrefix}/group-management`, icon: 'user-friends', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'LinkManagement', componentName: 'LinkManagement', val: 1009, text: 'Link Yönetimi', path: `${adminPathPrefix}/link-management`, icon: 'link', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'MealMenuManagement', componentName: 'MealMenuManagement', val: 1010, text: 'Yemek Menüsü', path: `${adminPathPrefix}/meal-menu-management`, icon: 'cookie', show: true, roles: [roles.ADMIN], hasChild: false },

  { name: 'ContentCategoryList', componentName: 'ContentCategoryList', val: 1011, text: 'İçerik Yönetimi', path: `${adminPathPrefix}/content-management`, icon: 'align-left', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'ContentCategoryNew', componentName: 'ContentCategoryForm', val: 1012, text: 'İçerik Kategorisi Ekle', path: `${adminPathPrefix}/content-management/new`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },
  { name: 'ContentCategoryEdit', componentName: 'ContentCategoryForm', val: 1013, text: 'İçerik Kategorisi Düzenle', path: `${adminPathPrefix}/content-management/edit`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },

  { name: 'ContentList', componentName: 'ContentList', val: 1014, text: 'İçerik Listesi', path: `${adminPathPrefix}/content-management/contents`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },
  { name: 'ContentNew', componentName: 'ContentNew', val: 1015, text: 'İçerik Ekle', path: `${adminPathPrefix}/content-management/contents/new`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },
  { name: 'ContentEdit', componentName: 'ContentEdit', val: 1016, text: 'İçerik Düzenle', path: `${adminPathPrefix}/content-management/contents/edit`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },

  { name: 'FAQList', componentName: 'FAQList', val: 1023, text: 'S.S.S Listesi', path: `${adminPathPrefix}/content-management/contents/faq`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },
  { name: 'FAQNew', componentName: 'FAQNew', val: 1024, text: 'S.S.S Ekle', path: `${adminPathPrefix}/content-management/contents/faq/new`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },
  { name: 'FAQEdit', componentName: 'FAQEdit', val: 1025, text: 'S.S.S Düzenle', path: `${adminPathPrefix}/content-management/contents/faq/edit`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },

  { name: 'EventCalendar', componentName: 'EventCalendar', val: 1017, text: 'Etkinlik Takvimi Yönetimi', path: `${adminPathPrefix}/event-calendar`, icon: 'calendar-alt', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'EventCalendarNew', componentName: 'EventCalendarNew', val: 1018, text: 'Etkinlik Ekle', path: `${adminPathPrefix}/event-calendar/new`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },
  { name: 'EventCalendarEdit', componentName: 'EventCalendarEdit', val: 1019, text: 'Etkinlik Düzenle', path: `${adminPathPrefix}/event-calendar/edit`, icon: 'user-friends', show: false, roles: [roles.ADMIN], hasChild: false },

  { name: 'FileManagement', componentName: 'FileManagement', val: 1026, text: 'Dosya Yöneticisi', path: `${adminPathPrefix}/file-management`, icon: 'folder', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'FileManagement', componentName: 'FileManagement', val: 1101, text: 'Kurumsal Doküman Yönetimi', path: `${adminPathPrefix}/file-management`, icon: 'folders', show: true, roles: [roles.ADMIN], hasChild: false },

  { name: 'PopupManagement', componentName: 'PopupManagement', val: 1027, text: 'Popup Yönetimi', path: `${adminPathPrefix}/popup-management`, icon: 'bullhorn', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'PopupManagementNew', componentName: 'PopupManagementNew', val: 1028, text: 'Popup Ekle', path: `${adminPathPrefix}/popup-management/new`, icon: 'bullhorn', show: false, roles: [roles.ADMIN], hasChild: false },
  { name: 'PopupManagementEdit', componentName: 'PopupManagementEdit', val: 1029, text: 'Popup Düzenle', path: `${adminPathPrefix}/popup-management/edit`, icon: 'bullhorn', show: false, roles: [roles.ADMIN], hasChild: false },
  
  { name: 'CameraManagement', componentName: 'CameraManagement', val: 1030, text: 'Kamera Yönetimi', path: `${adminPathPrefix}/camera-management`, icon: 'video', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'CameraManagementNew', componentName: 'CameraManagementNew', val: 1031, text: 'Kamera Ekle', path: `${adminPathPrefix}/camera-management/new`, icon: 'bullhorn', show: false, roles: [roles.ADMIN], hasChild: false },
  { name: 'CameraManagementEdit', componentName: 'CameraManagementEdit', val: 1032, text: 'Kamera Düzenle', path: `${adminPathPrefix}/camera-management/edit`, icon: 'bullhorn', show: false, roles: [roles.ADMIN], hasChild: false },

  { name: 'ThanksManagement', componentName: 'ThanksManagement', val: 1033, text: 'Teşekkür Yönetimi', path: `${adminPathPrefix}/thanks`, icon: 'comment-alt-smile', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'ThanksManagementDetail', componentName: 'ThanksManagementDetail', val: 1034, text: 'Teşekkür Detayı', path: `${adminPathPrefix}/thanks/detail`, icon: 'bullhorn', show: false, roles: [roles.ADMIN], hasChild: false },


  { name: 'ThanksManagement', componentName: 'ThanksManagement', val: 1108, text: 'Fikir Yönetimi', path: `${adminPathPrefix}`, icon: 'lightbulb-on', show: true, roles: [roles.ADMIN], hasChild: false },

  { name: 'ThanksManagement', componentName: 'ThanksManagement', val: 1109, text: 'Haber Yönetimi', path: `${adminPathPrefix}`, icon: 'newspaper', show: true, roles: [roles.ADMIN], hasChild: false },
  { name: 'ThanksManagement', componentName: 'ThanksManagement', val: 1111, text: 'Anket Yönetimi', path: `${adminPathPrefix}`, icon: 'poll-h', show: true, roles: [roles.ADMIN], hasChild: false },

  // { name: 'ServiceManagement', componentName: 'ServiceManagement', val: 1035, text: 'Servisler', path: `${adminPathPrefix}/services`, icon: 'play', show: true, roles: [roles.ADMIN], hasChild: false },
  

];

export const setWindowTitle = (text) => {
  document.title = `${text} | ${env.REACT_APP_TITLE}`;
}

export const useSetMenu = (menuName, titleSuffix, showBreadcrump, subTitle, extra, footer) => {


  const dispatch = useDispatch();
  const location = useLocation();

  const selectedMenu = getMenuItem(menuName);

  let val = _.has(selectedMenu, 'val') ? selectedMenu.val : 0;
  //let patToGoBack = getMenuItem(menu[0].name).path;

  if (_.has('selectedMenu', 'parent')) {
    const parentMenuItem = getMenuItem(selectedMenu.parent);
    val = parentMenuItem.val;
    //patToGoBack = parentMenuItem.path;
  }

  useEffect(() => {
    if (val) {
      dispatch(setSelectedMenu(selectedMenu.val));
      setWindowTitle(selectedMenu.text);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu, val]);

  return (
    <div className="page-header-wrapper">
      <PageHeader
        // breadcrumb={<Breadcrumb />}
        // breadcrumbRender={() =>  <Breadcrumb location={location} title={titleSuffix} /> }
        onBack={() => history.goBack()}
        title={`${(titleSuffix ?? titleSuffix)}`} className='page-header'
        subTitle={subTitle}
        extra={extra}
        footer={footer}
      />
      {showBreadcrump && <Breadcrumb location={location} title={titleSuffix} />}
    </div>
  )

};

export const getMenuItem = (name: string | null = '', url: string | null = '') => {

  if (name)
    return menu.filter(f => f.name === name)[0];

  if (url)
    return menu.filter(f => f.path === url)[0];

  return menu[0];
}
