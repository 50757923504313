import React, { useEffect, useState, useRef } from "react";
import { PageBase } from "../../../components";
import UserDetail from "../../../components/UserDetail";
import { GetResourceData } from "../../../helpers/basemethods";
import { Tabs, Table, Button, TabsProps, Badge } from "antd";
import Column from "antd/lib/table/Column";
import TabPane from "antd/lib/tabs/TabPane";
import { useSelector } from "react-redux";
import { Store } from "../../../state";
import MyIdeas from "../../../components/MyIdeas";
import MySurveys from "../../../components/MySurveys";
import { isMobile } from "react-device-detect";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import TaskTrackingTaskCalendar from "../../../components/TaskTrackingTaskCalendar";
import TaskTrackingMyTasks from "../../../components/TaskTrackingMyTasks";

export interface TaskTrackingProps { }

const TaskTracking: React.FC<TaskTrackingProps> = () => {
    const [PageLoading, setPageLoading] = useState<boolean>(false)
    const [UserDetails, setUserDetails] = useState<any>(null)
    const { currentUser } = useSelector((s: Store) => s.auth);
    const [activeTab, setActiveTab] = useState<string>("1");

 
    useEffect(() => {
        if (currentUser) {
            setUserDetails(currentUser)
        }
    }, [0]);

    


    const myTaskAgenda = () => {
      return (
          <span><i className="fal  fa-calendar"></i> Görev Takvimim</span>
      )
    }

    const myTasks = () => {
      return (
        <Badge size="small" count={2}>
          <span><i className="fal fa-tasks"></i> Görevlerim</span>
        </Badge>
      )
    }

    const myTeamsTasks = () => {
      return (
        <Badge size="small" count={3}>
          <span><i className="fal fa-clipboard-list"></i> Ekibimin Görevleri</span>
        </Badge>
      )
    }

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: myTaskAgenda(),
          children: <TaskTrackingTaskCalendar isMyTeam={false} />
        },
        {
          key: '2',
          label: myTasks(),
          children: <TaskTrackingMyTasks isMyTeam={false}/>,
        },
        {
          key: '3',
          label: myTeamsTasks(),
          children: <TaskTrackingMyTasks isMyTeam={true}/>,
        }
      ];
      

    const onChangeTab = (key) => {
        setActiveTab(key);
      };
  
      const activeKeyLeft = () => {
        let _activeKeyNum = parseInt(activeTab)
        if(_activeKeyNum>1){
          setActiveTab((_activeKeyNum-1).toString())
        }
      }
  
      const activeKeyRight = () => {
        let _activeKeyNum = parseInt(activeTab)
        if(_activeKeyNum<items.length){
          setActiveTab((_activeKeyNum+1).toString())
        }
      }

    return (
        <PageBase menuName={"MyProfile"} className="content-detail" loading={PageLoading}>
        
            <>
               
                <div className="intranet-content-card corporate-demand profile-demands">
                    <div className="intranet-content-card-header">
                    <div className="title">
                        <h2>Görev Yönetimi</h2>
                    </div>
                    </div>
                    <div className="intranet-content-card-body corporateBody">
                    {
                    isMobile &&
                    <>
                        <a onClick={()=>{ activeKeyLeft() }}><div className="corporateButtons left" ><LeftOutlined /></div></a>
                        <a onClick={()=>{ activeKeyRight() }}><div className="corporateButtons right"><RightOutlined /></div></a>
                    </>
                    }
                    <Tabs
                        defaultActiveKey="1" 
                        activeKey={activeTab}
                        onChange={onChangeTab}
                        type="card"
                        tabPosition="top"
                        items={items}
                    />
                    </div>
                </div>     
            </>
                
                                 
        </PageBase>
    );
};

export default TaskTracking;
