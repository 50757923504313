import { Button, Form, FormInstance, Input, List, Modal, Radio, Row } from "antd"
import { showNoti } from "../helpers/root";
import { NotifierHeaderInfoType, NotifierResultType } from "../helpers/enums";
import { GetOperationResourceData, GetResourceData, GetSystemLanguage } from "../helpers/basemethods";
import React, { useEffect, useState } from "react";
import { EmptySurveyExample , AnsweredSurveyExample, DemoSurveyExampleEmpty, DemoSurveyExampleAnswered, EmptyWeekData, WeekConstantData } from "../helpers/constantData";
import { SubmitButton } from "./SubmitButton";
import Col from "antd/es/grid/col";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../state";
import { setIsSurveyAnswered } from "../state/reducers/CommonReducer";
import { Calendar, Views, momentLocalizer, } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment'
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { testApiCall } from "../business/Public/CommonBusiness";
import { setActivities, setActivitiesCalling } from "../state/reducers/HomePageReducer";
import { ApiTestResults } from "../helpers/testApiResponses";


const localizer = momentLocalizer(moment) // or globalizeLocalizer

export interface TaskTrackingTaskCalendarProps {
    isMyTeam : boolean
}
const TaskTrackingTaskCalendar: React.FC<TaskTrackingTaskCalendarProps> = (props) => {
    const dispatch = useDispatch();

    const [CalendarData, setCalendarData] = useState<any>([])
    
    const { taskTrackingMyTasks  } = useSelector((s: Store) => s.demand);
 
    useEffect(() => {
        let result =  ApiTestResults(props.isMyTeam ? "tasksMyTeam" : "tasks")
        setCalendarData(result);
    }, [0]);

  //ay değiştirdiğinde çağrılan metod
  const onNavigate = async (e) => {
    let params = {
      startDate: moment(e).startOf('month').format("YYYY-MM-DDT00:00:00"),
      endDate: moment(e).endOf('month').format("YYYY-MM-DDT23:59:00"),
    }

    let result = await testApiCall("tasks")
    setCalendarData(result);

  }

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };
    const label = () => {
      const date = moment(toolbar.date);
      return (`${date.format('MMMM')} ${date.format('YYYY')}`);
    };

    return (
      <div className="calendar-toolbar-container">
        <div className="calendar-toolbar">
          <div className="left">
            <button onClick={goToBack}><LeftOutlined /></button>
          </div>
          <div className="center">{label()}</div>
          <div className="right">
            <button onClick={goToNext}><RightOutlined /></button>
          </div>
        </div>

      </div >
    );
  };
  const EventComponent = ({ event }) => {
    return (
      <span>
        <strong>{event.owner}</strong>
        <p>{event.title}</p>
      </span>
    );
  };

  const eventPropGetter = (event) => {
    const color = event.color;
    var style = {
      border: '2px solid',
      borderColor: color,
      backgroundColor : "white",
      borderRadius: '10px',
      color: 'black',
      display: 'block'
    };    
    return {style: style
    };
  };

    return (
        <React.Fragment>          
          <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Calendar
            events={CalendarData}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "600px" }}
            views={[Views.MONTH]}
            defaultView={[Views.MONTH]}
            defaultDate={new Date()}
            culture={GetSystemLanguage() == "Tr" ? "tr" : "en"}
            locale={GetSystemLanguage() == "Tr" ? "tr_TR" : "en_US"}
            popup
            eventPropGetter={eventPropGetter}
            components={props.isMyTeam ? {
              toolbar: CustomToolbar,
              event : EventComponent
            } : { toolbar: CustomToolbar } }
            onNavigate={onNavigate}

          />
            </Col>
          </Row>
           
        </React.Fragment>
        

    )
}

export default TaskTrackingTaskCalendar;
            