import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../state';
import {  AutoComplete, Button, Card, Checkbox, Col, Collapse, DatePicker, Divider, Form, Input, Modal, Popconfirm, Row,  Select,  Space,  Table, Tabs,  TabsProps,  Tooltip, Upload } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import Column from 'antd/lib/table/Column';
import { GetResourceData } from '../helpers/basemethods';
import { getMyDemandContent, getTaskTrackingMyTasks, getTaskTrackingMyTeamTasks, setMyDemandContent } from '../state/reducers/DemandReducer';
import { CostRequestType, DayOffRequestType, emptyGuid, VacationRequestType } from '../helpers/constantData';
import RequestTable from './RequestTable';
import { isMobile } from 'react-device-detect';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import TaskTrackingTable from './TaskTrackingTable';
import {UploadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import TaskTrackingTaskCalendar from './TaskTrackingTaskCalendar';
import { GetSearchDelegatedResult } from '../business/Public/CommonBusiness';
import TextArea from 'antd/lib/input/TextArea';
import TaskTrackingAddNewTask from './TaskTrackingAddNewTask';

export interface TaskTrackingMyTasksProps { isMyTeam : boolean }
const TaskTrackingMyTasks: React.FC<TaskTrackingMyTasksProps> = (props) => {
    
    const uuidv1 = require("uuid/v1");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false)

    const [showNewItem, setShowNewItem] = useState<any>(null)
    const [showItemDetail, SetShowItemDetail] = useState<any>(null)
    const [activeTab, setActiveTab] = useState<string>(props.isMyTeam ? "1" : "2");
    const { taskTrackingMyTeamTasks, taskTrackingMyTasks  } = useSelector((s: Store) => s.demand);
    const [subTaskDataSource, setSubTaskDataSource] = useState<any[]>(null)    
    const [subTaskCounter, setSubTaskCounter] = useState<number>(0);    
    const [newSubTaskDesc, setNewSubTaskDesc] = useState<string>("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [SearchResults, setSearchResults] = useState<any[]>([])
    const [SearchLoading, setSearchLoading] = useState<boolean>(false);
    const [SearchUser, setSearchUser] = useState<string>("");
    const [delegatedUser, setDelegatedUser] = useState<string>("");

    const [form] = Form.useForm();

    useEffect(() => {  
      if(props.isMyTeam){
        if (!taskTrackingMyTeamTasks) {
          dispatch(getTaskTrackingMyTeamTasks());
         }
      } 
      else{
        if (!taskTrackingMyTasks) {
          dispatch(getTaskTrackingMyTasks());
         }
      }   
       
      }, []);

    const requestDetail = async (id) => {
        await getItemDetail(id)
        .then((response) => {            
            SetShowItemDetail(response);
            let subTaskDb = [];
            let taskCounter = 0;
            response.subTaskList.forEach(task => {
              subTaskDb.push({
                "subTaskGuid" : task.subTaskGuid,
                "subTaskDesc" : task.subTaskDesc,
                "subTaskState" : task.subTaskState,
                "subTaskIndex" : taskCounter++
              });
              
            });
            setSubTaskCounter(taskCounter)
            setSubTaskDataSource(subTaskDb)
        })
        .catch((error) => {
            SetShowItemDetail(null)  
            setSubTaskDataSource(null)
            setSubTaskCounter(0)
        });          
    }

    async function getItemDetail(id)  {
        let obj = props.isMyTeam ? taskTrackingMyTeamTasks.data.filter(x=> x.taskId == id)[0] : taskTrackingMyTasks.data.filter(x=> x.taskId == id)[0];
        return obj;
    }

    const CancelRequest = (itemId) => {
        dispatch(setMyDemandContent({
            "lang" : props.isMyTeam ? taskTrackingMyTeamTasks.lang : taskTrackingMyTasks.lang,
            "data" : props.isMyTeam ? taskTrackingMyTeamTasks.data.filter(x=> x.id != itemId) : taskTrackingMyTasks.data.filter(x=> x.id != itemId)
        }))
    };


    const { Panel } = Collapse;
    const items: TabsProps['items'] = [
        props.isMyTeam &&
        {
          key: '1',
          label: <>Ekibimin Görev Takvimi</> ,
          children: <TaskTrackingTaskCalendar isMyTeam={true} />
        },
        {
          key: '2',
          label: <>{props.isMyTeam ? "Açık Görevler" : "Açık Görevlerim"}</> ,
          children: <TaskTrackingTable _myTeam={props.isMyTeam} _isStateOpen={true} _myDemandContent={props.isMyTeam ? taskTrackingMyTeamTasks : taskTrackingMyTasks} _requestDetail={requestDetail} _cancelRequest={CancelRequest} />
        },
        {
          key: '3',
          label: <>{props.isMyTeam ? "Kapalı Görevler" : "Kapalı Görevlerim"}</> ,
          children: <TaskTrackingTable _myTeam={props.isMyTeam} _isStateOpen={false} _myDemandContent={props.isMyTeam ? taskTrackingMyTeamTasks : taskTrackingMyTasks} _requestDetail={requestDetail}  />
        }
        
      ];

      const onChangeTab = (key) => {
        setActiveTab(key);
      };
  
      const activeKeyLeft = () => {
        let _activeKeyNum = parseInt(activeTab)
        if(_activeKeyNum>1){
          setActiveTab((_activeKeyNum-1).toString())
        }
      }
  
      const activeKeyRight = () => {
        let _activeKeyNum = parseInt(activeTab)
        if(_activeKeyNum<items.length){
          setActiveTab((_activeKeyNum+1).toString())
        }
      }

      const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        const newData = {
          subTaskGuid: 'emptyGuid', // boş guid değeri
          subTaskDesc: newSubTaskDesc,
          subTaskState: false,
          subTaskIndex: subTaskCounter
        };
        setSubTaskDataSource([...subTaskDataSource, newData]);
        setSubTaskCounter(subTaskCounter + 1);
        setNewSubTaskDesc('');
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

      const handleSub = (index) => {
        let subTaskDb = [];
        subTaskDataSource.forEach(task => {
          if(task.subTaskIndex != index){
            subTaskDb.push({
              "subTaskGuid" : task.subTaskGuid,
              "subTaskDesc" : task.subTaskDesc,
              "subTaskState" : task.subTaskState,
              "subTaskIndex" :  task.subTaskIndex
            }); 
          }                   
        });
        setSubTaskDataSource(subTaskDb)
      }      

      const handleSubTaskChange = (val,index) => {
        let newVal = val.target.checked;
        let subTaskDb = [];
        subTaskDataSource.forEach(task => {
          subTaskDb.push({
            "subTaskGuid" : task.subTaskGuid,
            "subTaskDesc" : task.subTaskDesc,
            "subTaskState" : task.subTaskIndex == index ? newVal :task.subTaskState,
            "subTaskIndex" :  task.subTaskIndex
          });          
        });
        setSubTaskDataSource(subTaskDb)
      }

    return (
        <React.Fragment>    
            <>   
            {
                isMobile &&
                <>
                <a style={{display: parseInt(activeTab)==1 ? "none" : "block"}} onClick={()=>{ activeKeyLeft() }}><div className="corporateButtons left" ><LeftOutlined /></div></a>
                <a style={{display: parseInt(activeTab)==items.length ? "none" : "block"}} onClick={()=>{ activeKeyRight() }}><div className="corporateButtons right"><RightOutlined /></div></a>
                </>
            } 
            <Row>
              <Col style={{display:"flex", justifyContent:"flex-end"}} xl={24} lg={24} md={24} sm={24} xs={24}>
                <Button onClick={()=>{setShowNewItem(true)}} type="primary" style={{ marginBottom: 16 }}>
                 <PlusOutlined /> {props.isMyTeam ? "Ekibime Görev ekle" : "Yeni Görev Ekle"}
                </Button>
              </Col>
            </Row>    
            {            
                <Tabs
                    defaultActiveKey={props.isMyTeam ? "1" : "2"} 
                    activeKey={activeTab} 
                    onChange={onChangeTab}
                    type="card"
                    tabPosition="top"
                    items={items}
                />                
            }
            {
              showItemDetail &&
                 <Modal
                    width={"800px"}
                    open={showItemDetail}
                    onCancel={() => { SetShowItemDetail(null) }}
                    destroyOnClose
                    footer={null}
                    className='thanks-modal birthday-greeting detail-modal'
                >
                        <>
                          <Card type='inner' title="Görev Detayı" style={{height:"100%"}}>     
                                {
                                  props.isMyTeam && 
                                  <Row>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                      <span style={{fontWeight:"bold"}}>Görev Sahibi : </span>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                      <span>{showItemDetail.taskOwner}</span>
                                    </Col>
                                  </Row>   
                                }                            
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span style={{fontWeight:"bold"}}>Görev No : </span>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.taskId}</span>
                                    </Col>
                                </Row>                                 
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span style={{fontWeight:"bold"}}>Görev Adı </span>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.taskName}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span style={{fontWeight:"bold"}}>Görev Açıklama : </span>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.taskDesc}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span style={{fontWeight:"bold"}}>Görev Hedef Tarihi : </span>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <span>{showItemDetail.taskTargetDate}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span style={{fontWeight:"bold"}}>Statü : </span>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <span>{showItemDetail.state}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Collapse defaultActiveKey={"1"}>
                                            <Panel style={{fontSize:"11px"}} header="Alt Görevler" key="1">
                                            <div>
                                              <Button disabled={!showItemDetail.isActive} onClick={showModal} type="primary" style={{ marginBottom: 16 }}>
                                                Alt Görev Ekle
                                              </Button>
                                              <Table
                                                pagination={{
                                                  pageSize: 4,
                                                }}
                                                bordered
                                                dataSource={subTaskDataSource}>
                                                  <Column title={GetResourceData("State")} key="subTaskState" render={(item) => {
                                                      return (
                                                          <div style={{display:"flex", justifyContent:"flex-start", columnGap:"10px"}}>
                                                            <Checkbox disabled={!showItemDetail.isActive} key={uuidv1()} defaultChecked={item.subTaskState} onChange={(e) => handleSubTaskChange(e,item.subTaskIndex)} />
                                                          </div>
                                                      );}}/>
                                                  <Column title={GetResourceData("State")} key="subTaskDesc" dataIndex="subTaskDesc" />
                                                  {
                                                    showItemDetail.isActive && 
                                                    <Column  title={GetResourceData("Detail")} key="detail" render={(item) => {
                                                     
                                                      return (                                                        
                                                          <div style={{display:"flex", justifyContent:"flex-start", columnGap:"10px"}}>
                                                              
                                                          <Tooltip title={GetResourceData("CancelTask")}>
                                                              <Popconfirm
                                                                  title={GetResourceData("CancelTaskQuestion")}
                                                                  onConfirm={() => handleSub(item.subTaskIndex)}
                                                                  okText={GetResourceData("Yes")}                                            
                                                                  cancelText={GetResourceData("No")}
                                                                  disabled={item.type === VacationRequestType}
                                                              >
                                                                  <Button icon={<DeleteOutlined />} type='primary' danger />
                                                              </Popconfirm>

                                                          </Tooltip>
                                                              
                                                          </div>
                                                      );
                                                      }} />
                                                  }
                                                  
                                                </Table>
                                            </div>
                                                
                                            </Panel>                                 
                                        </Collapse>
                                    </Col>
                                </Row>
                                <Row>
                                  {
                                    showItemDetail.isActive &&
                                    <Button  onClick={() => { SetShowItemDetail(null) }} type="primary" style={{ marginBottom: 16, marginRight:10 }}>
                                      Kaydet
                                    </Button>
                                  }
                                  <Button disabled={!showItemDetail.isActive} onClick={() => { SetShowItemDetail(null) }} type="primary" style={{ marginBottom: 16 }}>
                                    {showItemDetail.isActive ? "İptal" : "Kapat"}
                                  </Button>
                                </Row>
                          </Card>
                        </>
                        
             </Modal>
            }
            {
              <Modal
                title="Alt görev açıklaması girin."
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Input 
                  value={newSubTaskDesc}
                  onChange={(e) => setNewSubTaskDesc(e.target.value)}
                />
              </Modal>
            }
            <TaskTrackingAddNewTask isMyTeam={props.isMyTeam} setShowNewItem={setShowNewItem} showNewItem={showNewItem} />
           </>
        </React.Fragment>
    );
}

export default TaskTrackingMyTasks;