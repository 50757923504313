import React from "react";
import { useQRCode } from 'next-qrcode';
import VCard from "vcard-creator";
interface IProps {
    fullName?: string;
    email?: string;
    telephoneNumber?: string;
    mobilPhoneNumber?: string;
    title?: string;
    width?: number,
    showLogo?: boolean
}
const QRGenerator: React.FC<IProps> = ({ fullName, title, email, telephoneNumber, mobilPhoneNumber, width }) => {

    if (!title) {
        title = ""
    }

    const { Image } = useQRCode();
    const myVCard = new VCard();
    // myVCard
    //     .addName(fullName)
    //     .addEmail(email ?? "")
    //     .addPhoneNumber(telephoneNumber?.replace(/\s/g, "") ?? "", "WORK")
    //     .addPhoneNumber(mobilPhoneNumber?.replace(/\s/g, "") ?? "", "PREF;WORK")
    //     .addURL("https://company.com", "Web Site")
    //     .addJobtitle(title)
    //     .addCompany("YOUR COMPANY")
    myVCard
        .addName("Volkan Seçginli")
        .addEmail("volkan.secginli@vnsteknoloji.com.tr")
        .addPhoneNumber("+90 532 210 12 10"?.replace(/\s/g, "") ?? "", "WORK")
        .addPhoneNumber("+90 532 210 12 10"?.replace(/\s/g, "") ?? "", "PREF;WORK")
        .addURL("https://vnsteknoloji.com.tr", "Web Site")
        .addJobtitle("MANAGING PARTNER")
        .addCompany("VNS TEKNOLOJİ")

    return (
        <Image
            text={myVCard.toString()}
            options={{
                type: 'image/jpeg',
                quality: 5,
                // errorCorrectionLevel: 'M',
                margin: 3,
                scale: 4,
                width: width ?? 160,
                // color: {
                //     dark: '#DE0D12',
                //     light: '#FFFFFF'
                // },
            }}
        />
    );
};

export default QRGenerator;
