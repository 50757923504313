import React, { useEffect, useRef } from 'react';

const OutsideClickDetector = ({ children, onClickOutside, className="" }) => {
    const ref: any = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref?.current.contains(event.target)) {
                onClickOutside();
            }
        };

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [onClickOutside]);

    return <div ref={ref} className={className}>{children}</div>;
};

export default OutsideClickDetector;