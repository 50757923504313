import React from 'react';
import { Layout } from 'antd';
import ProfileCard from '../../../components/ProfileCard';
import { isMobile } from 'react-device-detect';
const { Header } = Layout;

const HeaderAdminComponent: React.FC = () => {
    

    return (<Header className="site-layout-sub-header-background" style={{ padding: 0 }} >
        <div className="header-inner">
            {/* <Input placeholder="Arama Yapınız ...." bordered={false} prefix={<i className="fal fa-search" />} /> */}

            <ul className="header-menu horizontal-list">
              
            
               
                <li>
                    <div className="header-menu-item">
                        <ProfileCard 
                            layout="admin" 
                            showMenu 
                            showDetailButton={false} 
                            showSubTitle 
                            showSimpleOnMobile={isMobile}
                        />
                      
                    </div>
                </li>
            </ul>
        </div>

    </Header>)
}

export default HeaderAdminComponent;