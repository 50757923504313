import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import AuthReducer from './reducers/AuthReducer';
import CommonReducer from './reducers/CommonReducer';
// import ContentReducer from "./reducers/ContentReducer";
import MenuReducer from "./reducers/MenuReducer";
import HomePageReducer from "./reducers/HomePageReducer";
import DemandReducer from "./reducers/DemandReducer";

export const store = configureStore({ reducer: {
    auth: AuthReducer,
    common: CommonReducer,
    // content: ContentReducer,
    menu: MenuReducer,
    homepage: HomePageReducer,
    demand: DemandReducer,
} });

export type Store = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, Store, null, Action<string>>;
export default store;
