import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageBase } from "../../../../components";
import { getMenuItem } from "../../../../helpers/menu";
import { getUrlParamValue, multiLangHandler, showNoti } from "../../../../helpers/root";
import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Switch } from "antd";
import EntityPermissionManagement from '../../EntityPermissionManagement/EntityPermissionManagement';
import { EntityPermission } from "../../../../type/EntityPermissionManagement/EntityPermissionRequest";
import { EntityTargetInfo } from "../../../../type/EntityPermissionManagement/EntityTargetInfoRequest";
import { EntityHeader } from "../../../../type/EntityPermissionManagement/EntityHeader";
import { EntityType } from "../../../../type/Enum/EntityType";
import { callApi } from "../../../../helpers/service";
import { NotifierResultType, formModes, methods } from "../../../../helpers/enums";
import history from "../../../../history";
import uuidv1 from "uuid/v1";
import MultiLangHeaderDiv from "../../../../layouts/AdminLayout/components/MultiLangHeaderDiv";
import { DefaultPortalLanguage, emptyGuid, entityHeaderConstant } from "../../../../helpers/constantData";
import { ValidLanguages } from "../../../../helpers/resources";
import { GetAdminResourceData } from "../../../../helpers/basemethods";

const ContentCategoryForm: React.FC = () => {
    const dispatch = useDispatch();
    const id = getUrlParamValue("id");

    const [form] = Form.useForm();

    const [pageLang, setPageLang] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [ShowPermission, setShowPermission] = useState<boolean>(false);
    const [formMode, setFormMode] = useState<any>(formModes.NEW);
    const [ContentCategoryList, setContentCategoryList] = useState<any[]>([]);
    const [entityHeaderData, setEntityHeaderData] = useState<EntityHeader>({ entityPermissions: [], entityTargetInfo: {} });
    const [ParentCategory, setParentCategory] = useState<any>(null);
    const [titleValues, setTitleValues] = useState<any>({});
    const [isCategoryActive, setIsCategoryActive] = useState<boolean>(true)

    useEffect(() => {
        setPageLang(DefaultPortalLanguage);
        getAllContentCategories()
        if (id) {
            setFormMode(formModes.EDIT)
            getData()
        } else {
            setFormMode(formModes.NEW)
            // setShowPermission(true)
            let _titleValues = ValidLanguages.map(lang => ((
                {
                    lang : lang,
                    value : null
                }
            )))
            setTitleValues(_titleValues);
        }
        
    }, [])

    const getData = () => {
        callApi({
            url: `/admin/Content/GetContentCategoryById/${id}`,
            method: methods.GET,
            useToken: true,
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    form.setFieldsValue({ ...response.data.result.contentCategory, parentCategoryId: response.data.result.contentCategory.parentCategory?.id })
                    setEntityHeaderData({ ...response.data.result.header })
                    // setShowPermission(true)
                    setParentCategory(response.data.result.contentCategory.parentCategory?.id)
                }
            },
            finish: () => {
                setLoading(false)
            }
        })

    }
    const getAllContentCategories = () => {
        callApi({
            url: `/admin/Content/GetAllContentCategories`,
            method: methods.GET,
            useToken: true,
            successCallback: (response) => {
                setContentCategoryList(response.data.result)
                if (getUrlParamValue("parentId")) {
                    form.setFieldsValue({ parentCategoryId: getUrlParamValue("parentId") })
                    setParentCategory(getUrlParamValue("parentId"))
                }
            },
            finish: () => {
                setLoading(false)
            }
        })

    }
    const _save = (contentCategory: any) => {

        
          
        let postParams = {
            sequence : contentCategory.sequence,
            isActive : isCategoryActive,
            entityHeader: entityHeaderConstant,
            values : ValidLanguages.map(lang => ((
                {
                    lang: lang,
                    name: titleValues.find(x=> x.lang == lang).value,
                    id : formMode === formModes.EDIT ? "id" : emptyGuid
                })))
        }       
        let apiUrl = "admin/Content/AddContentCategory";
        console.log(postParams)
        if (formMode === formModes.EDIT) {
            postParams["id"] = id?.toString();
            postParams["parentCategoryId"] = id?.toString();
            apiUrl = "admin/Content/UpdateContentCategory";
        }

        setLoading(true);
        callApi({
            url: apiUrl,
            method: methods.POST,
            useToken: true,
            data: postParams,
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    showNoti(NotifierResultType.success, "Başarılı", "Kaydetme işlemi başarılı")
                    history.replace(getMenuItem("ContentCategoryList").path);
                } else {
                    showNoti(NotifierResultType.error, "Hata!", "Kaydetme işlemi sırasında bir hata meydana geldi")
                }
            },
            finish: () => {
                setLoading(false)
            }
        })
    }

    const _onDelete = () => {
        setLoading(true)
        callApi({
            url: "/admin/Content/RemoveContentCategory",
            method: methods.POST,
            useToken: true,
            data: { id: id },
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    showNoti(NotifierResultType.success, "Başarılı", "Silme işlemi başarılı")
                    history.replace(getMenuItem("ContentCategoryList").path);
                }
            },
            finish: () => {
                setLoading(false)
            }
        })

    }

    const onChangeEntityPermissions = (permissionList: EntityPermission[]) => {
        const newState = { ...entityHeaderData };
        newState.entityPermissions = permissionList;
        setEntityHeaderData(newState)
    }

    const onChangeEntityTargetInfo = (targetInfo: EntityTargetInfo) => {
        const newState = { ...entityHeaderData };
        newState.entityTargetInfo = targetInfo!;
        setEntityHeaderData(newState)
    }
    const getMenuName = () => {
        if (formMode === formModes.NEW) {
            return getMenuItem('ContentCategoryNew').text
        }
        if (formMode === formModes.EDIT) {
            return getMenuItem('ContentCategoryEdit').text
        }
        return ""
    }

    const titleHandler = (e)=> {
        let _values = multiLangHandler(e,titleValues);            
        setTitleValues(_values)
    }

    return (
        <PageBase
            menuName={getMenuName()}
            title={getMenuName()}
            showPageHeader
            showBreadcrump>

            <Form form={form} onFinish={_save} layout='vertical' >
                <MultiLangHeaderDiv pageLang={pageLang} setPageLang={setPageLang} />

                <Row>
                    {
                        (ParentCategory) &&
                        <Col lg={24}>
                            <Form.Item
                                label="Üst Kategori (Seçtili kategori altına yeni kategori ekler.)"
                                name="parentCategoryId"
                                rules={[{ required: true, message: 'Lütfen üst kategöri seçiniz!' }]}
                            >
                                <Select loading={loading} disabled={formMode === formModes.NEW}>
                                    {ContentCategoryList.map((item, i) => (
                                        <Select.Option key={i} value={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col lg={24}>
                        {
                            titleValues?.length>0 && 
                            <>
                                {                        
                                    ValidLanguages.map((lang) => (
                                    (   
                                        <>
                                            <Form.Item label={GetAdminResourceData("AdminBannerFormTitle")} style={{display:pageLang==lang?"block":"none"}} name={"lbltitle"+lang} rules={[{ required: lang==DefaultPortalLanguage, message: 'Lütfen başlık giriniz!' }]}>
                                                <Input 
                                                name={lang + "title"}
                                                value={titleValues.find(x=> x.lang == lang).value}
                                                onChange={titleHandler}
                                                placeholder={GetAdminResourceData("AdminMultiLangInfo").replace("{0}",GetAdminResourceData(lang))}
                                                /> 
                                            </Form.Item>
                                        </>
                                    
                                    )))                            
                                }
                            </>
                        }
                       
                    </Col>
                    <Col lg={24}>
                        <Form.Item label="Url (Eğer url girerseniz, bu url yeni pencerede açılacak!)" name="url" rules={[{ required: false, message: 'Lütfen url giriniz!' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={3}>
                        <Form.Item label="Sıra" name="sequence" rules={[{ required: true, message: 'Lütfen sıra numarası giriniz!' }]}>
                        <InputNumber min={1}  />
                        </Form.Item>
                    </Col>
                    <Col lg={3}>
                        <Form.Item label="Aktiflik" valuePropName="checked" name="isActive">
                        <Switch defaultChecked={true}/>
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item>
                            {
                                ShowPermission &&
                                <EntityPermissionManagement
                                    key={id ?? 0}
                                    entityPermissions={entityHeaderData?.entityPermissions}
                                    entityTargetInfo={entityHeaderData?.entityTargetInfo!}
                                    onChangeEntityPermissions={(data) => onChangeEntityPermissions(data)}
                                    onChangeEntityTargetInfo={(data) => onChangeEntityTargetInfo(data)}
                                    entityType={EntityType.content}
                                />
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Space size={8}>
                        <Button loading={loading} htmlType="submit">Kaydet</Button>
                        {formMode === formModes.EDIT &&
                            <Popconfirm title={"Silmek istediğinize emin misiniz?"} onConfirm={_onDelete} okText="Evet" cancelText="Hayır">
                                <Button className="red" loading={loading}>Sil</Button>
                            </Popconfirm>
                        }
                    </Space>
                </Form.Item>
            </Form>


        </PageBase>
    );
}

export default React.memo(ContentCategoryForm);