import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, Radio, Row, Select } from 'antd';
import { GetCustomValidateMessage, GetOperationResourceData, GetResourceData, GetSystemLanguage } from '../helpers/basemethods';
import { showNoti } from '../helpers/root';
import { NotifierHeaderInfoType, NotifierResultType } from '../helpers/enums';
import { requestTypeProblem, requestTypeRequest } from '../helpers/constantData';
import TextArea from 'antd/lib/input/TextArea';
import FileUpload from './FileUpload';

export interface BTRequestProps { }
const BTRequest: React.FC<BTRequestProps> = (props) => {

    const [form] = Form.useForm();
    const [clientReady, setClientReady] = useState<boolean>(false);

    const [requestType, setRequestType] = useState<string>("");
    const [requestTypeDesc, setRequestTypeDesc] = useState<string>("");
    const [shortDesc, setShortDesc] = useState<string>("");
    const [desc, setDesc] = useState<string>("");
    const [requestTypeCategory, setRequestTypeCategory] = useState<string>("");
    const [requestTypeCategoryDesc, setRequestTypeCategoryDesc] = useState<string>("");
    const [requestAttachment,setRequestAttachment] = useState<string[]>([]);
    const [btnMargin, setBtnMargin] = useState<number>(0);
    const [clearTrigger, setClearTrigger] = useState<string>("");
  
    useEffect(() => {
        preparePage();
      }, []);

      const preparePage = () => {
        setRequestType(requestTypeProblem);
        form.setFieldValue("requestType",requestTypeProblem )
        setRequestTypeDesc(GetResourceData(requestTypeProblem))
        setClientReady(true);   
      }
    
      const onFinish = (values: any) => {
        clearPage();
        showNoti(NotifierResultType.success, GetOperationResourceData(NotifierHeaderInfoType.notifierSuccess), GetOperationResourceData("DayOffDemandSuccess"))
    };

    const clearPage = () => {
        form.resetFields();
        clearFiles();
        setShortDesc("");
        setDesc("");
        setRequestTypeCategoryDesc("");
        setBtnMargin(0);
    }


    const shortDescHandler = (desc) => {
        setShortDesc(desc.target.value);
    }
    const requestDescHandler = (desc) => {
        setDesc(desc.target.value);
    }

    const requestTypeHandler = (e) => {
        let _value = e.target.value;     
        setRequestType(_value);
        setRequestTypeDesc(GetResourceData(_value))
        form.resetFields();
    }

    const requestTypeCategorySelectHandler = (key,value) => {
        setRequestTypeCategory(key);
        setRequestTypeCategoryDesc(value.children);
    }

    const handleInvalid = (event) => {
        const target = event.target;
        if (!target.validity.valid) {
            let _errorMessage = GetCustomValidateMessage(target.name);
            if (_errorMessage) {
                target.setCustomValidity(_errorMessage);
            }
        }
      };

      const clearIsDone = () => {
        setClearTrigger("")
        setRequestAttachment([])
      }

    const handleInput = (event) => {
        event.target.setCustomValidity('');
    };

    
    const fileHandler = (e,lang) => {
        setBtnMargin(e.length);
        setRequestAttachment(e.map(item => item.name ))
    }
    
    const clearFiles = () => {
        setClearTrigger("clear");
    }


    return (
        <React.Fragment>           
           {
                <Form className='bt-request' form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>                     
                        <Row>
                            <Col className='demandColLeft' xl={12} lg={12} md={12} sm={24} xs={24} >
                                <Card type='inner' title={GetResourceData("ITRequest")}>
                                    <Row>
                                        <Col xl={24} lg={24} md={12} sm={24} xs={24}>
                                            <Radio.Group className='btRequestType' onChange={requestTypeHandler} defaultValue={requestTypeProblem}>
                                                <Radio key={requestTypeProblem} value={requestTypeProblem}>
                                                <strong>{GetResourceData("requestTypeProblem")}</strong> 
                                                </Radio>
                                                <Radio key={requestTypeRequest} value={requestTypeRequest}>
                                                <strong>{GetResourceData("requestTypeRequest")}</strong> 
                                                </Radio>
                                            </Radio.Group>   
                                        </Col>    
                                                                          
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item required label={GetResourceData("ShortDesc")} name="shortDescription" > 
                                            <Input placeholder={GetResourceData("ShortDescriptionPlaceHolder")} onChange={shortDescHandler} name='shortDescription' required onInvalid={handleInvalid} onInput={handleInput}></Input>
                                            </Form.Item>
                                        </Col>   
                                        
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item label={GetResourceData("requestTypeCategory")} name="requestTypeCategory" required>
                                                <Select placeholder={GetResourceData("selectRequestTypeCategory")} onChange={requestTypeCategorySelectHandler}>
                                                    <Select.Option value="1">{GetResourceData("CorporateAppsERP")}</Select.Option>
                                                    <Select.Option value="2">{GetResourceData("CorporateAppsCRM")}</Select.Option>
                                                    <Select.Option value="3">{GetResourceData("CorporateAppsIK")}</Select.Option>
                                                    <Select.Option value="4">{GetResourceData("CorporateAppsOther")}</Select.Option>
                                                    <Select.Option value="5">{GetResourceData("Computer")}</Select.Option>
                                                    <Select.Option value="6">{GetResourceData("Phone")}</Select.Option>
                                                    <Select.Option value="7">{GetResourceData("Monitor")}</Select.Option>
                                                    <Select.Option value="8">{GetResourceData("OtherBT")}</Select.Option>
                                                    <Select.Option value="9">{GetResourceData("Auth")}</Select.Option>
                                                    <Select.Option value="10">{GetResourceData("OfficeApps")}</Select.Option>
                                                    <Select.Option value="11">{GetResourceData("SecurityApps")}</Select.Option>
                                                    <Select.Option value="12">{GetResourceData("Other")}</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>  
                                                                            
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item required label={GetResourceData("Description")} name="requestDesc" >
                                                <TextArea required placeholder={GetResourceData("ShortDescriptionPlaceHolder")}  rows={5} name='requestDesc' onChange={requestDescHandler} onInvalid={handleInvalid} onInput={handleInput}/>
                                            </Form.Item>
                                        </Col>  
                                                                            
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{marginBottom:"10px"}}>
                                            <label>{GetResourceData("FileAttachment")}</label>
                                        </Col>    
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <FileUpload      
                                                clearTrigger={clearTrigger}
                                                clearIsDone={clearIsDone}                                    
                                                isMultiple={false} 
                                                setFileList={fileHandler} 
                                                language={GetSystemLanguage()}                                                 
                                                />
                                        </Col>    
                                        
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{marginTop:(btnMargin*(30) + 10)+"px"}}>
                                            <Form.Item shouldUpdate>
                                                {() => (
                                                    <Button
                                                    className="corporate-demands-btn"
                                                    style={{float:"right"}}
                                                    type="primary"
                                                    htmlType="submit"
                                                    disabled={
                                                        !clientReady ||
                                                        !form.isFieldsTouched(true) ||
                                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                    }
                                                    >
                                                    {GetResourceData("Demand")}
                                                    </Button>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='demandColRight' xl={12} lg={12} md={12} sm={24} xs={24} >
                                <Card type='inner' title={GetResourceData("DemandDetail")} style={{height:"100%"}}>                                    
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("BTRequestType")}</span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{requestTypeDesc}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("ShortDesc")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{shortDesc}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("requestTypeCategory")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{requestTypeCategoryDesc}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("Description")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{desc}</span>
                                        </Col>
                                    </Row>
                                    <Divider/>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("FileAttachment")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                {
                                                    requestAttachment && requestAttachment.map(item => (
                                                        <><span>{item}</span><br/></>
                                                    ))
                                                }
                                        </Col>
                                    </Row>                                    
                                </Card>
                            </Col>
                        </Row>
              </Form>
           }
        </React.Fragment>
    );
}

export default BTRequest;