import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../state';
import Marquee from "react-fast-marquee";
import { Content } from 'antd/lib/layout/layout';
import { PlusOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { getPublicNews } from '../state/reducers/HomePageReducer';
import { AutoComplete, Button, Card, Col, DatePicker, Descriptions, DescriptionsProps, Divider, Form, Input, Row, Select, SelectProps, Tooltip } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { CostTypes, CurrencyCodes } from '../helpers/constantData';
import { GetOperationResourceData, GetResourceData, GetSystemLanguage } from '../helpers/basemethods';
import moment from 'moment';
import { NotifierHeaderInfoType, NotifierResultType } from '../helpers/enums';
import { showNoti } from '../helpers/root';

export interface ExpenseRequestProps { }
const ExpenseRequest: React.FC<ExpenseRequestProps> = (props) => {

    const [form] = Form.useForm();
    const [clientReady, setClientReady] = useState<boolean>(false);    
    const [currencyCode, setCurrencyCode] = useState<any>("");
    const base = { date: "", type: "", desc: "", price: undefined }
    const [ExpenseList, setExpenseList] = useState<any>([{ date: "", type: "", desc: "", price: 0  }])

    const expenseTypes = [
        "",
        ""
    ]

    if (GetSystemLanguage() == "Tr") {
        moment.locale('tr-TR');
    } else {
        moment.locale('en-US');
    }

    useEffect(() => {
        setClientReady(true);
        form.setFieldsValue({dayCount:4})
      }, []);
    
      const onFinish = (values: any) => {
        form.resetFields(); // Form elemanlarının değerlerini sıfırla
        setCurrencyCode("");
        setExpenseList([{ date: "", type: "", desc: "", price: 0  }]);
        showNoti(NotifierResultType.success, GetOperationResourceData(NotifierHeaderInfoType.notifierSuccess), GetOperationResourceData("CostDemandSuccess"))

      };

    return (
        <React.Fragment>           
           {
                <Form className='expense-request' form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>                     
                        <Row>
                            <Col className='demandColLeft' xl={12} lg={12} md={12} sm={24} xs={24} >
                                <Card type='inner' title={GetResourceData("CostDemand")}>
                                    <Row>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item  name="currency" rules={[{ required: true }]}>
                                                <Select placeholder={GetResourceData("CurrencyCode")} onSelect={(value) => setCurrencyCode(value)} >
                                                    {CurrencyCodes.map((item) => (
                                                        <Select.Option key={item.value} value={item.text}>
                                                        {item.text}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>                                  
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <div className="flex-between mb-10 gap-10">
                                            <h3 className='costItemClass'><strong>{GetResourceData("CostItem")}</strong></h3>
                                            <Tooltip title={GetResourceData("Add")}>
                                                <Button style={{ marginRight: "-10px" }} onClick={() => { let $data = [...ExpenseList]; $data.push(base); setExpenseList($data); }} type="primary" shape="circle" icon={<PlusOutlined />} />
                                            </Tooltip>
                                        </div>

                                            {
                                            ExpenseList.map((item, i) => (
                                                <Row key={i}>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
                                                    <Form.Item>
                                                        <Select placeholder={GetResourceData("CostType")} optionLabelProp="label" value={item.type || undefined} optionFilterProp={"label"} onChange={(e) => { let $data = [...ExpenseList]; $data[i].type = e; setExpenseList($data) }} >
                                                            {CostTypes.map((item) => (
                                                                <Select.Option key={item.value} value={item.text}>
                                                                {item.text}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={5} sm={24} xs={24}>
                                                    <Form.Item>
                                                    <DatePicker className='expenseDate' placeholder={GetResourceData("Date")} value={item.date} onChange={(e) => {  let $data = [...ExpenseList]; $data[i].date = e; setExpenseList($data);  }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={3} sm={24} xs={24}>
                                                    <Form.Item>
                                                    <Input placeholder={GetResourceData("Amount")} type="number" min={0} value={item.price || undefined} onChange={(e) => { let $data = [...ExpenseList]; $data[i].price = e.target.value; setExpenseList($data) }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={9} sm={24} xs={24}>
                                                    <Form.Item>
                                                    <Input placeholder={GetResourceData("Description")} value={item.desc} onChange={(e) => { let $data = [...ExpenseList]; $data[i].desc = e.target.value; setExpenseList($data) }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col className='expenseDeleteCol' lg={1}>
                                                    <Tooltip title={GetResourceData("Delete")}>
                                                    <Button disabled={ExpenseList.length === 1} onClick={() => { let $data = [...ExpenseList]; $data.splice(i, 1); setExpenseList($data); }} type="primary" shape="circle" icon={<DeleteOutlined />} />
                                                    </Tooltip>
                                                </Col>
                                                </Row>
              ))
            }
               
                                        </Col>    
                                    </Row>
                             
                             
                                    <Row>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item shouldUpdate>
                                                {() => (
                                                    <Button
                                                    style={{float:"right"}}
                                                    type="primary"
                                                    htmlType="submit"
                                                    disabled={
                                                        !clientReady ||
                                                        !form.isFieldsTouched(true) ||
                                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                    }
                                                    >
                                                    {GetResourceData("Demand")}
                                                    </Button>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='demandColRight' xl={12} lg={12} md={12} sm={24} xs={24} >
                                <Card className='costCard' type='inner' title={GetResourceData("DemandDetail")} style={{height:"100%"}}>                             
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span className='costSpanTitle' style={{fontWeight:"bold"}}>{GetResourceData("CurrencyCode")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span className='costSpanDesc'>{currencyCode}</span>
                                        </Col>
                                    </Row>
                                    <div>

                                    {
                                        ExpenseList?.length > 0 && 
                                        <>
                                          {ExpenseList.map((item) => (
                                                                <><Divider /><Row>
                                                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                      <span className='costSpanTitle' style={{ fontWeight: "bold" }}>{GetResourceData("Date")} & {GetResourceData("CostType")}: </span>
                                                  </Col>
                                                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                      <span className='costSpanDesc'>{item?.date != "" ? item.date.format('L') : ""} - {item.type}</span>
                                                  </Col>
                                              </Row><Row>
                                                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                          <span className='costSpanTitle' style={{ fontWeight: "bold" }}>{GetResourceData("Amount")} : </span>
                                                      </Col>
                                                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                          <span className='costSpanDesc'>{item.price} {currencyCode}</span>
                                                      </Col>
                                                  </Row><Row>
                                                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                          <span className='costSpanTitle' style={{ fontWeight: "bold" }}>{GetResourceData("Description")} : </span>
                                                      </Col>
                                                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                          <span className='costSpanDesc'>{item.desc}</span>
                                                      </Col>
                                                  </Row></>
                                            ))}
                                        
                                        
                                        </>
                                   
                                    }
                                    
                                    </div>
                                    
                                </Card>
                            </Col>
                        </Row>
              </Form>
           }
        </React.Fragment>
    );
}

export default ExpenseRequest;