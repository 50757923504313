import { createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { AppDispatch } from "..";
import { callApi } from "../../helpers/service";
import { ServiceResult } from "../../type/ServiceResult";
import { methods } from "../../helpers/enums";
import moment from "moment";
import { GetActivityCalendarItems, GetBannerItems, getBoardIdeaItems, getBornToWeekItems, getMealMenuItems, getNewEmployeeItems, getOurNewsItems } from "../../business/Public/HomePageBusiness";
import { GetSystemLanguage, GetTodayWithCertainFormat, GetTodayWithShortFormat } from "../../helpers/basemethods";
import { getRandomName, getRandomUsername } from "../../helpers/samples";
// import { GetTodayWithCertainFormat, GetTodayWithShortFormat } from "../../helpers/basemethods";


export type HomePageReducerType = {
    banners: any | null;
    bornToWeek: any | null;
    boardIdeas: any | null;
    newEmployees: any | null;
    mostThanks: any | null;
    myThanks: any | null;
    thanksCategories: any | null;
    activities: any | null;
    announcements: any | null;
    publicNews: any | null;
    mealMenu: any | null;
    popup: any | null;
    ourNews: any | null;
    newsFromUs: any | null;
    bannersCalling: any | null; 
    activitiesCalling: any | null;   
}

const initialState: HomePageReducerType = {
    banners: null,
    bornToWeek: null,
    newEmployees: [],
    mostThanks: null,
    myThanks: null,
    thanksCategories: [],
    activities: null,
    announcements: null,
    publicNews: null,
    mealMenu: null,
    popup: null,
    boardIdeas: [],
    ourNews: [],
    newsFromUs:null,
    bannersCalling:false,
    activitiesCalling: false
}

const homePageReducer = createSlice({
    name: "homepage",
    initialState,
    reducers: {
        setBanners: (state, action) => void (state.banners = action.payload),
        setBornToWeek: (state, action) => void (state.bornToWeek = action.payload),
        setBoardIdeas: (state, action) => void (state.boardIdeas = action.payload),
        setNewEmployees: (state, action) => void (state.newEmployees = action.payload),
        setMostThanks: (state, action) => void (state.mostThanks = action.payload),
        setMyThanks: (state, action) => void (state.myThanks = action.payload),
        setThanksCategories: (state, action) => void (state.thanksCategories = action.payload),
        setActivities: (state, action) => void (state.activities = action.payload),
        setAnnouncements: (state, action) => void (state.announcements = action.payload),
        setPublicNews: (state, action) => void (state.publicNews = action.payload),
        setMealMenu: (state, action) => void (state.mealMenu = action.payload),
        setPopup: (state, action) => void (state.popup = action.payload),
        setOurNews: (state, action) => void (state.ourNews = action.payload),
        setNewsFromUs: (state, action) => void (state.newsFromUs = action.payload),
        setBannersCalling: (state, action) => void (state.bannersCalling = action.payload),
        setActivitiesCalling: (state, action) => void (state.activitiesCalling = action.payload),
        
    }
});

export const {
    setBanners,
    setBornToWeek,
    setNewEmployees,
    setMostThanks,
    setMyThanks,
    setThanksCategories,
    setActivities,
    setAnnouncements,
    setPublicNews,
    setMealMenu,
    setPopup,
    setBoardIdeas,
    setOurNews,
    setNewsFromUs,
    setBannersCalling,
    setActivitiesCalling
} = homePageReducer.actions;



export const getBornToWeek = () => async (dispatch: AppDispatch) => {
    let apiCall = getBornToWeekItems();
    if (apiCall.isSuccessful) {      
        dispatch(setBornToWeek(apiCall.result));
    }    
};
export const getOurNews = () => async (dispatch: AppDispatch) => {
    let apiCall = getOurNewsItems();
    if (apiCall.isSuccessful) {      
        dispatch(setOurNews(apiCall.result));
    } 
};

export const getBoardIdeas = () => async (dispatch: AppDispatch) => {
    let apiCall = getBoardIdeaItems();
    if (apiCall.isSuccessful) {      
        dispatch(setBoardIdeas(apiCall.result));
    }
};
export const getNewEmployees = () => async (dispatch: AppDispatch) => {
    let apiCall = getNewEmployeeItems();
    if (apiCall.isSuccessful) {      
        dispatch(setNewEmployees(apiCall.result));
    }
};
export const getMostThanks = () => async (dispatch: AppDispatch) => {
    // callApi({
    //     url: "api/Thanks/GetMostThanks",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setMostThanks(res.data.result));
    //         }
    //     }
    // })
    dispatch(setMostThanks([
        {
          "Owner" : "Volkan Seçginli",
          "userName" : "volkan.secginli"
        },
        {
          "Owner" : getRandomName(),
          "userName" : getRandomUsername()
        },
        {
            "Owner" : getRandomName(),
            "userName" : getRandomUsername()
        }]));
};
export const getMyThanks = () => async (dispatch: AppDispatch) => {
    // callApi({
    //     url: "api/Thanks/GetMyThanks",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setMyThanks(res.data.result));
    //         }
    //     }
    // })
};
export const getThanksCategories = () => async (dispatch: AppDispatch) => {
    // callApi({
    //     url: "api/Thanks/GetThanksCategories",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setThanksCategories(res.data.result));
    //         }
    //     }
    // })
    dispatch(setThanksCategories([
        {
            "title": "Hayatı Kolaylaştırmak",
            "description": "Hayatı Kolaylaştırmak",
            "id": "34baee97-d581-40db-8bbe-62cc686e3006",
            "isActive": true
        },
        {
            "title": "İş Birliği Geliştirmek",
            "description": "İş Birliği Geliştirmek",
            "id": "10b43e30-3163-42f4-ae7b-ba05028742b6",
            "isActive": true
        },
        {
            "title": "Liderlik Sunmak",
            "description": "Liderlik Sunmak",
            "id": "e6373944-2185-483d-88cd-740703b3320a",
            "isActive": true
        },
        {
            "title": "Müşteri Mutluluğu Yaratmak",
            "description": "Müşteri Mutluluğu Yaratmak",
            "id": "56966d60-6e10-440d-807e-59781135beb9",
            "isActive": true
        },
        {
            "title": "Yaratıcı Fikir Sunmak",
            "description": "Yaratıcı Fikir Sunmak",
            "id": "0e1ed1f7-6272-4274-8c0b-4c81fa9df36b",
            "isActive": true
        },
        {
            "title": "Zor Koşullarda Başarmak",
            "description": "Zor Koşullarda Başarmak",
            "id": "c56f698b-41a7-4c15-aa0f-1a5d5fccb451",
            "isActive": true
        }
    ]))
};
export const getActivityCalendar = () => async (dispatch: AppDispatch) => {
    let apiCall = GetActivityCalendarItems();
    if (apiCall.isSuccessful) {      
        dispatch(setActivities(apiCall.result));
    }
};
export const getAnnouncements = () => async (dispatch: AppDispatch) => {
    // callApi({
    //     url: "api/Announcement/GetActiveAnnouncements",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setAnnouncements(res.data.result));
    //         }
    //     }
    // })
    dispatch(setAnnouncements(["THY ile 5 yıllık anlaşmamızı imzaladık!","THY ile 5 yıllık anlaşmamızı imzaladık!", ]));
};
export const getPublicNews = () => async (dispatch: AppDispatch) => {
    // callApi({
    //     url: "api/Announcement/GetPublicNews",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<any>) => {
    //         if (res.status === 200) {
    //             dispatch(setPublicNews(res.data));
    //         }
    //     }
    // })
    dispatch(setPublicNews(["USD/TRY : 30.65","EUR/TRY : 33.47","BIST100 : 9,248.85"]));
};
export const getMealMenu = () => async (dispatch: AppDispatch) => {
    let apiCall = getMealMenuItems();
    if (apiCall.isSuccessful) {      
        dispatch(setMealMenu(apiCall.result));
    }   
};
export const getPopups = () => async (dispatch: AppDispatch) => {
    // callApi({
    //     url: "api/Popup/GetActivePopups",
    //     useToken: true,
    //     successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
    //         if (res.status === 200) {
    //             dispatch(setPopup(res.data.result))
    //         }
    //     }
    // })
    dispatch(setPopup([
        {
            "id": 1,
            "title" : GetSystemLanguage() == "Tr" ? "Yeni Çalışma Modelimiz" : "Our New Working Model",
            //"description" : "desc",
            "showAgain" : true,
            "img" : GetSystemLanguage() == "Tr" ?  require('../../assets/images/samples/popupTr.png').default : require('../../assets/images/samples/popupEn.png').default
        }
    ]))
};


export default homePageReducer.reducer;