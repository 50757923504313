export const OperationResourceData = {
    notifierSuccess : {
        valTr : "Başarılı",
        valEn : "Successful"
    },
    notifierInfo : {
        valTr : "Bilgi",
        valEn : "Info"
    },
    CopyToCliboardSuccess: {
        valTr: "İmza panoya kopyalandı",
        valEn: "Signature copied on clipboard"
    },
    CopyToCliboardFail: {
        valTr: "İmza panoya kopyalanamadı",
        valEn: "Signature could not be copied to the clipboard"
    },
    QuickSearchNotFound: {
        valTr: "Herhangi bir sonuç bulunamadı...",
        valEn: "No results were found..."
    },
    MessageSendToUser: {
        valTr: "Mesajınız {0} kişisine başarıyla gönderilmiştir!",
        valEn: "Your message has been successfully sent to {0}!"
    },
    MessageCouldntBeSent: {
        valTr: "Mesajınız gönderilirken bir hata meydana geldi!",
        valEn: "An error occurred while sending your message!"
    },
    DayOffDemandSuccess: {
        valTr: "İzin talebiniz başarıyla iletildi.",
        valEn: "Your day off request has been successfully submitted."
    },
    CostDemandSuccess: {
        valTr: "Masraf talebiniz başarıyla iletildi.",
        valEn: "Your cost request has been successfully submitted."
    },
    IdeaRequestSend: {
        valTr: "Fikir talebiniz başarıyla iletildi.",
        valEn: "Your new idea request has been successfully submitted."
    },
    SurveySend: {
        valTr: "Anket yanıtlarınız başarıyla iletildi.",
        valEn: "Your survey responses have been successfully transmitted."
    },
    AddTaskSuccess: {
        valTr: "Görev ekleme başarılı.",
        valEn: "Task successfully added."
    },
}