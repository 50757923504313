import * as React from 'react';
import { Anchor, Button, Col, Divider, Form, Input, Modal, Row, Space, Switch } from "antd";
import { AxiosResponse } from "axios";
import { useEffect, useState,useLayoutEffect } from "react";
import { gutterSize } from "../../../helpers/root";
import { callApi } from "../../../helpers/service";
import { ServiceResult } from "../../../type/ServiceResult";
import { UserOrGroup } from '../../../type/GroupManagement/UserOrGroup';
import { UserOrGroupMember } from '../../../type/GroupManagement/UserOrGroupMember';
import CircleName from '../../../components/CircleName';
import uuidv1 from "uuid/v1";
import { debounce } from 'lodash';
import _ from 'lodash';
import { EntityPermission } from '../../../type/EntityPermissionManagement/EntityPermissionRequest';
import { EntityTargetInfo } from '../../../type/EntityPermissionManagement/EntityTargetInfoRequest';
import { EntityType } from '../../../type/Enum/EntityType';
import { getRandomFirstName, getRandomName, getRandomSurName, getRandomTitle } from '../../../helpers/samples';

interface IProps { itemId?: string; entityType: EntityType; onChangeEntityPermissions: Function; onChangeEntityTargetInfo: Function; entityPermissions?: EntityPermission[] | null; entityTargetInfo?: EntityTargetInfo | null, reset?: boolean }
const PermissionManagement: React.FC<IProps> = ({ onChangeEntityPermissions, onChangeEntityTargetInfo, entityPermissions, entityTargetInfo, itemId, entityType, reset }) => {    
    const [GroupAndPerson, setGroupAndPersonData] = useState<UserOrGroup[]>([]);
    const [permissionList, setPermissionList] = useState<UserOrGroupMember[]>([]);
    const [targetInfo, setTargetInfo] = useState<EntityTargetInfo>();
    const [groupFilterIsActive, setGroupFilter] = useState<any>()
    const [form] = Form.useForm();
    
    useEffect(() => {
        onChangeEntityTargetInfo(targetInfo)
    }, [targetInfo])
    useEffect(() => {
        onChangeEntityPermissions(permissionList)
    }, [permissionList])
    useEffect(() => {
        fill();
    }, [])
    useEffect(() => {
        fill();
    }, [reset])





    const fill = () => {
        if (entityTargetInfo) {
            form.setFieldsValue({
                entityType: entityTargetInfo.entityType,
                itemId: itemId,
                kocSistem: entityTargetInfo.kocSistem,
                kocSavunma: entityTargetInfo.kocSavunma,
                kocDigital: entityTargetInfo.kocDigital,
                entegart: entityTargetInfo.entegart,
            })
            setTargetInfo(entityTargetInfo)
        }
        if (entityPermissions && entityPermissions.length > 0) {
            let newList: UserOrGroupMember[] = [...permissionList]
            entityPermissions.forEach((item) => {
                newList.push({
                    group: item.group,
                    groupId: item.group ? item.group.id : null,
                    applicationUser: item.applicationUser,
                    applicationUserId: item.applicationUser ? item.applicationUser.id : null,
                    itemId: itemId!,
                    entityType: entityType
                },)
            })
            setPermissionList(newList);
            if (entityPermissions) {
                form.setFieldsValue({ grupFilterCheck: true })
                setGroupFilter(true)
            }
        }
        else{
            form.setFieldsValue({ grupFilterCheck: false })
                setGroupFilter(false)
        }
    }

    const _onChange = (value: any) => {

        if (value && value.length > 2) {
            callApi({
                url: `admin/EntityPermission/SearchUserOrGroup?query=${value}`,
                useToken: true,
                successCallback: (
                    res: AxiosResponse<ServiceResult<UserOrGroup[]>>
                ) => {
                    setGroupAndPersonData(res.data.result);
                },
            });
        }
    }

    const debounceOnChange = debounce(query => { _onChange(query) }, 400);

    const _removeFromList = (item: UserOrGroupMember) => {
        if (item.group) {
            Modal.confirm({
                title: 'Emin misiniz?',
                content: `'${item.group.name}' grubu eklenenler arasından çıkarılacaktır!`,
                onOk() {
                    var filteredData = permissionList.filter(x => x.group?.id != item.group?.id);
                    setPermissionList(filteredData)
                },
                onCancel() {
                },
            });
        }
        else {
            Modal.confirm({
                title: 'Emin misiniz?',
                content: `'${getRandomName()}' kullanıcısı eklenenler arasından çıkarılacaktır!`,
                onOk() {
                    var filteredData = permissionList.filter(x => x.applicationUser?.id != item.applicationUser?.id);
                    setPermissionList(filteredData)
                },
                onCancel() {
                },
            });
        }
    }

    const _addUserOrGroup = (item: UserOrGroup) => {
        let $items = [...permissionList];
        if (item.user && $items.filter(x => x.applicationUserId === item.user.id).length > 0) {
            return
        }
        if (item.group && $items.filter(x => x.groupId === item.group.id).length > 0) {
            return
        }
        $items.push({
            group: item.group,
            groupId: item.group ? item.group.id : null,
            applicationUser: item.user,
            applicationUserId: item.user ? item.user.id : null,
            itemId: itemId!,
            entityType: entityType
        })
        setPermissionList($items)
    }

    const grupFilterChecked = (value) => {
        setGroupFilter(value);
        if (!value) {
            setPermissionList([])
        }

    }

    const onChangeTargetInfo = (key: string, value: boolean) => {
        var targetInfoState: EntityTargetInfo = {
            ...targetInfo,
            kocSistem: key == "kocSistem" ? value : targetInfo?.kocSistem!,
            kocDigital: key == "kocDigital" ? value : targetInfo?.kocDigital!,
            kocSavunma: key == "kocSavunma" ? value : targetInfo?.kocSavunma!,
            entegart: key == "entegart" ? value : targetInfo?.entegart!,
            entityType: targetInfo?.entityType!,
            itemId: itemId!
        };

        setTargetInfo(targetInfoState)
    };

    return (
        <>
            <div className="content-card-basic" style={{ backgroundColor: '#fbf9f7' }}>
                <Form form={form} >
                    


                    <Form.Item valuePropName="checked" name="grupFilterCheck">
                        <Switch checkedChildren="Grup veya Kişi Filtresi Ekle" unCheckedChildren="Grup veya Kişi Filtresi Ekle" onChange={grupFilterChecked} />
                    </Form.Item>

                </Form>
                <div style={{ display: !groupFilterIsActive ? 'none' : '', height: 500 }}> <div className="row-space-between">

                    <Space direction="vertical" style={{ width: "100%" }}>
                        <div className="input-with-button" style={{ padding: 0, width: 313 }}>
                            <Input placeholder="Grup veya kişi adı arayın..." size="middle" onChange={(e: any) => debounceOnChange(e.target.value)} />
                        </div>
                        <Row gutter={gutterSize}>
                            <Col xl={8} lg={8} md={10} sm={24}>
                                <Anchor>
                                    <div className="content-card-basic no-padding">
                                        <div className="header" style={{ padding: 0, paddingLeft: 20, paddingTop: 20 }}>
                                            <h3 className="content-title">
                                                <i className="fal fa-users" /> Grup veya Kişiler
                                            </h3>
                                        </div>
                                        <div className="body no-padding" style={{ maxHeight: "350px", overflow: "auto" }}>
                                            <div className="custom-menu">
                                                {GroupAndPerson.map(i => (
                                                    <div key={uuidv1()} className={`menu-item`}>
                                                        <CircleName name={getRandomName()} surname="" title={getRandomName()} />
                                                        {i.group && (<i className="fal fa-users" style={{ color: 'red' }} />)}
                                                        <div className="badge-arrow-container">
                                                            <Button onClick={() => _addUserOrGroup(i)}>Ekle</Button>
                                                        </div>
                                                    </div>)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Anchor>
                            </Col>
                            <Col xl={16} lg={16} md={14} sm={24}>
                                {permissionList &&
                                    <Anchor>
                                        <div className="content-card-basic no-padding">
                                            <div className="header" style={{ padding: 0, paddingLeft: 20, paddingTop: 20 }}>
                                                <h3 className="content-title">
                                                    <i className="fal fa-user" /> Eklenenler
                                                </h3>
                                            </div>
                                            <div className="body no-padding" style={{ maxHeight: "350px", overflow: "auto" }}>
                                                <div className="custom-menu">
                                                    {permissionList.map(i => (
                                                        <div key={uuidv1()}>
                                                            {i.applicationUser && (
                                                                <div key={uuidv1()} className={`menu-item`}>
                                                                    <CircleName name={getRandomFirstName()} surname={getRandomSurName()} title={getRandomTitle()} />
                                                                    <div className="badge-arrow-container">
                                                                        <Button type='primary' danger={true} onClick={() => _removeFromList(i)}>Çıkar</Button>
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                            {i.group && (
                                                                <div key={uuidv1()} className={`menu-item`}>
                                                                    <CircleName name={i.group.name} surname={""} title={i.group.description} />
                                                                    <i className="fal fa-users" style={{ color: 'red' }} />
                                                                    <div className="badge-arrow-container">
                                                                        <Button type='primary' danger={true} onClick={() => _removeFromList(i)}>Çıkar</Button>
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                        </div>
                                                    )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Anchor>
                                }
                            </Col>
                        </Row>
                    </Space>
                </div>
                </div>
            </div>

        </>
    );
}

// export default React.memo(PermissionManagement);
export default PermissionManagement;

