import React, { useEffect, useState } from "react";
import { PageBase, RichTextEditor } from "../../../../components";
import { getMenuItem } from "../../../../helpers/menu";
import { getUrlParamValue, showNoti } from "../../../../helpers/root";
import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Space, Switch } from "antd";
import EntityPermissionManagement from '../../EntityPermissionManagement/EntityPermissionManagement';
import { EntityPermission } from "../../../../type/EntityPermissionManagement/EntityPermissionRequest";
import { EntityTargetInfo } from "../../../../type/EntityPermissionManagement/EntityTargetInfoRequest";
import { EntityHeader } from "../../../../type/EntityPermissionManagement/EntityHeader";
import { EntityType } from "../../../../type/Enum/EntityType";
import { callApi } from "../../../../helpers/service";
import { NotifierResultType, formModes, methods } from "../../../../helpers/enums";
import history from "../../../../history";
import uuidv1 from "uuid/v1";

const FAQForm: React.FC = () => {
    const id = getUrlParamValue("id");
    const contentId = getUrlParamValue("contentId");
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [ShowPermission, setShowPermission] = useState<boolean>(false);
    const [formMode, setFormMode] = useState<any>(formModes.NEW);
    const [entityHeaderData, setEntityHeaderData] = useState<EntityHeader>({ entityPermissions: [], entityTargetInfo: {} });

    useEffect(() => {
        if (id) {
            setFormMode(formModes.EDIT)
            getData()
        } else {
            setFormMode(formModes.NEW)
            setShowPermission(true)
        }
    }, [])

    const getData = () => {
        callApi({
            url: `/admin/Content/GetContentFaqById/${id}`, method: methods.GET, useToken: true,
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    form.setFieldsValue({ ...response.data.result.contentFAQ})
                    setEntityHeaderData({ ...response.data.result.header })
                    setShowPermission(true)
                    
                }
            },
            finish: () => {
                setLoading(false)
            }
        })
    }
    const _save = (data: any) => {

        var params: any = {
            ...data,
            contentId: contentId,
            entityHeader: entityHeaderData,
        };

        
        let apiUrl = "admin/Content/AddFaqToContent";
        if (formMode === formModes.EDIT) {
            params.id = id?.toString();
            apiUrl = "admin/Content/UpdateContentFaq";
        }

        setLoading(true);
        callApi({
            url: apiUrl, method: methods.POST, useToken: true, data: params,
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    showNoti(NotifierResultType.success, "Başarılı", "Kaydetme işlemi başarılı")
                    history.goBack()
                } else {
                    showNoti(NotifierResultType.error, "Hata!", "Kaydetme işlemi sırasında bir hata meydana geldi")
                }
            },
            finish: () => {
                setLoading(false)
            }
        })
    }
    const _onDelete = () => {
        setLoading(true)
        callApi({
            url: "/admin/Content/DeleteContentFaq",
            method: methods.POST,
            useToken: true,
            data: { id: id },
            successCallback: (response) => {
                if (response.data.isSuccessful) {
                    showNoti(NotifierResultType.success, "Başarılı", "Silme işlemi başarılı")
                    history.goBack()
                }
            },
            finish: () => {
                setLoading(false)
            }
        })

    }
    const onChangeEntityPermissions = (permissionList: EntityPermission[]) => {
        const newState = { ...entityHeaderData };
        newState.entityPermissions = permissionList;
        setEntityHeaderData(newState)
    }
    const onChangeEntityTargetInfo = (targetInfo: EntityTargetInfo) => {
        const newState = { ...entityHeaderData };
        newState.entityTargetInfo = targetInfo!;
        setEntityHeaderData(newState)
    }
    const getMenuName = () => {
        if (formMode === formModes.NEW) {
            return getMenuItem('FAQNew').text
        }
        if (formMode === formModes.EDIT) {
            return getMenuItem('FAQEdit').text
        }
        return ""
    }
    return (
        <PageBase menuName={getMenuName()} title={getMenuName()} showPageHeader showBreadcrump>
            <Form form={form} onFinish={_save} layout='vertical' >
                <Row gutter={10}>
                    <Col lg={24}>
                        <Form.Item label="Başlık" name="name" rules={[{ required: true, message: 'Lütfen başlık giriniz!' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item label="Cevap" name="description" rules={[{ required: true, message: 'Lütfen içerik giriniz' }]}>
                            <RichTextEditor />
                        </Form.Item>
                    </Col>
                    <Col lg={3}>
                        <Form.Item label="Sıra" name="sequence" rules={[{ required: true, message: 'Lütfen sıra numarası giriniz!' }]}>
                        <InputNumber min={0} defaultValue={1} />
                        </Form.Item>
                    </Col>
                    <Col lg={3}>
                        <Form.Item label="Aktiflik" valuePropName="checked" name="isActive">
                        <Switch defaultChecked={true}/>
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item>
                            {
                                ShowPermission &&
                                <EntityPermissionManagement
                                    key={id ?? 0}
                                    entityPermissions={entityHeaderData?.entityPermissions}
                                    entityTargetInfo={entityHeaderData?.entityTargetInfo!}
                                    onChangeEntityPermissions={(data) => onChangeEntityPermissions(data)}
                                    onChangeEntityTargetInfo={(data) => onChangeEntityTargetInfo(data)}
                                    entityType={EntityType.content}
                                />
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Space size={8}>
                        <Button loading={loading} htmlType="submit">Kaydet</Button>
                        {formMode === formModes.EDIT &&
                            <Popconfirm title={"Silmek istediğinize emin misiniz?"} onConfirm={_onDelete} okText="Evet" cancelText="Hayır">
                                <Button className="red" loading={loading}>Sil</Button>
                            </Popconfirm>
                        }
                    </Space>
                </Form.Item>
            </Form>
        </PageBase>
    );
}

export default React.memo(FAQForm);