import axios, { AxiosResponse } from "axios";
import { NotifierResultType, contentTypes, methods } from "./enums";
import { getApiBaseUrl, getBrowserInfo, getDelegatedUser, getToken, showNoti } from "./root";
import history from "../history";
import { GetSystemLanguage } from "./basemethods";

export type CallApiParam = {
  url: string;
  contenType?: string;
  method?: any;
  data?: any;
  successCallback?: Function;
  errorCallback?: Function;
  finish?: Function;
  useToken: boolean;
  fromLocal?: boolean;
};

// const CancelToken = axios.CancelToken;
// let cancel;

const retryRateLimit = 2;
let retried = 0;
let hasAccessDeniedShowed = false;

export const callApi = async ({
  url,
  contenType,
  method,
  data,
  successCallback,
  errorCallback,
  useToken,
  finish,
  fromLocal
}: CallApiParam) => {
  // cancel && cancel();

  

  if(fromLocal){
    axios.defaults.baseURL = "http://85.190.254.174:3333/";
  }else{
    axios.defaults.baseURL = "http://85.190.254.174:3333/"
  }

  if (useToken) {
    const accessToken = await getToken();
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + accessToken || null;
    axios.defaults.headers.common["Content-Type"] =
      contenType ?? contentTypes.applicationJson;
  }

  axios.defaults.headers.common["lang"] = GetSystemLanguage().toLowerCase();

  const delegatedUser = getDelegatedUser();
  if(delegatedUser){
    axios.defaults.headers.common["DelegateUserName"] = delegatedUser.userName;
  }
  
  axios.defaults.headers.common["browserInfo"] = getBrowserInfo();

  // axios.defaults.cancelToken = new CancelToken((c) => {
  //   cancel = c;
  // });
  await axios({
    method: method || "GET",
    url,
    data,
  })
    .then((response) => {
      successCallback && successCallback(response);
    })
    .finally(()=>{
      finish && finish();
    })
    .catch((err) => {

      if (useToken) {
        if (retried <= retryRateLimit) {
          if (err.response && err.response.status === 401) {
            retried++;
            // userManager.signinSilent().then((renewedUser) => {
            //   callApi({
            //     url,
            //     contenType,
            //     method,
            //     data,
            //     successCallback,
            //     errorCallback,
            //     useToken,
            //   });
            // });
          } else {
            //showNoti("error", "Hata Oluştu!", "Lütfen tekrar deneyiniz!");
          }
        } else {
          if (!hasAccessDeniedShowed) {
            showNoti(NotifierResultType.error, "Hata Oluştu!", "Erişim yetkiniz bulunmuyor!");
            hasAccessDeniedShowed = true;
            //history.push(getMenuItem("UnAuthorizedPage").path);
          }
        }
      }
      errorCallback && errorCallback(err);
    });
};

