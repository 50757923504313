import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import QRGenerator from '../pages/Public/VCard';
import { getRandomEmail, getRandomName, getRandomPhoneNumber, getRandomTitle } from '../helpers/samples';
import {  GetIconPathDarkMode } from '../helpers/basemethods';
import { Store } from '../state';
import { useSelector } from 'react-redux';


export interface ViewQrCodeProps {
    className?: "",
    user?: any,
}

const ViewQrCode: React.FC<ViewQrCodeProps> = (props) => {

    const { isDarkMode  } = useSelector((s: Store) => s.common);
    const phoneSrc = GetIconPathDarkMode("scan-qr-code-with-phone",isDarkMode,"svg");
    const { className, user } = props;
    const [qrModalVisible, setQrModalVisible] = useState<boolean>(false);

    useEffect(() => {

    }, [])


    return (
        <React.Fragment>
            <div onClick={() => { setQrModalVisible(true) }} className={`view-qr-code ${className}`}>
                <span className='icon'>
                    {/* <i className="fa fa-qrcode"></i> */}
                    <img src={phoneSrc} />



                </span>
            </div>

            <Modal
                open={qrModalVisible}
                onCancel={() => { setQrModalVisible(false) }}
                footer={null}
                className='view-qr-modal'
            >
                <QRGenerator width={300} fullName={getRandomName()} telephoneNumber={getRandomPhoneNumber()} mobilPhoneNumber={getRandomPhoneNumber()} email={getRandomEmail()} title={getRandomTitle()} />

            </Modal>
        </React.Fragment>

    );
}

export default ViewQrCode;