import { getLanguageResource } from "../../../business/Public/HeaderBusiness";
import { Languages } from "../../../helpers/resources";
import React from "react";

export interface MultiLangHeaderDivProps {
    pageLang:string,
    setPageLang:any
}
const MultiLangHeaderDiv: React.FC<MultiLangHeaderDivProps> = (props) => {   
    return (
            <div className='adminMultiLangHeader'>
                {                        
                    Languages.map((lang) => ((
                        <span> <img className={lang.key==props.pageLang ? "selectedLangImg" : ""} src={getLanguageResource(lang.key)} alt={lang.label} onClick={() => { props.setPageLang(lang.key) }}/></span>
                    )))                            
                }
            </div>
    )

}

export default MultiLangHeaderDiv;
