import React from 'react';
import { logoPath, logoAdminPath } from '../helpers/root';
import history from '../history';

export interface LogoProps {
    isAdmin?:boolean;
}
 
const Logo: React.FC<LogoProps> = ({isAdmin}) => {
    console.log(logoPath);
    return ( 
        <div className="logo">
            <div className="effect-1" />
            <div className="effect-2" />
            <a onClick={() => { history.push(`/`) }}><img src={`${isAdmin ? logoAdminPath : logoPath}`} alt="VNS Intranet" /></a>
        </div>
     );
}
 
export default Logo;