import moment from 'moment';
import React from 'react';
import { GetResourceData } from '../../../helpers/basemethods';

const Footer = () => {

    return (
        <footer>
            <div className='footer-wrapper'>
                <div className='logo'>
                    <a href='https://www.vnsteknoloji.com/' target='_blank'>
                        <img height={50} src={require("../../../assets/images/icons/companyLogo.svg")} />
                    </a>
                </div>
                <div className='copyright'>
                    <a href={"#"}>{GetResourceData("ProcessingOfPersonalData")}</a>
                    <span>Copyright {moment().format("YYYY")} {GetResourceData("AllRightsReserved")}</span>
                </div>
            </div>
        </footer>
    )
}

export default React.memo(Footer)