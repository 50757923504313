import React, { useEffect, useState } from "react";
import { useQRCode } from 'next-qrcode';
import VCard from "vcard-creator";
import { PageBase } from "../../../components";
import { Badge, Card, Col, Divider, List, Row, Table, Tabs, TabsProps } from "antd";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import DayoffRequest from "../../../components/DayoffRequest";
import MyRequest from "../../../components/MyRequests";
import MyTasks from "../../../components/MyTasks";
import ExpenseRequest from "../../../components/ExpenseRequest";
import { GetResourceData } from "../../../helpers/basemethods";
import { getUrlParamValue } from "../../../helpers/root";
import history from "../../../history";
import { setCorporateTabChoice } from "../../../state/reducers/CommonReducer";
import { Store } from "../../../state";
import { isMobile } from "react-device-detect";
import BTRequest from "../../../components/BTRequest";
import AdministrativeRequest from "../../../components/AdministrativeRequest";
import TravelRequest from "../../../components/TravelRequest";
//import "yet-another-react-lightbox/styles.css";

export interface CorporateDemandsProps { }
const CorporateDemands: React.FC<CorporateDemandsProps> = () => {
  
const dispatch = useDispatch();
const { corporateTabChoice } = useSelector((s: Store) => s.common);
const tabChoice = getUrlParamValue("tab") ?? "0";
const [activeTab, setActiveTab] = useState<string>(corporateTabChoice);

  if(tabChoice!="0"){
    dispatch(setCorporateTabChoice(tabChoice));
    history.push("/corporate-demands")
    
  }

  const waitingApprovals = {
    waitingMyDemands : "3",
    waitingMyConfirmations : "5"    
  }

  const myTasks = () => {
    return (
      <Badge size="small" count={waitingApprovals.waitingMyConfirmations}>
        <span><i className="fal fa-thumbs-up"></i> {GetResourceData("Confirmations")}</span>
      </Badge>
    )
  }
  const myRequests = () => {
    return (
      <Badge size="small" count={waitingApprovals.waitingMyDemands}>
        <span><i className="fal fa-clipboard-list"></i> {GetResourceData("Demands")}</span>
      </Badge>
    )
  }
  const items: TabsProps['items'] = [
      {
        key: '1',
        label: <><i className="fal fa-umbrella-beach"></i> {GetResourceData("DayOffRequests")}</> ,
        children: <DayoffRequest/>
      },
      {
        key: '2',
        label: <><i className="fal fa-money-bill"></i> {GetResourceData("CostRequests")}</> ,
        children: <ExpenseRequest/>
      },
      {
        key: '3',
        label: <><i className="fal fa-plane"></i> {GetResourceData("VacationRequests")}</> ,
        children: <><div style={{margin:"20px"}}>{GetResourceData("ComingSoon")}</div></> 
        // children: <TravelRequest/>

      },
      {
        key: '4',
        label: <><i className="fal fa-laptop"></i> {GetResourceData("ITRequest")}</> ,
        children: <BTRequest/>
      },
      {
        key: '5',
        label: <><i className="fal fa-user"></i> {GetResourceData("AdministrativeRequest")}</> ,
        children: <AdministrativeRequest/>
      },
      {
        key: '6',
        label: myRequests(),
        children: <MyRequest/>
      },
      {
        key: '7',
        label: myTasks(),
        children: <MyTasks/>
      },
    ];

    const onChangeTab = (key) => {
      setActiveTab(key);
    };

    const activeKeyLeft = () => {
      let _activeKeyNum = parseInt(activeTab)
      if(_activeKeyNum>1){
        setActiveTab((_activeKeyNum-1).toString())
      }
    }

    const activeKeyRight = () => {
      let _activeKeyNum = parseInt(activeTab)
      if(_activeKeyNum<items.length){
        setActiveTab((_activeKeyNum+1).toString())
      }
    }

    return (
        <PageBase transparent>
        <React.Fragment>      

          

            <div className="intranet-content-card corporate-demand">
            <div className="intranet-content-card-header">
            <div className="title">
                <h2>{GetResourceData("CorporateDemands")}</h2>
            </div>
            </div>
            <div className="intranet-content-card-body corporateBody">
              {
                isMobile &&
                <>
                  <a style={{display: parseInt(activeTab)==1 ? "none" : "block"}} onClick={()=>{ activeKeyLeft() }}><div className="corporateButtons left" ><LeftOutlined /></div></a>
                  <a style={{display: parseInt(activeTab)==items.length ? "none" : "block"}} onClick={()=>{ activeKeyRight() }}><div className="corporateButtons right"><RightOutlined /></div></a>
                </>
              }
              
                <Tabs
                    defaultActiveKey={corporateTabChoice} 
                    activeKey={activeTab} 
                    onChange={onChangeTab}
                    items={items}
                    type="card"
                    tabPosition="top"
                    
                />
            </div>
        </div> 
        </React.Fragment>
      </PageBase>
    );
};

export default CorporateDemands;