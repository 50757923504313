export const Languages = [
    {
        "key": "Tr",
        "label" : "Türkçe"
    },
    {
        "key": "En",
        "label" : "English"
    }
  ]
  
export const ValidLanguages = [
    "Tr",
    "En"
]

export const LanguageTr = "Tr";
export const LanguageEn = "En";
export const ResourceData = {
    Tr :{
        valTr : "Türkçe",
        valEn : "Turkish"        
    },
    En :{
        valTr : "İngilizce",
        valEn : "English"        
    },
    Hello : {
        valTr : "Merhaba",
        valEn : "Hello"
    },    
    SearchContainerPlaceHolder : {
        valTr : "Kişi veya içerik aramalarınızı buradan yapabilirsiniz",
        valEn : "You can search for people or content here"
    },    
    FrequentlySarched : {
        valTr : "Sık arananlar",
        valEn : "Frequently searched"
    },    
    MoodSelection : {
        valTr : "Mood Seç:",
        valEn : "Select Mood:"
    },       
    OfficeSelection : {
        valTr : "Lokasyon:",
        valEn : "Location:"
    },    
    MyProfile : {
        valTr : "Profilim:",
        valEn : "My Profile:"
    },        
    Signature : {
        valTr : "Outlook İmzam",
        valEn : "Outlook Signature"
    },        
    Registry : {
        valTr : "Sicil",
        valEn : "Registry"
    },        
    Department : {
        valTr : "Departman",
        valEn : "Department"
    },       
    Manager : {
        valTr : "Yönetici",
        valEn : "Manager"
    },
    Sunday : {
        valTr : "PAZ",
        valEn : "SUN"
    },    
    Monday : {
        valTr : "PZT",
        valEn : "MON"
    },    
    Tuesday : {
        valTr : "SAL",
        valEn : "TUE"
    },    
    Wednesday : {
        valTr : "ÇAR",
        valEn : "WED"
    },    
    Thursday : {
        valTr : "PER",
        valEn : "THU"
    },    
    Friday : {
        valTr : "CUM",
        valEn : "FRI"
    },    
    Saturday : {
        valTr : "CTS",
        valEn : "SAT"
    },    
    Weekly : {
        valTr : "Haftalık",
        valEn : "Weekly"
    },    
    NoEvents : {
        valTr : " Bugün herhangi bir etkinlik yok",
        valEn : " There are no events today"
    },       
    EventCalender : {
        valTr : " Etkinlik Takvimi",
        valEn : " Event Calender"
    },  
    MonthlyView : {
        valTr : "Aylık Görünüm",
        valEn : "Monthly View"
    },    
    MostThankful : {
        valTr : "En Çok Teşekkür Edenler",
        valEn : "Most Thankful People"
    },    
    MostThankYouRecipients : {
        valTr : "En Çok Teşekkür Alanlar",
        valEn : "Most Thank You Recipients"
    },    
    ThankYouSystem : {
        valTr : "TEŞEKKÜR SİSTEMİ",
        valEn : "THANK YOU SYSTEM"
    },    
    ThankYouSelectMessage : {
        valTr : "Teşekkür etmek istediğiniz kişiyi aramadan seçiniz. Birden fazla seçim için kişi ekleyebilirsiniz.",
        valEn : "Select the person you want to thank from the search. You can add contacts for multiple selections."
    },    
    SearchByName : {
        valTr : "Ad soyada göre ara",
        valEn : "Search by name and surname"
    },    
    Search : {
        valTr : "Ara",
        valEn : "Search"
    },    
    PeopleToSend : {
        valTr : "GÖNDERİLECEK KİŞİLER",
        valEn : "PEOPLE TO SEND"
    },    
    FromTheSearchField : {
        valTr : "Arama kısmından, teşekkür etmek istediğiniz kişi veya kişileri bu alana eklemelisiniz!",
        valEn : "From the search field, you should add the person or people you want to thank in this field!"
    },    
    ThankYouCategory : {
        valTr : "TEŞEKKÜR KATEGORİSİ",
        valEn : "THANK YOU CATEGORY"
    },    
    FirstAddContact : {
        valTr : "Kişi eklemeden teşekkür kategorisi seçemezsiniz!",
        valEn : "You cannot select a thank you category without adding a contact!"
    },    
    PickAThankYouCategory : {
        valTr : "Teşekkür kategorisi seçiniz.",
        valEn : "You cannot select a thank you category without adding a contact!"
    },    
    ExplanationText : {
        valTr : "AÇIKLAMA METNİ",
        valEn : "EXPLANATION TEXT"
    },    
    FirstPickContactToExp : {
        valTr : "Kişi eklemeden açıklama metni giremezsiniz!",
        valEn : "You cannot enter a description text without adding a contact!"
    },    
    EnterDesc : {
        valTr : "Açıklama Metni giriniz.",
        valEn : "Enter Description Text."
    },    
    PleaseWaitUpper : {
        valTr : "LÜTFEN BEKLEYİNİZ",
        valEn : "PLEASE WAIT"
    },    
    SendUpper : {
        valTr : "GÖNDER",
        valEn : "SEND"
    },    
    WhatComesToMe : {
        valTr : "Bana Gelenler: ",
        valEn : "What Comes to Me: "
    },    
    DateYouWereThanked : {
        valTr : " Tarihinde Teşekkür Etti.",
        valEn : " is the date you were thanked."
    },    
    MySubmissions : {
        valTr : "Benim Gönderdiklerim:",
        valEn : "My Submissions:"
    },    
    DateYouThanked : {
        valTr : "Tarihinde Teşekkür Ettin",
        valEn : "is the date you thanked."
    },    
    NoIdeasOpenForVoting : {
        valTr : "Oylamaya açık fikir bulunmamaktadır.",
        valEn : "There are no ideas open for voting."
    },    
    IdeaBoard : {
        valTr : "Fikir Tahtası",
        valEn : "Idea Board"
    },    
    InnovativeIdea : {
        valTr : "Yenilikçi Fikir",
        valEn : "Innovative Idea"
    },    
    MakingLifeEasier : {
        valTr : "Hayatı Kolaylaştıran",
        valEn : "Making Life Easier"
    },       
    BudgetSaving : {
        valTr : "Bütçe Tasarrufu",
        valEn : "Budget Saving"
    },   
    VoteForTheIdea : {
        valTr : "Fikrini Oyla!",
        valEn : " Vote for the Idea!"
    },    
    IdeaTitle : {
        valTr : "Fikir Başlığı",
        valEn : "Idea Title"
    },    
    IdeaExplanation : {
        valTr : "Fikir Açıklaması",
        valEn : "Idea Explanation"
    },    
    HowUseful : {
        valTr : "Bu Öneriyi Ne Kadar Faydalı Buldun?",
        valEn : "How useful did you find this suggestion?"
    },        
    AlreadyVoted : {
        valTr : "Bu Öneriyi Daha Önce Oyladın.",
        valEn : "You have voted on this proposal before."
    },  
    LookAtOtherdays : {
        valTr : "Diğer günlere göz atabilirsiniz.",
        valEn : "You can take a look at the other days."
    },    
    Celebrate : {
        valTr : "Tebrik Et",
        valEn : "Celebrate"
    },    
    BornThisWeek : {
        valTr : "Bu Hafta Doğanlar",
        valEn : "Born This Week"
    },    
    HappyBirthday : {
        valTr : "Doğum Gününü Kutla!",
        valEn : "Happy Birthday!"
    },    
    ChooseTemplate : {
        valTr : "Bir Template seçin",
        valEn : "Choose a Template"
    },    
    HappyBirthdayToYou : {
        valTr : "Doğum Günün Kutlu Olsun",
        valEn : "Happy Birthday To You"
    },    
    TextColor : {
        valTr : "Yazı Rengi",
        valEn : "Text Color"
    },    
    BirthdayMessage : {
        valTr : "Doğum Günü Mesajınız",
        valEn : "Birthday Message"
    },    
    HideTitle : {
        valTr : "Başlığı Gizle",
        valEn : "Hide Title"
    },    
    SendingUpper : {
        valTr : "GÖNDERİLİYOR, LÜTFEN BEKLEYİNİZ",
        valEn : "SENDING, PLEASE WAIT"
    },    
    Title : {
        valTr : "Başlık",
        valEn : "Title"
    }, 
    Message : {
        valTr : "Mesajınız",
        valEn : "Message"
    }, 
    MealOfDay : {
        valTr : "Günün Yemeği",
        valEn : "Meal of the Day"
    }, 
    MainMeal : {
        valTr : "Ana Yemek",
        valEn : "Main Course"
    }, 
    Salads : {
        valTr : "Salatalar",
        valEn : "Salads"
    }, 
    Deserts : {
        valTr : "Tatlılar",
        valEn : "Deserts"
    }, 
    NoMenuForToday : {
        valTr : "Bugün için herhangi bir menü yok",
        valEn : "There is no menu for today"
    }, 
    AllergenGroup : {
        valTr : " alerjen grubu",
        valEn : " allergen group"
    }, 
    Milk : {
        valTr : "Süt",
        valEn : "Milk"
    }, 
    Egg : {
        valTr : "Yumurta",
        valEn : "Egg"
    }, 
    Celery : {
        valTr : "Kereviz",
        valEn : "Celery"
    }, 
    Peanut : {
        valTr : "Fıstık",
        valEn : "Peanut"
    }, 
    Fish : {
        valTr : "Balık",
        valEn : "Fish"
    },
    Gluten: {
        valTr: "Gluten",
        valEn: "Gluten"
    },
    Soy: {
        valTr: "Soya",
        valEn: "Soy"
    },
    Lentil: {
        valTr: "Acı Bakla",
        valEn: "Lentil"
    },
    Hazelnut: {
        valTr: "Fındık",
        valEn: "Hazelnut"
    },
    Almond: {
        valTr: "Badem",
        valEn: "Almond"
    },
    Mustard: {
        valTr: "Hardal",
        valEn: "Mustard"
    },
    Shellfish: {
        valTr: "Kabuklu Deniz Ürünleri",
        valEn: "Shellfish"
    },
    Sulfite: {
        valTr: "Sülfat",
        valEn: "Sulfite"
    },
    Honey: {
        valTr: "Bal",
        valEn: "Honey"
    },
    Corn: {
        valTr: "Mısır",
        valEn: "Corn"
    },
    Sesame: {
        valTr: "Susam",
        valEn: "Sesame"
    },
    Sugar: {
        valTr: "Şeker",
        valEn: "Sugar"
    },
    Spice: {
        valTr: "Baharat",
        valEn: "Spice"
    },
    Vegetarian: {
        valTr: "Vejetaryan",
        valEn: "Vegetarian"
    },
    Organic: {
        valTr: "Organik",
        valEn: "Organic"
    },
    GMO: {
        valTr: "GDO",
        valEn: "GMO"
    },
    Mollusk: {
        valTr: "Yumuşakçalar",
        valEn: "Mollusk"
    },
    SO2: {
        valTr: "SO2",
        valEn: "SO2"
    },
    Mushroom: {
        valTr: "Mantar",
        valEn: "Mushroom"
    },
    Alcohol: {
        valTr: "Alkol",
        valEn: "Alcohol"
    },
    Newcomers: {
        valTr: "Aramıza Yeni Katılanlar",
        valEn: "Newcomers"
    },
    NoRecordsToShow: {
        valTr: "Gösterilecek herhangi bir kayıt yok",
        valEn: "There are no records to show"
    },
    NewsFromUs: {
        valTr: "Bizden Haberler",
        valEn: "News From Us"
    },
    Searching: {
        valTr: "Arama yapılıyor...",
        valEn: "Searching..."
    },
    News: {
        valTr: "Genel Haberler :",
        valEn: "News :"
    },
    CopySignature: {
        valTr: "İmzayı kopyala",
        valEn: "Copy signature"
    },
    ShowAll: {
        valTr: "Tümünü Görüntüle",
        valEn: "Show All"
    },
    NoPendingJob: {
        valTr: "Bekleyen herhangi bir iş bulunmamaktadır",
        valEn: "There are no pending jobs"
    },
    Content: {
        valTr: "İçerik",
        valEn: "Content"
    },
    Person: {
        valTr: "Kişi",
        valEn: "Person"
    },
    Document: {
        valTr: "Doküman",
        valEn: "Document"
    },
    Link: {
        valTr: "Link",
        valEn: "Link"
    },
    CorporateDocuments: {
        valTr: "Kurumsal Dokümanlar",
        valEn: "Corporate Documents"
    },
    TaskTracking: {
        valTr: "Görev Yönetimi",
        valEn: "Task Management"
    },
    CorporateDemands: {
        valTr: "Kurumsal Talepler",
        valEn: "Corporate Demands"
    },
    Admin: {
        valTr: "Admin",
        valEn: "Admin"
    },
    NoCurrentNews: {
        valTr: "Güncel haber bulunmamaktadır.",
        valEn: "No current news available."
    },
    FAQS: {
        valTr: "Sıkça Sorulan Sorular",
        valEn: "Frequently Asked Questions"
    },
    CorporateTemplates: {
        valTr: "Kurumsal Şablonlar",
        valEn: "Corporate Templates"
    },
    CorporatePhotos: {
        valTr: "Kurumsal Fotoğraflar",
        valEn: "Corporate Photos"
    },
    CorporateVideos: {
        valTr: "Kurumsal Videolar",
        valEn: "Corporate Videos"
    },
    Item: {
        valTr: " Öğe",
        valEn: " Item"
    },
    Items: {
        valTr: " Öğe",
        valEn: " Items"
    },
    Confirmations: {
        valTr: "Onaylarım",
        valEn: "My Confirmations"
    },
    Demands: {
        valTr: "Taleplerim",
        valEn: "My Demands"
    },
    DayOffRequests: {
        valTr: "İzin Talep",
        valEn: "Day-off Request"
    },
    CostRequests: {
        valTr: "Masraf Talep",
        valEn: "Cost Request"
    },
    VacationRequests: {
        valTr: "Seyahat Talep",
        valEn: "Travel Request"
    },
    ITRequest: {
        valTr: "BT Talep",
        valEn: "IT Request"
    },
    AdministrativeRequest: {
        valTr: "İdari İşler Talep",
        valEn: "Administrative Request"
    },
    StartDate: {
        valTr: "Başlangıç Tarihi",
        valEn: "Start Date"
    },
    EndDate: {
        valTr: "Bitiş Tarihi",
        valEn: "End Date"
    },
    DayOffType: {
        valTr: "İzin Türü",
        valEn: "Day-Off Type"
    },
    SelectDayOffType: {
        valTr: "İzin Türü Seçiniz!",
        valEn: "Select Day-Off Type!"
    },
    DelegateUser: {
        valTr: "Vekalet Bırakılacak Kişi",
        valEn: "Person to Delegate"
    },
    Description: {
        valTr: "Açıklama",
        valEn: "Description"
    },
    Demand: {
        valTr: "Talep Et",
        valEn: "Demand"
    },
    AvailableBalance: {
        valTr: "Mevcut İzin:",
        valEn: "Available Balance:"
    },
    DayOffBeUsed: {
        valTr: "Kullanılan İzin Gün Sayısı",
        valEn: "Number of Day-off Days Be Used"
    },
    StartOfWeek: {
        valTr: "İş Başı Tarihi",
        valEn: "Start of Work"
    },
    CurrencyCode: {
        valTr: "Para Birimi",
        valEn: "Currency Code"
    },
    CostItem: {
        valTr: "Masraf Kalemi",
        valEn: "Cost Item"
    },
    Add: {
        valTr: "Ekle",
        valEn: "Add"
    },
    CostDemand: {
        valTr: "Masraf Talebi",
        valEn: "Cost Demand"
    },
    CostType: {
        valTr: "Masraf Türü",
        valEn: "Cost Type"
    },
    Date: {
        valTr: "Tarih",
        valEn: "Date"
    },
    Amount: {
        valTr: "Tutar",
        valEn: "Amount"
    },
    Delete: {
        valTr: "Sil",
        valEn: "Delete"
    },
    DemandDetail: {
        valTr: "Talep Detayı",
        valEn: "Demand Detail"
    },
    ComingSoon: {
        valTr: "Çok Yakında !",
        valEn: "Coming Soon !"
    },
    SendThanks: {
        valTr: "Teşekkür Et",
        valEn: "Send Thanks"
    },
    OpenRequests: {
        valTr: "Açık Taleplerim",
        valEn: "Open Requests"
    },
    RequestNo: {
        valTr: "Talep No",
        valEn: "Request No"
    },
    RequestTypeDate: {
        valTr: "Talep Türü/Tarihi",
        valEn: "Request Type/Date"
    },
    RequestType: {
        valTr: "Talep Türü",
        valEn: "Request Type"
    },
    State: {
        valTr: "Durum",
        valEn: "State"
    },
    Detail: {
        valTr: "Detay",
        valEn: "Detail"
    },
    CancelRequest: {
        valTr: "Talebi İptal Et",
        valEn: "Cancel Request"
    },
    CancelRequestQuestion: {
        valTr: "Talebi İptal Etmek İstiyor Musunuz?",
        valEn: "Do you want to cancel the request?"
    },
    CancelTask: {
        valTr: "Görevi İptal Et",
        valEn: "Cancel Task"
    },
    CancelTaskQuestion: {
        valTr: "Görevi İptal Etmek İstiyor Musunuz?",
        valEn: "Do you want to cancel the task?"
    },
    Yes: {
        valTr: "Evet",
        valEn: "Yes"
    },
    No: {
        valTr: "Hayır",
        valEn: "No"
    },
    ClosedRequests: {
        valTr: "Kapanmış Taleplerim",
        valEn: "Closed Requests"
    },
    RequestDate: {
        valTr: "Talep Tarihi",
        valEn: "Request Date"
    },
    RequestState: {
        valTr: "Talep Durumu",
        valEn: "Request State"
    },
    RequestHistory: {
        valTr: "Talep Geçmişi",
        valEn: "Request History"
    },
    Process: {
        valTr: "İşlem",
        valEn: "Process"
    },
    PerformedBy: {
        valTr: "Gerçekleştiren",
        valEn: "Performed by"
    },
    SearchResults: {
        valTr: "ARAMA SONUÇLARI",
        valEn: "SEARCH RESULTS"
    },
    YouCanSearchHere: {
        valTr: "Kişi veya içerik aramalarınızı buradan yapabilirsiniz",
        valEn: "You can search for people or content here"
    },
    ResultNotFound: {
        valTr: "Sonuç Bulunamadı",
        valEn: "Result Not Found"
    },
    Details: {
        valTr: "Detayına Git",
        valEn: "Details"
    },
    OrgTitle: {
        valTr: "Unvan",
        valEn: "Title"
    },
    HireDate: {
        valTr: "İşe Başlama",
        valEn: "Hire Date"
    },
    GSM: {
        valTr: "GSM",
        valEn: "GSM"
    },
    ShortCode: {
        valTr: "Kısa Kod",
        valEn: "Short Code"
    },
    BirthDate: {
        valTr: "Doğum Tarihi",
        valEn: "Birth Date"
    },
    WorkOffice: {
        valTr: "Çalıştığı Ofis",
        valEn: "Work Office"
    },
    Email: {
        valTr: "E-posta",
        valEn: "Email"
    },
    Download: {
        valTr: "İndir",
        valEn: "Download"
    },
    UserNotFound: {
        valTr: "Kullanıcı Bulunamadı",
        valEn: "User Not Found"
    },
    PendingApproval: {
        valTr: "Onayımda Bekleyenler",
        valEn: "Pending Approval"
    },
    Approve: {
        valTr: "Onayla",
        valEn: "Approve"
    },
    Reject: {
        valTr: "Reddet",
        valEn: "Reject"
    },
    ConfirmRequest: {
        valTr: "Talebi Onaylamak İstiyor Musunuz?",
        valEn: "Do You Want to Confirm the Request?"
    },
    RejectRequest: {
        valTr: "Talebi Onaylamak İstiyor Musunuz?",
        valEn: "Do you want to reject the request?"
    },
    PassedMyApproval: {
        valTr: "Onayımdan Geçenler",
        valEn: "Passed My Approval"
    },
    NewsArchive: {
        valTr: "Haber Arşivi",
        valEn: "News Archive"
    },
    IHaveAnIdea: {
        valTr: "Fikrim Var !",
        valEn: "I Have an Idea !"
    },
    AddTask: {
        valTr: "Görev Ekle",
        valEn: "Add Task"
    },
    GoToMyProfile: {
        valTr: "Profilim Sayfasına Git",
        valEn: "Go To My Profile"
    },
    IdeaType: {
        valTr: "Fikir Türü",
        valEn: "Idea Type"
    },
    SeeAllIdeas: {
        valTr: "Tüm Fikirleri Gör",
        valEn: "See All Ideas"
    },
    IdeaOwner: {
        valTr: "Fikir Sahibi",
        valEn: "Idea Owner"
    },
    IdeaDate: {
        valTr: "Fikir Tarihi",
        valEn: "Idea Date"
    },
    IdeaRate: {
        valTr: "Fikir Puanı",
        valEn: "Idea Rate"
    },
    IdeaTypes: {
        valTr: "Fikir Türleri",
        valEn: "Idea Types"
    },
    Technology: {
        valTr: "Teknoloji",
        valEn: "Technology"
    },
    SocialResponsibility: {
        valTr: "Sosyal Sorumluluk",
        valEn: "Social Responsibility"
    },
    EntertainmentAndHobby: {
        valTr: "Eğlence ve Hobi",
        valEn: "Entertainment and Hobby"
    },
    Education: {
        valTr: "Eğitim",
        valEn: "Education"
    },
    SurveyNo: {
        valTr: "Anket No",
        valEn: "Survey No"
    },
    SurveyDate: {
        valTr: "Anket Tarihi",
        valEn: "Survey Date"
    },
    SurveyAnswerDate: {
        valTr: "Anket Doldurma Tarihi",
        valEn: "Survey Answer Date"
    },
    SurveyState: {
        valTr: "Anket Durumu",
        valEn: "Survey State"
    },
    SurveyTitle: {
        valTr: "Anket Başlığı",
        valEn: "Survey Title"
    },
    AllIdeas: {
        valTr: "Tüm Fikirler",
        valEn: "All Ideas"
    },
    MyIdeas: {
        valTr: "Fikirlerim",
        valEn: "My Ideas"
    },
    AdminMultiLangInfo : {        
        valTr: "{0} dil seçeneği için görünecek değeri giriniz.",
        valEn: "Enter the value that will appear for the {0} language option."
    },
    AdminMultiLangImageInfo : {        
        valTr: "{0} dil seçeneği için görünecek imajı seçiniz.",
        valEn: "Select the image that will appear for the {0} language option."
    },
    AdminBannerFormTitle : {        
        valTr: "Başlık",
        valEn: "Title"
    },
    AdminBannerFormDesc : {        
        valTr: "Açıklama",
        valEn: "Description"
    },
    AdminBannerFormImage : {        
        valTr: "Görsel",
        valEn: "Image"
    },
    CancelIdea : {        
        valTr: "Fikir Önerisini İptal Et",
        valEn: "Cancel The Idea Suggestion"
    },
    CancelIdeaQuestion : {        
        valTr: "Fikir Önerisini İptal Etmek İstediğine Emin Misin",
        valEn: "Are You Sure You Want to Cancel the Idea Suggestion"
    },
    ProcessingOfPersonalData : {        
        valTr: "Kişisel Verilerin İşlenmesi",
        valEn: "Processing of Personal Data"
    },
    AllRightsReserved : {        
        valTr: "Tüm Hakları Saklıdır",
        valEn: "All Rights Reserved"
    },
    DoNotShowAgain : {        
        valTr: "Bir daha gösterme",
        valEn: "Do Not Show Again"
    },
    RemoteWorkSurvey : {        
        valTr: "Uzaktan Çalışma Anketi",
        valEn: "Remote Work Survey"
    },
    AnnualPaidDayOff : {        
        valTr: "Yıllık Ücretli İzin",
        valEn: "Annual Paid DayOff"
    },
    PaternityLeave : {        
        valTr: "Babalık İzni",
        valEn: "Paternity Leave"
    },
    FreeDayoff : {        
        valTr: "Ücretsiz İzin",
        valEn: "Free Dayoff"
    },
    Close : {        
        valTr: "Kapat",
        valEn: "Close"
    },
    CloseUPPER : {        
        valTr: "KAPAT",
        valEn: "CLOSE"
    },
    HRAprovalNeeded : {        
        valTr: "*İK onayı gerektirir temalı bir uyarı mesajı",
        valEn: "*A warning message with the theme *Requires HR approval"
    },
    MyIdeaSuggestions : {        
        valTr: "Fikir Önerilerim",
        valEn: "My Idea Suggestions"
    },
    MySurveyAnswers : {        
        valTr: "Anket Yanıtlarım",
        valEn: "My Survey Answers"
    },
    AppreciationsToMe : {        
        valTr: "Teşekkürlerim",
        valEn: "Appreciations to Me"
    },
    AppreciationsByMe : {        
        valTr: "Ettiğim Teşekkürler",
        valEn: "Appreciations by Me"
    },
    DemoSurveyQuestion : {        
        valTr: "Çalışma modelimiz nasıl olsun ?",
        valEn: "How should our working model be ?"
    },
    requestTypeProblem : {        
        valTr: "Sorun",
        valEn: "Problem"
    },
    requestTypeRequest : {        
        valTr: "Talep",
        valEn: "Request"
    },
    requestTypeCategory : {        
        valTr: "Kategori",
        valEn: "Category"
    },
    selectRequestTypeCategory : {        
        valTr: "Kategori seçiniz.",
        valEn: "Pick a category."
    },
    BTRequestType : {        
        valTr: "Talep Tipi",
        valEn: "Request Type"
    },
    ShortDesc : {        
        valTr: "Kısa Açıklama",
        valEn: "Short Description"
    },
    FileAttachment : {        
        valTr: "Dosya Eki",
        valEn: "File attachment"
    },
    ShortDescriptionPlaceHolder : {        
        valTr: "Kısa açıklama giriniz.",
        valEn: "Enter short description."
    },
    DescriptionPlaceHolder : {        
        valTr: "Açıklama giriniz.",
        valEn: "Enter description."
    },
    FileUploadMainMessage : {        
        valTr: "Buraya tıklayarak ya da sürükleyerek dosya yükleyebilirsiniz.",
        valEn: "You can upload files by clicking or dragging here."
    },
    FileUploadCountMessage : {        
        valTr: "Birden fazla dosya yükleyebilirsiniz.",
        valEn: "You can upload multiple files."
    },
    CorporateAppsERP : {        
        valTr: "Kurumsal Uygulamalar - ERP",
        valEn: "Enterprise Applications - ERP"
    },
    CorporateAppsCRM : {        
        valTr: "Kurumsal Uygulamalar - CRM",
        valEn: "Enterprise Applications - CRM"
    },
    CorporateAppsIK : {        
        valTr: "Kurumsal Uygulamalar - IK",
        valEn: "Enterprise Applications - HR"
    },
    CorporateAppsOther : {        
        valTr: "Kurumsal Uygulamalar - Diğer",
        valEn: "Enterprise Applications - Other"
    },
    Computer : {        
        valTr: "Bilgisayar",
        valEn: "Computer"
    },
    Phone : {        
        valTr: "Telefon",
        valEn: "Phone"
    },
    Monitor : {        
        valTr: "Monitör",
        valEn: "Monitor"
    },
    OtherBT : {        
        valTr: "Diğer BT Ekipmanı",
        valEn: "Other IT Equipment"
    },
    Auth : {        
        valTr: "Yetkilendirme",
        valEn: "Authorization"
    },
    OfficeApps : {        
        valTr: "Ofis Uygulamaları",
        valEn: "Office Applications"
    },
    SecurityApps : {        
        valTr: "Güvenlik Uygulamaları",
        valEn: "Security Applications"
    },
    Other : {        
        valTr: "Diğer",
        valEn: "Other"
    },
    OfficeFurn : {        
        valTr: "Ofis Mobilyaları",
        valEn: "Office Furniture"
    },
    Lightning : {        
        valTr: "Aydınlatma",
        valEn: "Lightning"
    },
    BusinessCardRequest : {        
        valTr: "Kartvizit Talep",
        valEn: "Business Card Request"
    },
    Cleaning : {        
        valTr: "Temizlik",
        valEn: "Cleaning"
    },
    ServiceTransportation : {        
        valTr: "Servis Ulaşım",
        valEn: "Service Transportation"
    },
    Catering : {        
        valTr: "İkram",
        valEn: "Catering"
    },
    travelTypeDomestic : {        
        valTr: "Yurt İçi",
        valEn: "Domestic"
    },
    travelTypeAbroad : {        
        valTr: "Yurt Dışı",
        valEn: "Abroad"
    },
    travelCountry : {        
        valTr: "Ülke",
        valEn: "Country"
    },
    travelCity : {        
        valTr: "Şehir",
        valEn: "City"
    },
    travelReason : {        
        valTr: "Seyahat Nedeni",
        valEn: "Travel Reason"
    },
    selectTravelReason : {        
        valTr: "Seyahat Nedeni Seçiniz",
        valEn: "Pick a Travel Reason"
    },
    Meeting : {        
        valTr: "Toplantı",
        valEn: "Meeting"
    },
    Fair : {        
        valTr: "Fuar",
        valEn: "Fair"
    },
    CustomerVisit : {        
        valTr: "Müşteri Ziyareti",
        valEn: "Customer Visit"
    },
    ProjectWork : {        
        valTr: "Proje Çalışması",
        valEn: "Project Work"
    },
    IsTravelReservationNeeded : {        
        valTr: "Rezervasyon Gerekli mi ?",
        valEn: "Is Reservation Required ?"
    },
    TravelItem : {        
        valTr: "Seyahat Kalemi",
        valEn: "Travel Item"
    },
    Accommodation : {        
        valTr: "Konaklama",
        valEn: "Accommodation"
    },
    Airplane : {        
        valTr: "Uçak",
        valEn: "Airplane"
    },
    Train : {        
        valTr: "Tren",
        valEn: "Train"
    },
    Bus : {        
        valTr: "Otobüs",
        valEn: "Bus"
    },
    Visa : {        
        valTr: "Vize",
        valEn: "Visa"
    },
    QualityDocuments : {        
        valTr: "Kalite Dokümanları",
        valEn: "Quality Documents"
    },
    ProductAndServices : {        
        valTr: "Ürün ve Hizmetlerimiz",
        valEn: "Product And Services"
    },
    EducationLibrary : {        
        valTr: "Eğitim Kütüphanesi",
        valEn: "Education Library"
    }
} 