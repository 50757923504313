import React from 'react';
import { Link } from 'react-router-dom';
import { getMenuItem } from '../helpers/menu';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { getHistoryList } from '../history';


export default (props) => {

    const { title } = props;
    let pathSnippets = window.location.pathname.split('/').filter(i => i);

    const historyList = getHistoryList();

    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        const text = url && getMenuItem(null, url) && getMenuItem(null, url).text;

        let item: { url: string | null, text: string | null } = { url, text };
        if (title && (pathSnippets.length === (index + 1))) {
            item.url = null;
            item.text = title;
        }

        // match url from history. For query string added urls 
        const foundUrlFromHistory = historyList.find(f => f.path === item.url);

        const pathToNavigate = foundUrlFromHistory ?
            `${foundUrlFromHistory.path}${foundUrlFromHistory.search}${foundUrlFromHistory.hash}`
            : item.url;
        return (
            <Breadcrumb.Item key={pathToNavigate}>
                {
                    pathToNavigate ?
                        <Link to={pathToNavigate!}>{item.text}</Link> :
                        <a>{item.text}</a>
                }
            </Breadcrumb.Item>
        );
    });

    const homeMenuItem = getMenuItem('Home');
    const breadcrumbItems = [
        <Breadcrumb.Item key={homeMenuItem.val} >
            <Link to={homeMenuItem.path}><HomeOutlined />  {homeMenuItem.text}</Link>
        </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems)

    return <Breadcrumb className='breadcrumb'>{breadcrumbItems}</Breadcrumb>;
}