import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../state';
import Marquee from "react-fast-marquee";
import { Content } from 'antd/lib/layout/layout';
import { UserOutlined, LockOutlined, SearchOutlined } from '@ant-design/icons';
import { getPublicNews } from '../state/reducers/HomePageReducer';
import { AutoComplete, Button, Card, Col, DatePicker, Descriptions, DescriptionsProps, Divider, Form, Input, Row, Select, SelectProps } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { GetOperationResourceData, GetResourceData, GetSystemLanguage, SystemLanguage } from '../helpers/basemethods';
import { LanguageTr } from '../helpers/resources';
import { forEach } from 'lodash';
import { showNoti } from '../helpers/root';
import { NotifierHeaderInfoType, NotifierResultType } from '../helpers/enums';
import Search from 'antd/lib/input/Search';
import { GetSearchDelegatedResult } from '../business/Public/CommonBusiness';
const { RangePicker } = DatePicker;

export interface DayoffRequestProps { }
const DayoffRequest: React.FC<DayoffRequestProps> = (props) => {

    const [form] = Form.useForm();
    const [clientReady, setClientReady] = useState<boolean>(false);
    const [options, setOptions] = useState<SelectProps<object>['options']>([]);
    const [SearchUser, setSearchUser] = useState<string>("");
    const [SearchLoading, setSearchLoading] = useState<boolean>(false);

    const [SearchResults, setSearchResults] = useState<any[]>([])
    
    const [totalDayOffCount, setTotalDayOffCount] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [startDateDesc, setStartDateDesc] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [endDateDesc, setEndDateDesc] = useState<string>("");
    const [dayoffType, setDayoffType] = useState<string>("");
    const [dayoffTypeDesc, setDayoffTypeDesc] = useState<string>("");
    const [dayOffDescription, setDayOffDescription] = useState<string>("");
    const [usedDayOffCount, setUsedDayOffCount] = useState<string>("");
    const [nextWorkDay, setNextWorkDay] = useState<string>("");
    const [delegatedUser, setDelegatedUser] = useState<string>("");
    
    useEffect(() => {
        setTotalDayOffCount("15")
    },[0]);


    useEffect(() => {
        setClientReady(true);
        form.setFieldsValue({dayCount:4})
      }, []);
    
      const onFinish = (values: any) => {
        form.resetFields(); // Form elemanlarının değerlerini sıfırla
        setStartDate("");
        setStartDateDesc("");
        setEndDate("");
        setEndDateDesc("");
        setDayoffType("");
        setDayoffTypeDesc("");
        setDayOffDescription("");
        setUsedDayOffCount("");
        setNextWorkDay("");
        setDelegatedUser("");
        showNoti(NotifierResultType.success, GetOperationResourceData(NotifierHeaderInfoType.notifierSuccess), GetOperationResourceData("DayOffDemandSuccess"))
    };
     
    function searchInputHandler (value) {
    setSearchUser(value);
    }

    function searchHandler (e) {
        if (e.target.value.length>=3) {            
            setSearchLoading(true);
            findUser(SearchUser);
        }
        else{
            setSearchResults([])
        }
     
    }

    const findUser = async (searchKey) => {
        let searchResultData = await GetSearchDelegatedResult(searchKey);
        if (searchKey === null || searchKey === undefined || searchKey === '') {
            setSearchResults(searchResultData.filter(x=> x.value.includes("xyzt")))
        }
        else{
            const filteredResult = searchResultData.filter(item =>
                item.value.toLowerCase().includes(searchKey.toLowerCase())
              );
            setSearchResults(filteredResult)
        }
        setSearchLoading(false)    
    }

    function getDateDescFromDateString(_date): any {
        const formattedDate = new Date(_date).toLocaleDateString(GetSystemLanguage() == LanguageTr ? "tr-TR" : "en-US", {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          });          
        return formattedDate;
    }

    const calcUsedDayOff = (_endDate,_startDate) =>  {
        const diffTime = Math.abs(_endDate -_startDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;  
        return diffDays.toString();
    }

    const getNextWorkDay = (_endDate) => {
        let __endDate = new Date(_endDate);
        __endDate.setDate(__endDate.getDate()+1);
        return getDateDescFromDateString(__endDate);
    }

    const startEndDateChanged = (dates, dateStrings) => {
        setStartDate(dateStrings[0]);
        setEndDate(dateStrings[1]);
        setStartDateDesc(getDateDescFromDateString(dateStrings[0]));
        setEndDateDesc(getDateDescFromDateString(dateStrings[1]));
        setUsedDayOffCount(calcUsedDayOff(dates[1],dates[0]));
        setNextWorkDay(getNextWorkDay(dateStrings[1]));
    }

    const dayOffTypeSelectHandler = (key,value) => {
        setDayoffType(key);
        setDayoffTypeDesc(value.children);
    }

    const setDayOffDesc = (desc) => {
        setDayOffDescription(desc.target.value);
    }

    const handleBlur = () => {        
        if (delegatedUser == "" || delegatedUser == null) {
            form.setFieldsValue({ delegatedUser: '' }); 
        }
        else{
            form.setFieldsValue({ delegatedUser: delegatedUser }); 
        }
    }   
    

    const rangePickerLabels = ():any =>{ return [
        GetResourceData("StartDate"),
        GetResourceData("EndDate")
    ]
}

    
  
    return (
        <React.Fragment>           
           {
                <Form className='dayoff-request' form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>                     
                        <Row>
                            <Col className='demandColLeft' xl={12} lg={12} md={12} sm={24} xs={24} >
                                <Card type='inner' title={GetResourceData("DayOffRequests")}>
                                    <Row>
                                        <Col xl={24} lg={24} md={12} sm={24} xs={24}>
                                            <Form.Item label={GetResourceData("StartDate") + " - " + GetResourceData("EndDate")} name="startDate" required>
                                                <RangePicker placeholder={rangePickerLabels()} onChange={startEndDateChanged} />
                                            </Form.Item>                  
                                        </Col>    
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item label={GetResourceData("DayOffType")} name="dayoffType" required>
                                                <Select placeholder={GetResourceData("SelectDayOffType")} onChange={dayOffTypeSelectHandler}>
                                                    <Select.Option value="1">{GetResourceData("AnnualPaidDayOff")}</Select.Option>
                                                    <Select.Option value="2">{GetResourceData("PaternityLeave")}</Select.Option>
                                                    <Select.Option value="3">{GetResourceData("FreeDayoff")}</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>   
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item label={GetResourceData("DelegateUser")} name="delegatedUser" required>

                                            <AutoComplete
                                                options={SearchResults} // Arama sonuçlarını options prop'una uygun formata dönüştürüyoruz
                                                onSelect={setDelegatedUser}
                                                value={SearchUser}
                                                onKeyUp={searchHandler}
                                                placeholder={GetResourceData("YouCanSearchHere")}
                                                onChange={(e) => { setSearchUser(e) }}
                                                allowClear={true}
                                                onClear={() => { setDelegatedUser(""); setSearchResults([]) }}
                                                >
                                                {/* <Input.Search  onKeyDown={(e) => { if (e.key === "Enter") { searchHandler() } }} placeholder={GetResourceData("SearchByName")} enterButton={GetResourceData("Search")} addonBefore={<SearchOutlined />} loading={SearchLoading} /> */}
                                            </AutoComplete>


                                                {/* <AutoComplete
                                                    style={{ width: "100%" }}
                                                    options={delegatedUsers}
                                                    filterOption={
                                                        (inputValue, option) =>
                                                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    
                                                    onSelect = {setDelegatedUser}
                                                    allowClear={true}
                                                    onClear={() => { setDelegatedUser("") }}
                                                    onBlur={handleBlur}
                                                    >
                                                    </AutoComplete>
                                                    <Input id="search-input" placeholder={GetResourceData("YouCanSearchHere")} size="middle" value={SearchUser} onChange={(e) => {searchInputHandler(e.target.value);}} onKeyDown={(e) => { if (e.key === "Enter") { searchHandler() } }} />
                                            */}
                                           
                                            </Form.Item>
                                            
                                           
                                        </Col>    
                                    </Row> 
                                    
                                    <Row>                                        
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item label={GetResourceData("Description")} name="description" required>
                                                <Input onChange={setDayOffDesc}></Input>
                                            </Form.Item>
                                        </Col>    
                                    </Row> 
                                    <Row>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item shouldUpdate>
                                                {() => (
                                                    <Button
                                                    className="corporate-demands-btn"
                                                    style={{float:"right"}}
                                                    type="primary"
                                                    htmlType="submit"
                                                    disabled={
                                                        !clientReady ||
                                                        !form.isFieldsTouched(true) ||
                                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                    }
                                                    >
                                                    {GetResourceData("Demand")}
                                                    </Button>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='demandColRight' xl={12} lg={12} md={12} sm={24} xs={24} >
                                <Card type='inner' title={GetResourceData("DemandDetail")} style={{height:"100%"}}>                                    
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("AvailableBalance")}</span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{totalDayOffCount}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("StartDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{startDateDesc}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("EndDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{endDateDesc}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DayOffBeUsed")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{usedDayOffCount}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("StartOfWeek")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{nextWorkDay}</span>
                                        </Col>
                                    </Row>
                                    <Divider/>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DayOffType")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{dayoffTypeDesc}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DelegateUser")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{delegatedUser}</span>
                                        </Col>
                                    </Row>
                                    <Row className='dayoffRequestRow'>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("Description")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{dayOffDescription}</span>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
              </Form>
           }
        </React.Fragment>
    );
}

export default DayoffRequest;