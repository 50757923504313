import React, { useEffect, useRef, useState } from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';

import { callApi } from '../helpers/service';
import { ServiceResult } from '../type/ServiceResult';
import { methods } from '../helpers/enums';
import { FileResult } from '../type/Admin/FileResult';
import { getResource } from '../helpers/root';
import _ from 'lodash';
import { GetResourceData } from '../helpers/basemethods';
const { Dragger } = Upload;


interface IProps {
  value?: string;
  onChange?: Function;
  onDelete?: Function;
  title?: string;
  isMultiple?:boolean;
  setFileList?:any;
  isDisabled?:boolean;
  maxCount?:number;
  language?:string;
  customTextMessage?:string;
  defaultFileValue?:string;
  clearTrigger?:string;
  clearIsDone?:any;
  name?:any
}


const FileUpload: React.FC<IProps> = ({ name, clearIsDone, clearTrigger, onChange, onDelete, value, title, isMultiple, setFileList, isDisabled, maxCount, language, customTextMessage,defaultFileValue }) => {

  const [ defaultFileList, setDefaultFileList ] = useState<any>([]);
  const fileList = useRef<string[]>([]);
  
  useEffect(() => {
    if(defaultFileValue){
      const files: any = defaultFileValue?.split(",");
      for(var fileId of files){
        callApi({
          url: `/admin/file/detail/${fileId}`,
          useToken: true,
          successCallback: async (res: AxiosResponse<ServiceResult<FileResult>>) =>   {
            if(res.data.isSuccessful){
              const fileFromApi = res.data.result;
              const fileUrl = await getResource("file?id=" + fileFromApi.id);
              const file = {
                uid: fileFromApi.id,
                name: fileFromApi.fileName,
                status: "done",
                url: fileUrl,
                size: fileFromApi.length,
                type: fileFromApi.contentType
              }
              setDefaultFileList(s => {
                const newList = _.uniqBy([...s, file], 'name');
                return newList;
              
              });
            }
          }
        })
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFileValue]);  

  useEffect(() => {
    if (clearTrigger=="clear") {
      clearFiles();
      clearIsDone();
    }
  },[clearTrigger])

  const clearFiles = () => {
    setDefaultFileList([]);
  }

  const customRequest = ({ onSuccess, onError, file }: any) => {
    const formData = new FormData();
    formData.append("file", file);
    callApi({
      url: `/admin/file`,
      useToken: true,
      data: formData,
      method: methods.POST,
      successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
        onSuccess(res.data, file);
      },
      errorCallback: (err:any) => {
        onError(err, null, file);
      },
    
    })
  }
  
  const props = {
    name: 'file',
    multiple: isMultiple,
    customRequest,
    onChange: (info: any) => {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} başarılı bir şekilde yüklendi.`);
        fileList.current = [...fileList.current, info.file.response![0].id!];

        const idList = fileList.current.join(",");
        // setDefaultFileList(fileList.current);
        setDefaultFileList(info.fileList)
        onChange && onChange(idList);

      } else if (status === 'error') {
        message.success(`${info.file.name} başarılı bir şekilde yüklendi.`);
      }
      else if(status === 'removed'){
        onDelete && onDelete(defaultFileValue);
      }

      setDefaultFileList(info.fileList)
      setFileList(info.fileList,language)
    },
  };


  return (
    <>
      <Dragger name={name} disabled={isDisabled} multiple={isMultiple} maxCount={maxCount} {...props} 
       fileList={defaultFileList}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{title ?? <>{GetResourceData("FileUploadMainMessage")}</>}</p>
        <p className="ant-upload-hint">
         {customTextMessage ? customTextMessage : GetResourceData("FileUploadCountMessage")} 
    </p>
      </Dragger>
    </>
  );
}

export default FileUpload;