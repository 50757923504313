import { showNoti } from './../helpers/root';
import { AxiosResponse } from "axios"
import { useEffect, useRef, useState } from "react"
import { NotifierResultType, methods } from "../helpers/enums"
import { setErrorCallback } from "../helpers/root"
import { callApi } from "../helpers/service"
import { ServiceResult } from "../type/ServiceResult"

export const  useDidUpdate = (callback: any, deps: any) => {
    const hasMount = useRef(false)
  
    useEffect(() => {
      if (hasMount.current) {
        callback()
      } else {
        hasMount.current = true
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }


  export const useGetResource = (path: string) => {
    const [ resource, setResource ] = useState<any>("/place-holder.png")

    useEffect(() => {
      if(path){
        (async () => {
          // const resource = await getResource(path);
          // setResource(resource);
          setResource("#");
        })()
        
      }
    }, [path]);

    return resource;
  }


  interface IUseCallApi{
    url: string
    method?: string
    showNotiAfterSuccess?: boolean
  }
  
  export function useCallApi<T>  (
    {
      url, 
      method = methods.GET,
      showNotiAfterSuccess = false
    }: IUseCallApi
  ): {loading: boolean, data: T | null, err: null, trigger: (params?: object) => void}  {
  
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ data, setData ] = useState<T | null>(null);
    const [ err, setErr ] = useState<any>(null);

    const _callApi = (params) => {
      setLoading(true)
      callApi(
        { 
          url, 
          useToken: true,
          method,
          data: params,
          successCallback: (res: AxiosResponse<ServiceResult<any>>) => {
            if(res.status === 200){
              setData(res.data.result)
              if(showNotiAfterSuccess){
                showNoti(NotifierResultType.success, "Başarılı", "İşlem başarılı")
              }
            }
          },
          errorCallback: (err: any) => {
            setErr(err)
            setErrorCallback(null, err);
          },
          finish: () => {
            setLoading(false)
          }
        }
      )
    }
 
    return {loading, data, err, trigger: _callApi}
    
  }