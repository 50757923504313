import React from 'react';

export interface CircleNameProps {
    name: string;
    surname: string;
    title?: string;
    icon?: string;
}

const CircleName: React.FC<CircleNameProps> = ({ name, surname, title, icon }) => {

    return (
        <>
            <div className="circle-name">
                {icon === undefined ? (<div className="circle">{name[0]}{surname[0]}</div>)
                    :
                    (
                        <div className={icon}></div>
                    )}

                <div className="name-title-container">
                    <span className="name">{name} {surname}</span>
                    {title && <span className="title">{title}</span>}
                </div>
            </div>
        </>
    );
}

export default CircleName;