import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../state';
import Marquee from "react-fast-marquee";
import { Content } from 'antd/lib/layout/layout';
import { CheckSquareOutlined, CloseSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { getPublicNews } from '../state/reducers/HomePageReducer';
import { AutoComplete, Button, Card, Col, Collapse, DatePicker, Descriptions, DescriptionsProps, Divider, Form, Input, Modal, Popconfirm, Row, Select, SelectProps, Table, Tabs, TabsProps, Tooltip } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import TabPane from 'antd/lib/tabs/TabPane';
import Column from 'antd/lib/table/Column';
import { GetResourceData } from '../helpers/basemethods';
import { getMyTasksContent } from '../state/reducers/DemandReducer';
import { CostRequestType, DayOffRequestType, VacationRequestType } from '../helpers/constantData';
import TaskTable from './TaskTable';
import { isMobile } from 'react-device-detect';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

export interface MyTaskProps { }
const MyTask: React.FC<MyTaskProps> = (props) => {

    const { Panel } = Collapse;
    const [showItemDetailType, SetShowItemDetailType] = useState<any>(null)
    const [showItemDetail, SetShowItemDetail] = useState<any>(null)
    const [showItemHistory, SetShowItemHistory] = useState<any>(null)
    const [activeTab, setActiveTab] = useState<string>("1");

    const dispatch = useDispatch();

  
    const { myTasksContent  } = useSelector((s: Store) => s.demand);

    useEffect(() => {      
       if (!myTasksContent) {
        dispatch(getMyTasksContent());
       }
      }, []);
      

    const requestDetail = async (id) => {
        await getItemDetail(id)
        .then((response) => {
            
            SetShowItemDetail(response);
            SetShowItemDetailType(response.type);
            SetShowItemHistory(response.history);
        })
        .catch((error) => {
            SetShowItemDetail(null)            
            SetShowItemDetailType(null);         
            SetShowItemHistory(null);
        });          
    }

    async function getItemDetail(id)  {
        let obj = myTasksContent.data.filter(x=> x.id == id)[0];
        return {
            "isActive" : true,
            "id" : obj.id,
            "type" : obj.type,
            "history" : obj.history,
            "dayOffBalance" : "14",
            "startDate" : "29 Şubat 2024",
            "endDate" : "4 Mart 2024",
            "usedDayOff" : "3",
            "startingWorkDate" : "5 Mart 2024",
            "dayOffType" : "Yıllık Ücretli İzin",
            "delegatedUser" : "Volkan Seçginli",
            "description" : obj.desc,
            "demandDate" : obj.date,
            "demandState" : obj.state,
            "currencyType" : "TRY",
            "costList" : [
                {
                    "costDateAndType" : "29 Şubat 2024 - Köprü Ücreti",
                    "amount" : "350 TRY",
                    "desc" : "test açıklama"
                },
                {
                    "costDateAndType" : "29 Şubat 2024 - Yemek Ücreti",
                    "amount" : "200 TRY",
                    "desc" : "test yemek açıklama"
                }
            ]
            

        }
    }
    
    const CancelRequest = (itemId) => {
        console.log("Click on Yes", itemId);
    };

    const ApproveRequest = (itemId) => {
        console.log("Click on Yes", itemId);
    };

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: <>{GetResourceData("PendingApproval")}</> ,
          children: <TaskTable _isStateOpen={true} _myTasksContent={myTasksContent} _requestDetail={requestDetail} _approveRequest={ApproveRequest} _declineRequest={CancelRequest} />
        },
        {
          key: '2',
          label: <>{GetResourceData("PassedMyApproval")}</> ,
          children: <TaskTable _isStateOpen={false} _myTasksContent={myTasksContent} _requestDetail={requestDetail} />
        }
        
      ];

      const onChangeTab = (key) => {
        setActiveTab(key);
      };
  
      const activeKeyLeft = () => {
        let _activeKeyNum = parseInt(activeTab)
        if(_activeKeyNum>1){
          setActiveTab((_activeKeyNum-1).toString())
        }
      }
  
      const activeKeyRight = () => {
        let _activeKeyNum = parseInt(activeTab)
        if(_activeKeyNum<items.length){
          setActiveTab((_activeKeyNum+1).toString())
        }
      }

    return (
        <React.Fragment>    
            <>   
            {
                <div>   
                    {
                        isMobile &&
                        <>
                        <a style={{display: parseInt(activeTab)==1 ? "none" : "block"}} onClick={()=>{ activeKeyLeft() }}><div className="corporateButtons left" ><LeftOutlined /></div></a>
                        <a style={{display: parseInt(activeTab)==items.length ? "none" : "block"}} onClick={()=>{ activeKeyRight() }}><div className="corporateButtons right"><RightOutlined /></div></a>
                        </>
                    }        
                    <Tabs
                        defaultActiveKey="1" 
                        activeKey={activeTab} 
                        onChange={onChangeTab}
                        type="card"
                        tabPosition="top"
                        className='myTasks'
                        items={items}
                    >
                    </Tabs>     
                </div>           
            }   
            {
                 <Modal
                    open={showItemDetailType}
                    onCancel={() => { SetShowItemDetailType(null) }}
                    destroyOnClose
                    footer={null}
                    closable={true}
                    className='thanks-modal birthday-greeting taskDetailModal'
                >
                    {
                        showItemDetailType === DayOffRequestType ?
                            <>
                                     <Card type='inner' title={GetResourceData("DemandDetail")} style={{height:"100%"}}>                                 
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestNo")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>Req-{showItemDetail.id}</span>
                                        </Col>
                                    </Row>                                 
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("AvailableBalance")} </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{showItemDetail.dayOffBalance}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("StartDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{showItemDetail.startDate}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("EndDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.endDate}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DayOffBeUsed")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.usedDayOff}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("StartOfWeek")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.startingWorkDate}</span>
                                        </Col>
                                    </Row>
                                    <Divider/>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DayOffType")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.dayOffType}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("DelegateUser")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.delegatedUser}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("Detail")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.description}</span>
                                        </Col>
                                    </Row>
                                    <Divider/>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.demandDate}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestState")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.demandState}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Collapse>
                                                <Panel style={{fontSize:"11px"}} header={GetResourceData("RequestHistory")} key="1">
                                                    <Table dataSource={showItemHistory} size='small'>
                                                        <Column title={GetResourceData("Process")} dataIndex="process" key="process"/>
                                                        <Column title={GetResourceData("PerformedBy")} dataIndex="user" key="user" />
                                                        <Column title={GetResourceData("Date")} dataIndex="date" key="date" />
                                                    </Table>
                                                </Panel>                                 
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    {
                                        showItemDetail && showItemDetail.isActive &&
                                        <>
                                            <Divider/>
                                            <Row>
                                                <Col className='request-buttons' xl={24} lg={24} md={24} sm={24} xs={24}>
                                                    <Popconfirm
                                                        title={GetResourceData("ConfirmRequest")}
                                                        onConfirm={() => CancelRequest(showItemDetail.id)}
                                                        onCancel={() => CancelRequest(showItemDetail.id) }
                                                        okText={GetResourceData("Yes")}                                           
                                                        cancelText={GetResourceData("No")}
                                                    >
                                                        <Button id="btnApprove">{GetResourceData("Approve")}</Button>
                                                    </Popconfirm>

                                                    <Popconfirm
                                                        title={GetResourceData("RejectRequest")}
                                                        onConfirm={() => CancelRequest(showItemDetail.id)}
                                                        onCancel={() => CancelRequest(showItemDetail.id) }
                                                        okText={GetResourceData("Yes")}                                           
                                                        cancelText={GetResourceData("No")}
                                                    >
                                                        <Button id="btnReject">{GetResourceData("Reject")}</Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Card>
                            </>
                        : showItemDetailType === CostRequestType ?
                            <>
                                <Card type='inner' title={GetResourceData("DemandDetail")} style={{height:"100%"}}>                             
                                <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("CurrencyCode")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.currencyType}</span>
                                        </Col>
                                    </Row>
                                    {
                                        showItemDetail.costList.map(item => (
                                            <div key={item.id}> {/* Her bir döngü elemanı için benzersiz bir 'key' propu eklemeyi unutmayın */}
                                                <Divider />
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span style={{ fontWeight: "bold" }}>{GetResourceData("RequestTypeDate")} : </span>
                                                    </Col>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span>{item.costDateAndType}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span style={{ fontWeight: "bold" }}>{GetResourceData("Amount")} : </span>
                                                    </Col>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span>{item.amount}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span style={{ fontWeight: "bold" }}>{GetResourceData("Description")} : </span>
                                                    </Col>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                                        <span>{item.desc}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))
                                    }

                                    
                                    <Divider/>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestDate")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span>{showItemDetail.demandDate}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <span style={{fontWeight:"bold"}}>{GetResourceData("RequestState")} : </span>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <span>{showItemDetail.demandState}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Collapse>
                                                <Panel style={{fontSize:"11px"}} header={GetResourceData("RequestHistory")} key="1">
                                                    <Table dataSource={showItemHistory} size='small'>
                                                        <Column title={GetResourceData("Process")} dataIndex="process" key="process"/>
                                                        <Column title={GetResourceData("PerformedBy")} dataIndex="user" key="user" />
                                                        <Column title={GetResourceData("Date")} dataIndex="date" key="date" />
                                                    </Table>
                                                </Panel>                                 
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    {
                                        showItemDetail && showItemDetail.isActive &&
                                        <>
                                            <Divider/>
                                            <Row>
                                                <Col className='request-buttons' xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <Popconfirm
                                                        title={GetResourceData("ConfirmRequest")}
                                                        onConfirm={() => CancelRequest(showItemDetail.id)}
                                                        onCancel={() => CancelRequest(showItemDetail.id) }
                                                        okText={GetResourceData("Yes")}                                           
                                                        cancelText={GetResourceData("No")}
                                                    >
                                                        <Button id="btnApprove">{GetResourceData("Approve")}</Button>
                                                    </Popconfirm>

                                                    <Popconfirm
                                                        title={GetResourceData("RejectRequest")}
                                                        onConfirm={() => CancelRequest(showItemDetail.id)}
                                                        onCancel={() => CancelRequest(showItemDetail.id) }
                                                        okText={GetResourceData("Yes")}                                           
                                                        cancelText={GetResourceData("No")}
                                                    >
                                                        <Button id="btnReject">{GetResourceData("Reject")}</Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Card>
                            </>   
                        :
                            <>
                                <span>Seyahat Detayı - {showItemDetail}</span>
                            </>                     
                    }
             </Modal>
            }
           </>
        </React.Fragment>
    );
}

export default MyTask;